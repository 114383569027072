/* src/app/components/ehr/patients/components/social-history-section/social-history/social-history-add-edit/social-history-add-edit.component.css */
textarea {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
}
.social-submitted .social.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=social-history-add-edit.component-4ELIUUMC.css.map */
