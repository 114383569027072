/* src/app/components/ehr/patients/components/health-care-plan-section/care-plan/care-plan-add-edit/care-plan-add-edit.component.css */
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 150px;
  margin-left: 1em;
}
.plan-submitted .plan.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=care-plan-add-edit.component-DVWNY4SQ.css.map */
