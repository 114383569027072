import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { SupportingDocumentsService } from 'src/app/services/supportingDocuments.service';
import { IAddDocument } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAddDocument';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { AddPatientService } from 'src/app/services/addpatient.service';
import { IGenericServiceResponse } from 'src/app/interfaces/IServiceResponse';
import { IPatientsDropdown } from 'src/app/interfaces/IPatientsDropdown';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-supporting-documents-add',
  templateUrl: './supporting-documents-add.component.html',
  styleUrls: ['./supporting-documents-add.component.css'],
})
export class SupportingDocumentsAddComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  documentDropdown: { id: number; name: string }[] = [];
  filesObj?: File[];
  isVisible = false;
  isLoading = false;
  isEdit = false;
  documentSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef;

  supportingDocumentForm: FormGroup = new FormGroup({});

  constructor(
    private readonly supportingDocumentsService: SupportingDocumentsService,
    private readonly addPatientService: AddPatientService,
    private readonly translate: TranslateService,
    private readonly formBuilder: FormBuilder,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isEdit = false;
    console.log('HERE');

    // Fetch dropdown data for file types (example)
    // Fetch dropdown data for file types
    this.addPatientService.getPatientsDropDown().subscribe({
      next: (res: IGenericServiceResponse<IPatientsDropdown>) => {
        this.documentDropdown = res?.data?.cachedData?.fileType;
        console.log(this.documentDropdown);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });

    // Initialize form
    this.supportingDocumentForm = this.formBuilder.group({
      fileTypeId: [null, Validators.required],
      file: [null, Validators.required],
    });

    // Subscribe to modal visibility from the service
    this.supportingDocumentsService.supportingDocumentModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((isVisible) => {
        this.isVisible = isVisible;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.modalRef.destroy();
  }

  handleCancel() {
    this.supportingDocumentForm.reset();
    this.supportingDocumentsService.supportingDocumentModalSub.next(false);
    this.documentSubmitted = false;
    this.isLoading = false;
    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.documentSubmitted = true;

    if (this.filesObj && this.supportingDocumentForm.valid) {
      const documentObj: IAddDocument = {
        fileTypeId: this.supportingDocumentForm.value.fileTypeId,
        patientId: '',
        files: this.filesObj,
      };

      // Add a new document
      this.supportingDocumentsService
        .addSupportingDocumentFile(documentObj)
        .subscribe({
          next: () => {
            this.swalToastService.toastSuccess(
              'Supporting Document added successfully'
            );
            this.supportingDocumentsService.getData();
            this.handleCancel();
          },
          error: () => {
            this.swalToastService.toastError(
              'Unable to add Supporting Document'
            );
            this.handleCancel();
          },
        });
    } else {
      this.isLoading = false;
    }
  }

  onFileSelected(event: any) {
    const files: File[] | null = Array.from(event.target.files);

    if (files) {
      this.filesObj = files;
      // this.supportingDocumentForm.get('file')?.setValue(files.name);
      this.supportingDocumentForm.get('file')?.setErrors(null);
    } else {
      this.filesObj = undefined;
      this.supportingDocumentForm.get('file')?.setValue(undefined);
      this.supportingDocumentForm.get('file')?.setErrors({ invalid: true });
    }
  }
}
