import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IAddLaboratoryFile } from '../interfaces/LaboratoryFile/IAddLaboratoryFile';
import { ILaboratoryFileDropdown } from '../interfaces/LaboratoryFile/ILaboratoryFileDropdown';
import { ILaboratoryFileDetailed } from '../interfaces/LaboratoryFile/ILaboratoryFileDetailed';
import { saveAs } from 'file-saver-es';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class LaboratoryFileService {
  public laboratoryModalSub = new BehaviorSubject<boolean>(false);

  public laboratoryEditSub = new BehaviorSubject<
    ILaboratoryFileDetailed | undefined
  >(undefined);

  public laboratoryDataSub = new BehaviorSubject<ILaboratoryFileDetailed[]>([]);

  public laboratoryLoadingSub = new BehaviorSubject<boolean>(false);
  public laboratoryLoadingSub$ = this.laboratoryLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.laboratoryLoadingSub.next(true);
    await this.getLaboratory(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.laboratoryDataSub.next(response?.data!);
        this.laboratoryLoadingSub.next(false);
      },

      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve Laboratory data');
        console.error('Error fetching procedures:', err);
      },
    });
  }

  getLaboratory(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<ILaboratoryFileDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<ILaboratoryFileDetailed[]>>(
        `${environment.BACKEND_URL}LaboratoryFile/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<ILaboratoryFileDetailed[]>>(
        `${environment.BACKEND_URL}LaboratoryFile/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  getPDF(imageId: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get(
      `${environment.BACKEND_URL}LaboratoryFile/DownloadPDF/${imageId}`,
      {
        headers: headers,
        responseType: 'blob',
      }
    );
  }

  downloadPDF(item: ILaboratoryFileDetailed): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .get(
        `${
          environment.BACKEND_URL
        }LaboratoryFile/DownloadPDF/${item?.id?.toString()!}`,
        {
          headers: headers,
          responseType: 'blob',
        }
      )
      .subscribe(
        (blob) => {
          const file = new Blob([blob], { type: 'application/pdf' });
          saveAs(file, item.examTitle + '.pdf');
        },
        (error) => {
          this.swalToastService.toastError('Unable to download the document');
          console.error('Error downloading PDF:', error);
        }
      );
  }

  getLaboratoryDropdown(): Observable<
    IGenericServiceResponse<ILaboratoryFileDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<ILaboratoryFileDropdown>>(
      `${environment.BACKEND_URL}LaboratoryFile/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getDropdown(): Observable<IGenericServiceResponse<ILaboratoryFileDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<ILaboratoryFileDropdown>>(
      `${environment.BACKEND_URL}LaboratoryFile/DropdownData/RareDisease`,
      {
        headers: headers,
      }
    );
  }

  addLaboratory(laboratoryObj: IAddLaboratoryFile) {
    laboratoryObj.visitId = this.eocService.currentVisitId();
    laboratoryObj.patientId = this.patientService.patientIDsignal();

    const formData = new FormData();
    formData.append('CategoryId', laboratoryObj?.categoryId?.toString()!);
    formData.append('ExamTitleId', laboratoryObj?.examTitleId?.toString()!);
    formData.append('Date', laboratoryObj?.date!);
    formData.append('Description', laboratoryObj?.description!);
    formData.append('file', laboratoryObj?.file!);

    formData.append('PatientId', laboratoryObj?.patientId?.toString()!);
    formData.append('VisitId', laboratoryObj?.visitId?.toString()!);

    // const headers = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data; boundary=----',
    // });

    return this._http.post(
      `${environment.BACKEND_URL}LaboratoryFile`,
      formData
    );
  }

  editLaboratory(laboratoryObj: IAddLaboratoryFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}LaboratoryFile/${laboratoryObj.id}`,
      laboratoryObj,
      {
        headers: headers,
      }
    );
  }

  deleteLaboratory(laboratoryId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}LaboratoryFile/${laboratoryId}`,
      {
        headers: headers,
      }
    );
  }

  deleteLaboratoryFromTable(laboratoryId: string) {
    // REMOVE THE Laboratory
    this.laboratoryDataSub.next(
      this.laboratoryDataSub.value?.filter(
        (rp) => rp.id?.toString() != laboratoryId
      )
    );
  }
}
