import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITenantSettings } from '../interfaces/TenantSettings/ITenantSettings';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class TenantSettingsService {
  public tenantSettingsSignal = signal({} as ITenantSettings);

  constructor(private readonly _http: HttpClient) {}

  downloadPicture(logo: number): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    return this._http.get(
      `${environment.BACKEND_URL}TenantSettings/DownloadPicture/${logo}`,
      {
        headers: headers,
        responseType: 'blob',
      }
    );
  }

  tenantSettings(): Observable<IGenericServiceResponse<ITenantSettings>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });
    return this._http.get<IGenericServiceResponse<ITenantSettings>>(
      `${environment.BACKEND_URL}TenantSettings`,
      {
        headers: headers,
      }
    );
  }
}
