/* src/app/components/ehr/dashboard-amen/dashboard-amen.component.css */
.dashboard-body {
  height: 100%;
}
.bed-header {
  margin-left: 50px;
  margin-top: 50px;
  margin-bottom: 100px;
}
.forms-between {
  margin-top: 50px;
}
.dashboard-container {
  min-height: calc(100vh - 66px);
  background-color: #f9fafb;
  padding: 0px 10vw 0px 10vw;
}
.body {
  margin-right: 50px;
  margin-left: 50px;
  padding: 20px;
}
.module-color {
  background-color: white;
}
.calendar-line {
  margin-top: 30px;
  border-radius: 2px;
  padding: 2px;
}
.export-data-btn {
  border: 1px solid rgba(0, 0, 0, 0.45) !important;
  border-radius: 4px;
  background: white !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-container {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-end;
  gap: 20px;
}
.row-layout {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 20px;
}
@media only screen and (max-width: 1299.99px) {
  .row-layout {
    flex-direction: column;
    gap: 20px;
  }
  .calendar-container {
    padding: 0;
    justify-content: center;
    align-content: center;
  }
}
.p30 {
  padding: 30px 30px 0px 30px;
}
.pa30 {
  padding: 30px 30px 30px 30px;
}
.plr30 {
  padding: 0px 30px 10px 30px;
}
.pb30 {
  padding: 20px 30px 20px 30px;
}
.p50 {
  padding: 0px 30px 0px 0px;
}
.toggle {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-top: 1.5em;
  font-weight: 500;
}
.toggle-btn {
  margin-left: 1em;
  margin-right: 1em;
}
::ng-deep .ant-switch-checked {
  background-color: white !important;
}
::ng-deep .ant-switch {
  background-color: white !important;
  border: 1px solid #9c9c9c !important;
  min-width: 50px !important;
}
::ng-deep .ant-switch-handle::before {
  background-color: #9c9c9c;
}
.daterange-items {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  margin-top: 30px;
  padding-top: 1.5em;
}
.title {
  font-weight: 600;
  font-size: 20px;
}
/*# sourceMappingURL=dashboard-amen.component-5DM6UDXT.css.map */
