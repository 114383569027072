<form [formGroup]="addressForm" (keydown.enter)="enter($event)" [ngClass]="{ submitted: formSubmitted }">
  <div>
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="100" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Street" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="100" class="add-patient-col">
        <input class="add-patient-input" [placeholder]="'Street' | translate" formControlName="street"
          [maxLength]="50" />
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Street Number" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Apartment Number" | translate
        }}<span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Postcode" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="33" class="add-patient-col">
        <input class="add-patient-input" [placeholder]="'Street Number' | translate" formControlName="streetNumber"
          [maxLength]="50" />
      </div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col">
        <input class="add-patient-input" [placeholder]="'Apartment Number' | translate"
          formControlName="apartmentNumber" [maxLength]="50" />
      </div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col">
        <input class="add-patient-input" [placeholder]="'Postcode' | translate" formControlName="postcode" />
      </div>
    </div>
  </div>

  <div>
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Country" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>

      <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Town" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col" fxLayoutAlign="start center">
        {{ "District" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="col" fxFlex="33" class="add-patient-col">
        <nz-select (keydown.enter)="$event.stopPropagation(); $event.preventDefault()" class="add-patient-inputZorro"
          nzShowSearch nzAllowClear [nzPlaceHolder]="'Country' | translate" formControlName="country"
          (ngModelChange)="onCountryChange($event)">
          <ng-container *ngFor="let place of countriesList">
            <nz-option [nzLabel]="place.name" [nzValue]="place.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>
      <div fxLayout="col" fxFlex="33" class="add-patient-col">
        <ng-container *ngIf="!isCountryCyprus; else cyprusDropdown">
          <input class="add-patient-input" [placeholder]="'Town' | translate" formControlName="town" [maxLength]="50" />
        </ng-container>
        <!-- Dropdown if Cyprus -->
        <ng-template #cyprusDropdown>
          <nz-select (keydown.enter)="$event.stopPropagation(); $event.preventDefault()" class="add-patient-inputZorro"
            nzShowSearch nzAllowClear [nzPlaceHolder]="'Select a Town' | translate" formControlName="town">
            <ng-container *ngFor="let city of cyprusCities">
              <nz-option [nzLabel]="city | translate" [nzValue]="city"></nz-option>
            </ng-container>
          </nz-select>
        </ng-template>
      </div>

      <div fxLayout="col" fxFlex="33" class="add-patient-col">
        <input class="add-patient-input" [placeholder]="'District' | translate" formControlName="district"
          [maxLength]="50" />
      </div>
    </div>
  </div>
</form>