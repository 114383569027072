/* src/app/components/ehr/admission/admission-profile/admission-medical-info/external-doctors/external-doctors.component.css */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
.add-patient-col {
}
.add-patient-input {
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
.add-patient-inputZorro {
  width: 100%;
}
.identifier-type-select {
  width: 80% !important;
}
::ng-deep nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
select.ng-valid.ng-touched {
  color: black;
}
input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: #c2c2c2;
}
.add-patient-button {
  height: 100%;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: var(--color-0) !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
.submitted .ng-invalid {
  border: red 1px solid;
}
.add-patient-button {
  margin-top: 1em;
  height: 35px;
  border: 1px solid var(--color-1);
  border-radius: 4px;
  background-color: var(--color-1) !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-button {
  margin-right: 1em;
  margin-bottom: 2em;
  height: 35px;
  border: 1px solid #ff2626;
  border-radius: 4px;
  background-color: white;
  color: #ff2626;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
::ng-deep ::ng-deep nz-select-top-control {
  height: 35px !important;
}
.navigation-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.navigation-controls button {
  border-radius: 4px;
  border: 1px solid var(--color-0);
  background-color: white;
  color: var(--color-0);
  cursor: pointer;
}
.navigation-controls button:hover {
  color: white !important;
  background-color: var(--color-0) !important;
}
.nav-btn {
  border-color: #d9d9d9 !important;
  background: var(--light-gray) !important;
  scale: 0.7;
}
.plus-cricle:hover {
  color: var(--color-0) !important;
}
.delete:hover {
  color: #ff2626 !important;
}
.navigator {
  margin-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid lightgray;
}
.navigator-2 {
  margin-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.mediator {
  margin-left: 1em;
}
.num-con {
  font-weight: 600;
}
.layout {
  margin-left: 1em;
  margin-right: 1em;
}
@media only screen and (min-width: 1279px) {
  .large-screen {
    margin-bottom: 0.5em;
  }
}
@media only screen and (max-width: 1279px) {
  .small-screen {
  }
}
.label-row {
  min-height: 50px;
}
.title {
  font-size: 20px;
  font-weight: 500;
  margin-left: 14px;
}
.add-patient-input:disabled {
  cursor: not-allowed;
}
.info-container {
  padding-left: 1em;
  padding-right: 1em;
}
.add-external-btn {
  background: transparent !important;
  border: 1px solid black !important;
  scale: 0.7;
  cursor: pointer;
}
.add-external-btn:disabled {
  border: 0px solid black !important;
}
.delete-external-btn {
  background: transparent !important;
  border: 1px solid black !important;
  scale: 0.7;
  cursor: pointer;
}
.delete-external-btn:disabled {
  border: 0px solid black !important;
}
.external-container {
  margin-top: 3em;
}
.select-container {
  padding-bottom: 1em;
}
/*# sourceMappingURL=external-doctors.component-WUDL5SJ6.css.map */
