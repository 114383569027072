<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Social History"
        [columns]="['Social History', 'Unit Amount', 'Timing Unit']"
        [rowFields]="['socialHistory', 'unitAmount', 'customTimingUnit']"
        [data]="socialHistory"
        [addComponent]="addComp"
        [deleteFun]="deleteFun"
        [isLoading]="isLoading"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-s')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
