/* src/app/components/ehr/admission/admission-profile/admission-timeline/admission-timeline.component.css */
.patient-creation-date {
  background-color: var(--light-gray);
  border: 1px solid black;
  height: 100px;
  width: min(250px, 50%);
  border-radius: 10px;
}
.creation-date-mark {
  padding-left: 10px;
  border-left: 8px solid lightblue;
}
.patient-pending-date {
  background-color: var(--light-gray);
  border: 1px solid black;
  height: 100px;
  width: min(250px, 50%);
  border-radius: 10px;
}
.pending-date-mark {
  padding-left: 10px;
  border-left: 8px solid lightcyan;
}
.patient-admission-date {
  background-color: var(--light-gray);
  border: 1px solid black;
  height: 250px;
  width: min(400px, 60%);
  border-radius: 10px;
}
.admission-date-mark {
  padding-left: 10px;
  border-left: 8px solid lightskyblue;
}
.patient-waiting-list-date {
  background-color: var(--light-gray);
  border: 1px solid black;
  height: 250px;
  width: min(250px, 20%);
  border-radius: 10px;
}
.waiting-list-date-mark {
  padding-left: 10px;
  border-left: 8px solid lightgoldenrodyellow;
}
.patient-approved-date {
  background-color: var(--light-gray);
  border: 1px solid black;
  height: 250px;
  width: min(250px, 20%);
  border-radius: 10px;
}
.approved-date-mark {
  padding-left: 10px;
  border-left: 8px solid lightgreen;
}
.patient-rejected-date {
  background-color: var(--light-gray);
  border: 1px solid black;
  height: 250px;
  width: 100%;
  border-radius: 10px;
}
.rejected-date-mark {
  padding-left: 10px;
  border-left: 8px solid lightcoral;
}
.patient-discharged-date {
  background-color: var(--light-gray);
  border: 1px solid black;
  height: auto;
  width: min(290px, 100%);
  border-radius: 10px;
}
.discharged-date-mark {
  padding-left: 10px;
  border-left: 8px solid lightsalmon;
}
.patient-rejected-date-container {
  padding-left: 1em;
  margin-left: 1em;
  border-left: 1px solid lightgray;
  width: min(250px, 30%);
}
input {
  width: 100%;
  border: 1px solid gray !important;
  border-radius: 5px !important;
  padding-left: 5px;
  padding-right: 5px;
}
select {
  width: 100%;
  height: 34px;
  margin-top: 1em;
  border: 1px solid gray !important;
  border-radius: 5px !important;
  padding-left: 5px;
  padding-right: 5px;
}
.notes {
  margin-top: 1em;
}
.cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  width: 80%;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
  border-bottom: 1px solid lightgray;
  gap: 40px;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::ng-deep .mat-mdc-card-header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
::ng-deep .mat-mdc-card-content {
  height: 100%;
}
select.ng-invalid {
  border: red 1px solid !important;
}
input.ng-invalid {
  border: red 1px solid !important;
}
.bed-unavailable {
  color: red;
  font-size: 12px;
}
::ng-deep .ant-modal {
  width: 80% !important;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer {
  width: 100%;
}
/*# sourceMappingURL=admission-timeline.component-TOXWRQQM.css.map */
