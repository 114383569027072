import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IArterialBloodGasDetailed } from '../interfaces/ArterialBloodGas/IArterialBloodGasDetailed';
import { IAddArterialBloodGas } from '../interfaces/ArterialBloodGas/IAddArterialBloodGas';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class ArterialBloodGasService {
  public arterialBloodGasModalSub = new BehaviorSubject<boolean>(false);

  public arterialBloodGasEditSub = new BehaviorSubject<
    IArterialBloodGasDetailed | undefined
  >(undefined);

  public arterialBloodGasDataSub = new BehaviorSubject<
    IArterialBloodGasDetailed[]
  >([]);

  public arterialBloodGasLoadingSub = new BehaviorSubject<boolean>(false);
  public arterialBloodGasLoadingSub$ =
    this.arterialBloodGasLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.arterialBloodGasLoadingSub.next(true);
    this.getArterialBloodGas(
      this.patientService.patientIDsignal(),
      this.eocService.currentEocId(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.arterialBloodGasDataSub.next(response?.data!);
        this.arterialBloodGasLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError(
          'Unable to retrieve Arterial Blood Gas data'
        );
        console.error('Error fetching Arterial Blood Gas data:', err);
      },
    });
  }

  getArterialBloodGas(
    patientId: string,
    episodeCareId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IArterialBloodGasDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IArterialBloodGasDetailed[]>>(
      `${environment.BACKEND_URL}ArterialBloodGas/${patientId}/${episodeCareId}/${visitId}`,
      {
        headers: headers,
      }
    );
  }

  addArterialBloodGas(abgObj: IAddArterialBloodGas) {
    abgObj.visitId = this.eocService.currentVisitId();
    abgObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}ArterialBloodGas`,
      abgObj,
      {
        headers: headers,
      }
    );
  }

  editArterialBloodGas(abgObj: IAddArterialBloodGas) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}ArterialBloodGas/${abgObj.id}`,
      abgObj,
      {
        headers: headers,
      }
    );
  }

  deleteArterialBloodGas(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}ArterialBloodGas/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deleteArterialBloodGasFromTable(arterialBloodGasId: string) {
    // REMOVE THE Arterial Blood Gas record
    this.arterialBloodGasDataSub.next(
      this.arterialBloodGasDataSub.value?.filter(
        (abg) => abg.id?.toString() != arterialBloodGasId
      )
    );
  }
}
