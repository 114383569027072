import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { ComorbidityService } from 'src/app/services/comorbidity.service';
import { IAddComorbidity } from 'src/app/interfaces/Comorbidity/IAddComorbidity';
import { TooltipComponent } from 'src/app/common/tooltip/tooltip.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  standalone: true,
  selector: 'app-comorbidity-add-edit', // Change component name
  templateUrl: './comorbidity-add-edit.component.html', // Change to appropriate file path
  styleUrls: ['./comorbidity-add-edit.component.css'], // Change to appropriate file path
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
    NzCheckboxModule,
  ],
})
export class ComorbidityAddEditComponent {
  // Change component class name
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editComorbidityId?: number; // Change variable name

  comorbiditySubmitted = false; // Change variable name

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  comorbidityForm: FormGroup = new FormGroup({}); // Change variable name

  constructor(
    private readonly comorbidityService: ComorbidityService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {
    this.comorbidityForm = this.formBuilder.group({
      dateValue: [null, Validators.required],
      noComorbiditiesValue: [false],
      anyComorbidityValue: [null],
      respiratoryDiseaseValue: [false],
      hypertensionValue: [false],
      copdValue: [false],
      heartDiseaseValue: [false],
      diabetesMellitusValue: [false],
      malignancyValue: [false],
      strokeValue: [false],
      immunosuppressionValue: [false],
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.comorbidityForm
      .get('noComorbiditiesValue')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((noComorbiditiesValue: boolean) => {
        const comorbidityControls = [
          'respiratoryDiseaseValue',
          'hypertensionValue',
          'copdValue',
          'heartDiseaseValue',
          'diabetesMellitusValue',
          'malignancyValue',
          'strokeValue',
          'immunosuppressionValue',
        ];
        comorbidityControls.forEach((control) => {
          if (noComorbiditiesValue) {
            this.comorbidityForm.get(control)?.disable();
            this.comorbidityForm.get(control)?.setValue(false);
          } else {
            this.comorbidityForm.get(control)?.enable();
          }
        });
      });

    this.comorbidityService.comorbidityEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editComorbidityId = data?.id;

          this.comorbidityForm.patchValue({
            dateValue: data?.date,
            noComorbiditiesValue: data?.isNoComorbidities,
            // iqrValue: data?.iqr,
            anyComorbidityValue: data?.anyComorbidity,
            respiratoryDiseaseValue: data?.isRespiratoryDisease,
            hypertensionValue: data?.isHypertension,
            copdValue: data?.isCopd,
            heartDiseaseValue: data?.isHeartDisease,
            diabetesMellitusValue: data?.isDiabetesMellitus,
            malignancyValue: data?.isMalignancy,
            strokeValue: data?.isStroke,
            immunosuppressionValue: data?.isImmunosuppression,
          });
        } else {
          this.isEdit = false;
          this.editComorbidityId = undefined;
          this.isLoading = false;
          this.editComorbidityId = undefined;
          this.comorbidityForm.reset();
          this.comorbidityForm.patchValue({
            dateValue: this.getCurrentDate(),
          });
        }
      });

    this.comorbidityService.comorbidityModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this.handleCancel();
  }

  handleCancel() {
    this.comorbidityForm.reset();

    this.comorbidityService.comorbidityModalSub.next(false);

    this.comorbiditySubmitted = false; // Change variable name

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.comorbiditySubmitted = true; // Change variable name

    if (this.comorbidityForm.valid) {
      const formValue = { ...this.comorbidityForm.value };
      Object.keys(formValue).forEach((key) => {
        if (formValue[key] === null) {
          formValue[key] = false;
        }
      });

      const comorbidityObj: IAddComorbidity = {
        id: this.editComorbidityId,
        date: formValue.dateValue,
        isNoComorbidities: formValue.noComorbiditiesValue,
        iqr: 0,
        anyComorbidity: formValue.anyComorbidityValue,
        isRespiratoryDisease: formValue.respiratoryDiseaseValue,
        isHypertension: formValue.hypertensionValue,
        isCopd: formValue.copdValue,
        isHeartDisease: formValue.heartDiseaseValue,
        isDiabetesMellitus: formValue.diabetesMellitusValue,
        isMalignancy: formValue.malignancyValue,
        isStroke: formValue.strokeValue,
        isImmunosuppression: formValue.immunosuppressionValue,
      };
      if (this.isEdit) {
        this.comorbidityService
          .editComorbidity(comorbidityObj) // Change to the appropriate service method
          .subscribe({
            next: () => {
              this.swalToastService.toastSuccess(
                'Comorbidity edited successfully'
              );
              this.comorbidityService.getData(); // Change to the appropriate service method

              this.handleCancel();
            },

            error: (err) => {
              this.swalToastService.toastError('Unable to edit comorbidity');

              this.handleCancel();
            },
          });
      } else {
        this.comorbidityService
          .addComorbidity(comorbidityObj) // Change to the appropriate service method
          .subscribe({
            next: () => {
              this.swalToastService.toastSuccess(
                'Comorbidity added successfully'
              );
              this.comorbidityService.getData(); // Change to the appropriate service method

              this.handleCancel();
            },
            error: (err) => {
              this.swalToastService.toastError('Unable to add comorbidity');
              this.handleCancel();
            },
          });
      }
    } else {
      this.isLoading = false;
    }
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }
}
