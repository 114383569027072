/* src/app/components/ehr/admission/admission-admit-modal/admission-admit-modal.component.css */
.form-spacing {
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}
form {
  padding-top: 0px;
}
.input-row {
  padding-top: 20px !important;
  display: flex !important;
  align-items: center !important;
  width: 100%;
}
.input-row-2 {
  display: flex !important;
  align-items: center !important;
  width: 100%;
}
.input-element {
  flex: 1 !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}
.label-row {
  flex: 0 0 20% !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 5px !important;
}
.optional {
  font-weight: 300 !important;
  font-size: 12px !important;
  display: flex;
  flex-direction: column;
}
.select {
  width: 100% !important;
}
.text-area {
  border: 1px solid #d9d9d9;
  padding: 6px 11px;
  border-radius: 4px;
}
.btn-row {
  margin-top: 20px !important;
  display: flex !important;
  justify-content: flex-end !important;
}
::ng-deep nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
.item-submitted .item.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=admission-admit-modal.component-FE5YZSMT.css.map */
