<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Medical Alerts"
        [columns]="['Description']"
        [rowFields]="['description']"
        [data]="medicalAlerts"
        [addComponent]="addComp"
        [deleteFun]="deleteFun"
        [isLoading]="isLoading"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-ma')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
