import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IPatient } from '../interfaces/IPatient';
import { IPatientDetailed } from '../interfaces/Detailed/IPatientDetailed';
import { IInpatient } from '../interfaces/IInpatient';
import {
  IAccommodationDropdown,
  IAccomodationPatients,
} from '../interfaces/Dropdown/IAccommodationDropdown';
import { IDashboardChart } from '../interfaces/Dashboard/IDashboardChart';
import { ISearchInpatient } from '../interfaces/ISearchInpatient';
import { COLORS } from 'src/styles/colors';

@Injectable({
  providedIn: 'root',
})
export class InpatientService {
  constructor(private _http: HttpClient) {}

  getInpatients(
    paramsObj: ISearchInpatient,
    pageSize: number = 10,
    pageNumber: number = 1
  ): Observable<IGenericServiceResponse<IInpatient[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IInpatient[]>>(
      `${environment.BACKEND_URL}AccommodationData/${pageNumber}/${pageSize}`,
      {
        headers: headers,
        params: new HttpParams({ fromObject: paramsObj as any }),
      }
    );
  }

  getDropdownData(): Observable<
    IGenericServiceResponse<IAccommodationDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IAccommodationDropdown>>(
      `${environment.BACKEND_URL}AccommodationData/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getAvailablePatients(): Observable<
    IGenericServiceResponse<IAccomodationPatients>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IAccomodationPatients>>(
      `${environment.BACKEND_URL}AccommodationData/DropdownData/AvailablePatients`,
      {
        headers: headers,
      }
    );
  }

  getDashboardInPatientByGender(): Observable<
    IGenericServiceResponse<IDashboardChart>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}AccommodationData/Dashboard/InPatientGender`,
      {
        headers: headers,
      }
    );
  }

  getDashboardAdmissionsByGender(): Observable<
    IGenericServiceResponse<IDashboardChart>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}Dashboard/AdmissionsByGender`,
      {
        headers: headers,
      }
    );
  }

  getDashboardInPatientByAge(): Observable<
    IGenericServiceResponse<IDashboardChart>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}AccommodationData/Dashboard/InPatientAge`,
      {
        headers: headers,
      }
    );
  }

  getDashboardInPatientByOlderAge(): Observable<
    IGenericServiceResponse<IDashboardChart>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}AccommodationData/Dashboard/InPatientOlderAge`,
      {
        headers: headers,
      }
    );
  }

  getDashboardAdmissionsByOlderAge(): Observable<
    IGenericServiceResponse<IDashboardChart>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDashboardChart>>(
      `${environment.BACKEND_URL}Dashboard/AdmissionsByAge`,
      {
        headers: headers,
      }
    );
  }
}
