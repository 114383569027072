<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Glasgow Coma Scale"
        [columns]="[
          'Date & Time',
          'Eye Opening',
          'Verbal Response',
          'Motor Response',
          'Score'
        ]"
        [rowFields]="[
          'onSetDateTime',
          'eyeOpeningName',
          'verbalResponseName',
          'motorResponseName',
          'totalScore'
        ]"
        [data]="glasgow"
        [addComponent]="addComp"
        [deleteFun]="deleteFun"
        [isLoading]="isLoading"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [refValues]="referenceValues"
        [showAddButton]="hasPermission('a-dmg')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
