/* src/app/components/ehr/patients/components/imaging-section/imaging-files/imaging-files.component.css */
::ng-deep nz-switch {
  margin-right: 0.5em;
}
.draft-entry td:first-child {
  border-left: 2px solid var(--color-1);
}
.edit-btn {
  margin-left: 1.5em;
  cursor: pointer;
}
.expandable-row {
  cursor: pointer;
}
tr:hover {
  cursor: pointer;
}
.document-view {
  border: 1px solid lightgray;
  min-width: 100% !important;
  margin-bottom: 2em;
}
.row {
  height: 100%;
}
.pdf-viewer {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.image-viewer {
  margin: 1em;
  height: 100%;
  max-height: 95%;
  max-width: 100%;
  cursor: pointer;
}
.preview-content {
  height: 60vh;
  width: 100%;
}
.preview-title {
  font-size: 18px;
  font-weight: 500;
  padding: 1em;
  height: 64px;
  border-bottom: 1px solid lightgray;
  background: var(--color-6) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.download-btn:hover {
  cursor: pointer;
}
.anticon {
  display: block;
  margin: 1em;
}
.folder-view-icon.anticon {
  scale: 2.5 !important;
}
.preview {
  margin-bottom: 2em;
}
.file-paginator {
  margin-top: 1em;
  margin-bottom: 1em;
}
::ng-deep .ant-switch-checked {
  background-color: white !important;
}
::ng-deep .ant-switch {
  background-color: white !important;
  border: 1px solid #9c9c9c !important;
  min-width: 50px !important;
}
::ng-deep .ant-switch-handle::before {
  background-color: #9c9c9c;
}
.preview-buttons {
  margin: 1em;
}
.active-btn {
  color: black !important;
  background: var(--color-3) !important;
}
.inactive-btn {
  color: black !important;
  background: rgba(199, 199, 199, 0.3) !important;
}
.modal-pdf {
  min-height: 70vh !important;
  min-width: 100% !important;
}
.selected-preview {
  background-color: var(--color-7) !important;
}
.file-name {
  margin-bottom: 0.5em;
}
/*# sourceMappingURL=imaging-files.component-VIP7CUGV.css.map */
