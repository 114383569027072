/* src/app/components/ehr/patients/components/inital-assessment-amen-section/dynamic-questionnaire/dynamic-questions/dynamic-questions.component.css */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
.add-patient-row {
  margin-bottom: 1.5em;
}
.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
}
.add-patient-input,
.textarea-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: static;
  width: 100%;
  box-sizing: border-box;
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var( --sjs-editorpanel-backcolor, var( --sjs-editor-background, var( --sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9) ) ) );
  border: none;
  border-radius: var( --sjs-editorpanel-cornerRadius, var(--sjs-corner-radius, 4px) );
  text-align: start;
  box-shadow: var(--sjs-shadow-inner, inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)), 0 0 0 0px var(--sjs-primary-backcolor, var(--primary, #19b394));
  transition: box-shadow var(--sjs-transition-duration, 150ms);
}
.add-patient-input:focus,
.textarea-input:focus {
  box-shadow: var( --sjs-shadow-inner-focus, inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15) ), 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, COLORS.green1));
  outline: none;
}
.textarea-input {
  min-height: 100px;
  resize: vertical;
  overflow: auto;
}
.add-patient-inputZorro {
  width: 100%;
}
.nz-select.ng-invalid.ng-touched,
input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
.nz-select.ng-valid.ng-touched,
input.ng-valid.ng-touched,
select.ng-valid.ng-touched,
textarea.ng-valid.ng-touched {
  color: black;
}
input,
textarea {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: transparent;
}
.add-patient-button {
  height: 100%;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: var(--color-0) !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
.back-button {
  margin-right: 1em;
  margin-bottom: 2em;
  height: 35px;
  border: 1px solid #ff2626;
  border-radius: 4px;
  background-color: white;
  color: #ff2626;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.submitted .ng-invalid {
  border: red 1px solid;
}
.radio-button-list label {
  display: block;
  padding: 5px 0;
}
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.radio-label input {
  margin-right: 8px;
}
input[type=radio] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #c2c2c2;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}
input[type=radio]:checked {
  background-color: var(--color-1);
  border: 2px solid var(--color-1);
}
input[type=radio]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}
.title {
  margin: 12px 0px 12px 0px !important;
  font-size: 12pt !important;
  font-family:
    "docs-Roboto",
    Helvetica,
    Arial,
    sans-serif !important;
  letter-spacing: 0 !important;
}
.add-patient-input,
.textarea-input {
  font-family:
    Roboto,
    Arial,
    sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0.2px !important;
  line-height: 20px !important;
  color: rgb(32, 33, 36) !important;
}
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.radio-label input {
  margin-right: 8px;
  vertical-align: middle;
}
input[type=radio] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #c2c2c2;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
input[type=radio]:checked {
  background-color: var(--color-1);
  border: 2px solid var(--color-1);
}
input[type=radio]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}
.radio-label span {
  vertical-align: middle;
}
/*# sourceMappingURL=dynamic-questions.component-LDKWK4V2.css.map */
