import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { IAddComplication } from 'src/app/interfaces/Complication/IAddComplication';
import { IComplication } from 'src/app/interfaces/Complication/IComplication';
import { GetComplication } from 'src/app/interfaces/Complication/IGetComplication';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { ComplicationService } from 'src/app/services/complication.service';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { t } from '@fullcalendar/resource/internal-common';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-complication',
  templateUrl: './complication.component.html',
  styleUrls: ['./complication.component.css'],
})
export class ComplicationComponent implements OnInit {
  initialComplicationData: any[] = [];
  loading: boolean = false;
  patiendId!: number;
  visitId!: number;
  eocId!: number;
  addComplicationObj: IAddComplication = { complicationId: 0 };
  getComplicationData: GetComplication[] = [];

  constructor(
    private complicationService: ComplicationService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit(): void {
    this.fetchAllComplicationData();
    this.fetchVisitComplicationData();
    this.synchronizeCheckedStates();
  }

  // get all complication data from database
  fetchAllComplicationData(): void {
    this.loading = true;
    this.complicationService.getComplicationDataDropDown().subscribe({
      next: (response) => {
        this.initialComplicationData = response.data.complication.map(
          (item) => ({
            ...item,
            checked: false,
          })
        );
        this.loading = false;
      },
      error: (err) => {
        this.swalToastService.toastError(
          'Unable to retrieve complication data'
        );
      },
    });
  }

  // get the visit's only complication data
  fetchVisitComplicationData(): void {
    this.loading = true;
    this.complicationService.getComplication().subscribe({
      next: (response) => {
        if (response.succeed && Array.isArray(response.data)) {
          this.getComplicationData = response.data;
          this.synchronizeCheckedStates();
        }
        this.loading = false;
      },
      error: (err) => {
        this.swalToastService.toastError(
          'Unable to retrieve complication data'
        );
        this.loading = false;
      },
    });
  }
  // update the initial complication data checked values based on the visit's only complication data
  synchronizeCheckedStates(): void {
    // Map through initialComplicationData to update their 'checked' property
    this.initialComplicationData = this.initialComplicationData.map(
      (initialItem) => {
        const isComplicationChecked = this.getComplicationData.some(
          (getComplicationItem) =>
            getComplicationItem.complicationId === initialItem.id &&
            getComplicationItem.isChecked
        );
        const isComplicationSubmitted = this.getComplicationData.some(
          (getComplicationItem) =>
            getComplicationItem.complicationId === initialItem.id &&
            getComplicationItem.isSubmitted
        );
        return {
          ...initialItem,
          checked: isComplicationChecked,
          isSubmitted: isComplicationSubmitted,
        };
      }
    );
  }
  // checked complications
  get checkedItems(): any[] {
    return this.initialComplicationData.filter((item) => item.checked);
  }
  // uncecked complications
  get uncheckedItems(): any[] {
    return this.initialComplicationData.filter((item) => !item.checked);
  }

  //add complication
  onCheckChange(item: any): void {
    this.loading = true;
    this.addComplicationObj.complicationId = item.id;
    this.complicationService
      .addComplication(this.addComplicationObj)
      .subscribe({
        next: () => {
          this.loading = false;
          this.swalToastService.toastSuccess('Complication added successfully');
          this.fetchVisitComplicationData();
        },
        error: (err) => {
          this.swalToastService.toastError('Unable to add complication');

          this.loading = false;
        },
      });
  }

  //delete complication
  onUncheckChange(item: any): void {
    this.loading = true;

    const matchingComplication = this.getComplicationData.find(
      (complication) => complication.complicationId === item.id
    );
    if (matchingComplication) {
      this.getComplicationData.push(matchingComplication);
      this.complicationService
        .deleteComplication(matchingComplication.id)
        .subscribe({
          next: () => {
            this.loading = false;
            this.swalToastService.toastSuccess(
              'Complication deleted successfully'
            );
            this.fetchVisitComplicationData();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to add complication');

            this.loading = false;
          },
        });
    }
  }
}
