import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { IAddMedicalInfo } from '../../../../../../../interfaces/MedicalInfo/IAddMedicalInfo';
import { CarePlanService } from '../../../../../../../services/carePlan.service';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  standalone: true,
  selector: 'app-care-plan-add-edit',
  templateUrl: './care-plan-add-edit.component.html',
  styleUrls: ['./care-plan-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class CarePlanAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editMedicalAlertId?: number;

  carePlanSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  carePlanForm: FormGroup = new FormGroup({});

  constructor(
    private readonly carePlanService: CarePlanService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = false;

    this.carePlanForm = this.formBuilder.group({
      descriptionValue: [null, Validators.required],
    });

    this.carePlanService.carePlanEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editMedicalAlertId = data?.id;

          this.carePlanForm.patchValue({
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editMedicalAlertId = undefined;

          this?.carePlanForm.reset();
        }
      });

    this.carePlanService.carePlanModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.carePlanForm.reset();

    this.carePlanService.carePlanModalSub.next(false);

    this.carePlanSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.carePlanSubmitted = true;

    if (this.carePlanForm.valid) {
      const carePlanObj: IAddMedicalInfo = {};
      carePlanObj.id = this.editMedicalAlertId;
      carePlanObj.description = this.carePlanForm.value?.descriptionValue;

      if (this.isEdit) {
        this.carePlanService.editCarePlan(carePlanObj).subscribe({
          next: () => {
            this.swalToastService.toastSuccess('Care Plan edited successfully');
            this.carePlanService.getData();

            this.handleCancel();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to edit care plan');

            this.handleCancel();
          },
        });
      } else {
        this.carePlanService.addCarePlan(carePlanObj).subscribe({
          next: () => {
            this.swalToastService.toastSuccess('Care Plan added successfully');
            this.carePlanService.getData();

            this.handleCancel();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to add care plan');
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
