/* src/app/components/ehr/patients/components/tracheostomy-section/ct-parameters/ct-parameters-add-edit/ct-parameters-add-edit.component.css */
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
nz-select {
}
.ctParameters-submitted .ctParameters.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=ct-parameters-add-edit.component-YEG7X4V3.css.map */
