/* src/app/components/ehr/patients/components/pregnancy-section/pregnancy-history/pregnancy-history-add-edit/pregnancy-history-add-edit.component.css */
.empty-table {
  margin-bottom: 3em;
}
.history-submitted .history.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=pregnancy-history-add-edit.component-RHGBV64J.css.map */
