<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Travel History"
        [columns]="['Country', 'Arrival Date', 'Departure Date']"
        [rowFields]="['country', 'arrivalDate', 'departureDate']"
        [data]="travelHistory"
        [addComponent]="addComp"
        [deleteFun]="deleteFun"
        [isLoading]="isLoading"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-th')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
