import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { ISocialHistoryDetailed } from '../interfaces/SocialHistory/ISocialHistoryDetailed';
import { IAddSocialHistory } from '../interfaces/SocialHistory/IAddSocialHistory';
import { ISocialHistoryDropdown } from '../interfaces/SocialHistory/ISocialHistoryDropdown';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class SocialHistoryService {
  public socialHistoryModalSub = new BehaviorSubject<boolean>(false);

  public socialHistoryEditSub = new BehaviorSubject<
    ISocialHistoryDetailed | undefined
  >(undefined);

  public socialHistoryDataSub = new BehaviorSubject<ISocialHistoryDetailed[]>(
    []
  );

  public socialHistoryLoadingSub = new BehaviorSubject<boolean>(false);
  public socialHistoryLoadingSub$ = this.socialHistoryLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.socialHistoryLoadingSub.next(true);
    this.getSocialHistory(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.socialHistoryDataSub.next(response?.data!);
        this.socialHistoryLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve social history');
        console.error('Error fetching social history:', err);
      },
    });
  }

  getSocialHistory(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<ISocialHistoryDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<ISocialHistoryDetailed[]>>(
        `${environment.BACKEND_URL}SocialHistory/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<ISocialHistoryDetailed[]>>(
        `${environment.BACKEND_URL}SocialHistory/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  getSocialHistoryDropdown(): Observable<
    IGenericServiceResponse<ISocialHistoryDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<ISocialHistoryDropdown>>(
      `${environment.BACKEND_URL}SocialHistory/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addSocialHistory(socialHistoryObj: IAddSocialHistory) {
    socialHistoryObj.visitId = this.eocService.currentVisitId();
    socialHistoryObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}SocialHistory`,
      socialHistoryObj,
      {
        headers: headers,
      }
    );
  }

  editSocialHistory(socialHistoryObj: IAddSocialHistory) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}SocialHistory/${socialHistoryObj.id}`,
      socialHistoryObj,
      {
        headers: headers,
      }
    );
  }

  deleteSocialHistory(socialHistoryId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}SocialHistory/${socialHistoryId}`,
      {
        headers: headers,
      }
    );
  }

  deleteSocialHistoryFromTable(socialHistoryId: string) {
    // REMOVE THE MedicalAlert
    this.socialHistoryDataSub.next(
      this.socialHistoryDataSub.value.filter(
        (ma) => ma.id?.toString() != socialHistoryId
      )
    );
  }
}
