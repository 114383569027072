import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { SocialHistoryAddEditComponent } from './social-history-add-edit/social-history-add-edit.component';
import { ISocialHistoryDetailed } from '../../../../../../interfaces/SocialHistory/ISocialHistoryDetailed';
import { SocialHistoryService } from '../../../../../../services/socialHistory.service';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-social-history',
  templateUrl: './social-history.component.html',
  styleUrls: ['./social-history.component.css'],
})
export class SocialHistoryComponent {
  public addComp = SocialHistoryAddEditComponent;

  private destroy$ = new Subject<void>();

  socialHistory?: ISocialHistoryDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly socialHistoryService: SocialHistoryService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {}

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  openModalFun(item?: ISocialHistoryDetailed) {
    this.socialHistoryService.socialHistoryModalSub.next(true);
    this.socialHistoryService.socialHistoryEditSub.next(item);
  }

  deleteFun(socialHistoryId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Social History')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.socialHistoryService
          .deleteSocialHistory(socialHistoryId.toString())
          .subscribe({
            next: (d) => {
              this.swalToastService.toastSuccess(
                'Social History deleted successfully'
              );
              this.socialHistoryService.deleteSocialHistoryFromTable(
                socialHistoryId.toString()
              );
            },
            error: (err) => {
              this.swalToastService.toastError(
                'Unable to delete Social History'
              );
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Social history is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
  ngOnInit() {
    this.socialHistoryService.socialHistoryLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.socialHistoryService.socialHistoryDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ISocialHistoryDetailed[]) => {
        this.socialHistory = data;
      });

    this.socialHistoryService.getData();
  }
}
