/* src/app/components/ehr/patients/components/daily-monitoring-section/arterial-blood-gas/arterial-blood-gas-add-edit/arterial-blood-gas-add-edit.component.css */
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
.abg-submitted .abg.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=arterial-blood-gas-add-edit.component-NLVVS6BU.css.map */
