/* src/app/common/reusable-table/reusable-table.component.css */
::ng-deep nz-switch {
  margin-right: 0.5em;
}
.draft-entry td:first-child {
  border-left: 2px solid var(--color-1);
}
.deleted-draft-entry td:first-child {
  border-left: 2px solid #dd6153;
}
.edit-btn {
  margin-left: 1.5em;
  cursor: pointer;
}
.expandable-row {
  cursor: pointer;
}
/*# sourceMappingURL=reusable-table.component-MYUTZQF3.css.map */
