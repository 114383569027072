import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { COLORS } from 'src/styles/colors';
import { WeightService } from 'src/app/services/weight.service';
import { IAddWeight } from 'src/app/interfaces/Weight/IAddWeight';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-weight-add-edit',
  standalone: true,
  templateUrl: './weight-add-edit.component.html',
  styleUrl: './weight-add-edit.component.css',
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class WeightAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editWeightId?: number;

  weightSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  weightForm: FormGroup = new FormGroup({});

  constructor(
    private readonly weightService: WeightService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = false;

    this.weightForm = this.formBuilder.group({
      weightValue: [null, Validators.required],
      descriptionValue: [null],
    });

    this.weightService.weightEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editWeightId = data?.id;

          this.weightForm.patchValue({
            weightValue: data?.weight,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editWeightId = undefined;

          this?.weightForm.reset();
        }
      });

    this.weightService.weightModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.weightForm.reset();

    this.weightService.weightModalSub.next(false);

    this.weightSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.weightSubmitted = true;

    if (this.weightForm.valid) {
      const weightObj: IAddWeight = {};
      weightObj.id = this.editWeightId;
      weightObj.weight = this.weightForm.value?.weightValue;
      weightObj.description = this.weightForm.value?.descriptionValue;

      if (this.isEdit) {
        this.weightService.editWeight(weightObj).subscribe({
          next: () => {
            this.swalToastService.toastSuccess(
              'Weight record edited successfully'
            );
            this.weightService.getData();

            this.handleCancel();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to edit Weight record');
            this.handleCancel();
          },
        });
      } else {
        this.weightService.addWeight(weightObj).subscribe({
          next: () => {
            this.swalToastService.toastSuccess(
              'Weight record added successfully'
            );
            this.weightService.getData();

            this.handleCancel();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to add Weight record');
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
