import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Helper function to add/subtract days
function adjustDate(date: string | undefined, minutes: number): Date | null {
  if (!date) return null;
  const adjustedDate = new Date(date);
  adjustedDate.setMinutes(adjustedDate.getMinutes() + minutes);
  return adjustedDate;
}

// Validator for Pending Date
export function pendingDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  const form = control.parent;
  if (!form) return null;

  const controlDate = new Date(control.value);
  const maxDate =
    adjustDate(
      form.get('waitingListDate')?.value ||
        form.get('approveDate')?.value ||
        form.get('admissionDate')?.value ||
        form.get('dischargeDate')?.value,
      -1
    ) || new Date();

  return maxDate && controlDate > maxDate ? { invalidPendingDate: true } : null;
}

// Validator for Waiting List Date
export function waitingListDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  const form = control.parent;
  if (!form) return null;

  const controlDate = new Date(control.value);
  const minDate = adjustDate(form.get('pendingDate')?.value, 1);
  const maxDate =
    adjustDate(
      form.get('approveDate')?.value ||
        form.get('admissionDate')?.value ||
        form.get('dischargeDate')?.value,
      -1
    ) || new Date();

  if (minDate && controlDate < minDate) return { waitingListTooEarly: true };
  if (maxDate && controlDate > maxDate) return { waitingListTooLate: true };

  return null;
}

// Validator for Approval Date
export function approvalDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  const form = control.parent;
  if (!form) return null;

  const controlDate = new Date(control.value);
  const minDate = adjustDate(form.get('waitingListDate')?.value, 1);
  const maxDate =
    adjustDate(
      form.get('admissionDate')?.value || form.get('dischargeDate')?.value,
      -1
    ) || new Date();

  if (minDate && controlDate < minDate) return { approvalTooEarly: true };
  if (maxDate && controlDate > maxDate) return { approvalTooLate: true };

  return null;
}

// Validator for Admission Date
export function admissionDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  const form = control.parent;
  if (!form) return null;

  const controlDate = new Date(control.value);
  const minDate = adjustDate(form.get('approveDate')?.value, 1);
  const maxDate =
    adjustDate(form.get('dischargeDate')?.value, -1) || new Date();

  if (minDate && controlDate < minDate) return { admissionTooEarly: true };
  if (maxDate && controlDate > maxDate) return { admissionTooLate: true };

  return null;
}

// Validator for Discharge Date
export function dischargeDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  const form = control.parent;
  if (!form) return null;

  const controlDate = new Date(control.value);
  const minDate = adjustDate(form.get('admissionDate')?.value, 1);
  const maxDate = new Date();

  if (minDate && controlDate < minDate) return { dischargeTooEarly: true };
  if (maxDate && controlDate > maxDate) return { dischargeTooLate: true };

  return null;
}

// Validator for Rejection Date
export function rejectionDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  const form = control.parent;
  if (!form) return null;

  const controlDate = new Date(control.value);
  const minDate = adjustDate(
    form.get('waitingListDate')?.value || form.get('pendingDate')?.value,
    1
  );
  const maxDate = new Date();

  if (minDate && controlDate < minDate) return { rejectionTooEarly: true };
  if (maxDate && controlDate > maxDate) return { rejectionTooLate: true };

  return null;
}

export function requiredIfAllNextFieldsEmpty(
  nextFieldControlNames: string[]
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control?.parent;
    if (!formGroup) return null;

    // Check if all the next fields are empty
    let allNextFieldsEmpty = true;
    for (let nextFieldControlName of nextFieldControlNames) {
      const nextField = formGroup.get(nextFieldControlName);
      if (nextField && nextField.value) {
        allNextFieldsEmpty = false;
        break; // As soon as we find a field with a value, stop checking
      }
    }

    // If all next fields are empty, mark the current field as required
    if (allNextFieldsEmpty && !control.value) {
      return { requiredIfAllNextFieldsEmpty: true };
    }

    return null;
  };
}

export function dischargeOrRejectValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dischargeDate = control.get('dischargeDate')?.value;
    const rejectDate = control.get('rejectDate')?.value;

    if (!dischargeDate && !rejectDate) {
      return { dischargeOrRejectRequired: true };
    }

    return null;
  };
}

export function editDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  const form = control.parent;
  if (!form) return null;

  const controlDate = new Date(control.value);
  const minDate = form.get('minDate')?.value
    ? new Date(form.get('minDate')?.value)
    : undefined;
  const maxDate = form.get('maxDate')?.value
    ? new Date(form.get('maxDate')?.value)
    : undefined;

  if (minDate && controlDate < minDate) return { editDateTooEarly: true };
  if (maxDate && controlDate > maxDate) return { editDateTooLate: true };

  return null;
}
