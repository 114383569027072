<div class="external-container">
  <!-- Title -->
  <div class="navigator title">
    {{ "External Doctors" | translate }}

    <span *ngIf="totalPages === 0" class="info-container">
      <button (click)="addNewDoctor()" nz-button [disabled]="!isEditMode" class="nav-btn-plus add-external-btn"
        nz-tooltip nzTooltipTitle="{{ 'Add new external doctor' | translate }}" nzTooltipPlacement="top">
        <span class="plus-circle" nz-icon nzType="plus-circle" nzTheme="outline"></span>
      </button>
    </span>
  </div>

  <!-- Form for external doctors -->
  <form *ngIf="totalPages > 0" [formGroup]="externalDoctorsForm" [ngClass]="{ submitted: formSubmitted }">
    <div formArrayName="externalDoctors">
      <ng-container *ngFor="
          let doctor of externalDoctors.controls;
          let i = index;
          trackBy: trackByFn
        ">
        <div *ngIf="i === currentPage" [formGroupName]="i">
          <h3 class="navigator-2">
            <button (click)="previousPage()" nz-button [disabled]="currentPage === 0" class="nav-btn">
              <span nz-icon nzType="caret-left" nzTheme="outline"></span>
            </button>
            {{ "External Doctor" | translate }}
            <span class="num-con">{{ currentPage + 1 }}</span>
            {{ "of" | translate }}
            <span class="num-con">{{ totalPages }}</span>
            <button (click)="nextPage()" nz-button [disabled]="currentPage >= totalPages - 1" class="nav-btn">
              <span nz-icon nzType="caret-right" nzTheme="outline"></span>
            </button>

            <button (click)="addNewDoctor()" nz-button [disabled]="!isEditMode" class="nav-btn-plus add-external-btn"
              nz-tooltip nzTooltipTitle="{{ 'Add new external doctor' | translate }}" nzTooltipPlacement="top">
              <span class="plus-circle" nz-icon nzType="plus-circle" nzTheme="outline"></span>
            </button>
            <button (click)="removeDoctor(i)" nz-button [disabled]="!isEditMode"
              class="nav-btn-delete delete-external-btn" nz-tooltip
              nzTooltipTitle="{{ 'Remove external doctor' | translate }}" nzTooltipPlacement="top">
              <span class="delete" nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </h3>

          <!-- External doctor lookup -->
          <div class="select-container">
            <div fxLayout="row" fxLayoutAlign="start center" class="add-patient-row"
              style="gap: 16px; margin-left: 14px">
              <label nz-tooltip nzTooltipTitle="{{ 'Cyprus Medical Association' | translate }}">
                {{ "Search from CyMA list" | translate }}</label>
              <!-- <nz-switch
              [formControlName]="'toggleControl'"
              (ngModelChange)="onExternalDoctorToggle($event, i)"
              [disabled]="!isEditMode"
            ></nz-switch> -->

              <nz-switch [nzCheckedChildren]="checkedTemplate" [nzUnCheckedChildren]="unCheckedTemplate"
                [disabled]="!isEditMode" (ngModelChange)="onExternalDoctorToggle($event, i)"
                [formControlName]="'toggleControl'"></nz-switch>
              <ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
              <ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center" class="add-patient-row"
              style="gap: 16px; margin-left: 14px">
              <label>{{ "Select doctor from list " | translate }}</label>
              <span class="optional">{{ "(optional)" | translate }}</span>
              <div fxFlex="40">
                <nz-select *ngIf="externalDoctors.at(i).get('toggleControl')?.value" class="add-patient-inputZorro"
                  nzShowSearch nzAllowClear formControlName="externalDoctorCymaId"
                  [nzOptions]="externalDoctorsCymaDropdown" (ngModelChange)="onDoctorSelectionChange($event, i, true)"
                  [disabled]="!isEditMode" nzPlaceHolder="{{ 'Select external Doctor' | translate }}"></nz-select>

                <nz-select *ngIf="!externalDoctors.at(i).get('toggleControl')?.value" class="add-patient-inputZorro"
                  nzShowSearch nzAllowClear formControlName="doctorId" [nzOptions]="externalDoctorsDropdown"
                  (ngModelChange)="onDoctorSelectionChange($event, i, false)" [disabled]="!isEditMode"
                  nzPlaceHolder="{{ 'Select external Doctor' | translate }}"></nz-select>
              </div>
            </div>
          </div>

          <!-- Doctor Information -->
          <div class="layout " fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayout="row" fxLayoutAlign="start center" class="label-row large-screen w-100" fxLayoutGap="10px">
              <div fxFlex="25" class="add-patient-col w-100">
                <div>{{ "First Name" | translate }}</div>
                <input class="add-patient-input" formControlName="firstName" [maxLength]="50" />
              </div>
              <div fxFlex="25" class="add-patient-col w-100">
                <div>{{ "Last Name" | translate }}</div>
                <input class="add-patient-input" formControlName="lastName" [maxLength]="50" />
              </div>
              <div fxFlex="25" class="add-patient-col w-100">
                <div>
                  {{ "Phone Number" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <input class="add-patient-input" formControlName="phoneNumber" [maxLength]="50" />
              </div>
              <div fxFlex="25" class="add-patient-col w-100">
                <div>{{ "Registration Number" | translate }}</div>
                <input class="add-patient-input" formControlName="registrationNumber" [maxLength]="50" />
              </div>
            </div>

            <!-- Address Section -->
            <span formGroupName="addressData" class="w-100 ">
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center"
                class="label-row large-screen small-screen w-100" fxLayoutGap="10px">
                <div fxLayout="column" fxFlex="50" class="add-patient-col w-100" fxLayoutAlign="center start">
                  <div>
                    {{ "Street" | translate }}
                    <span class="optional">{{ "(optional)" | translate }}</span>
                  </div>
                  <input class="add-patient-input" formControlName="street" [maxLength]="50" />
                </div>

                <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                  <div>
                    {{ "Street Number" | translate }}
                    <span class="optional">{{ "(optional)" | translate }}</span>
                  </div>
                  <input class="add-patient-input" formControlName="streetNumber" [maxLength]="50" />
                </div>

                <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                  <div>
                    {{ "Apartment Number" | translate }}
                    <span class="optional">{{ "(optional)" | translate }}</span>
                  </div>
                  <input class="add-patient-input" formControlName="apartmentNumber" [maxLength]="50" />
                </div>
              </div>

              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center center"
                class="label-row large-screen small-screen w-100" fxLayoutGap="10px">
                <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                  <div>
                    {{ "Postcode" | translate }}
                    <span class="optional">{{ "(optional)" | translate }}</span>
                  </div>
                  <input class="add-patient-input" formControlName="postCode" [maxLength]="50" />
                </div>

                <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                  <div>
                    {{ "Country" | translate }}
                    <span class="optional">{{ "(optional)" | translate }}</span>
                  </div>
                  <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="countryId"
                    (ngModelChange)="onCountryChange($event)">
                    <ng-container *ngFor="let place of countriesList">
                      <nz-option [nzLabel]="place.name" [nzValue]="place.id"></nz-option>
                    </ng-container>
                  </nz-select>
                </div>

                <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                  <div>
                    {{ "Town" | translate }}
                    <span class="optional">{{ "(optional)" | translate }}</span>
                  </div>
                  <ng-container *ngIf="!isCountryCyprus; else cyprusDropdown">
                    <input class="add-patient-input" formControlName="town" [maxLength]="50" />
                  </ng-container>
                  <!-- Dropdown if Cyprus -->
                  <ng-template #cyprusDropdown>
                    <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear
                      [nzPlaceHolder]="'Select a Town' | translate" formControlName="town">
                      <ng-container *ngFor="let city of cyprusCities">
                        <nz-option [nzLabel]="city | translate" [nzValue]="city"></nz-option>
                      </ng-container>
                    </nz-select>
                  </ng-template>
                </div>

                <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                  <div>
                    {{ "District" | translate }}
                    <span class="optional">{{ "(optional)" | translate }}</span>
                  </div>
                  <input class="add-patient-input" formControlName="district" [maxLength]="50" />
                </div>
              </div>
            </span>

            <!-- Specialties Section -->
            <div fxLayout="row" fxLayoutAlign="start center" class="label-row large-screen w-100" fxLayoutGap="10px">
              <div fxFlex="100" class="add-patient-col w-100">
                <div>
                  {{ "Specialties" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <nz-select [nzMode]="'multiple'" [nzPlaceHolder]="
                    (isExternalDoctor ? 'Not available' : 'Select specialties')
                      | translate
                  " [nzDisabled]="isExternalDoctor" formControlName="specialties" class="add-patient-inputZorro"
                  nzShowSearch>
                  <nz-option *ngFor="let specialty of allSpecialties" [nzValue]="specialty.id"
                    [nzLabel]="specialty.name"></nz-option>
                </nz-select>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>