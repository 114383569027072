<form [formGroup]="medicalInfoForm" [ngClass]="{ submitted: formSubmitted }">
  <div class="layout">
    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="50" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Doctor's Full Name" | translate }}
      </div>

      <div fxLayout="col" fxFlex="50" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Select Primary Doctor" | translate }}
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="column" fxFlex="50" class="add-patient-col h100i" fxLayoutAlign="center start">
        <nz-select class="add-patient-inputZorro" nzMode="multiple" nzShowSearch nzAllowClear
          formControlName="assignedDoctors" [nzOptions]="doctors" (ngModelChange)="onDoctorSelectionChange($event)">
        </nz-select>
      </div>

      <div fxLayout="column" fxFlex="50" class="add-patient-col h100i" fxLayoutAlign="center start">
        <div fxLayout="row wrap">
          <div *ngFor="let doctor of selectedDoctors" style="margin-right: 20px">
            <label class="radio-label">
              <input [disabled]="!isEditMode" [style]="{ cursor: isEditMode ? 'pointer' : 'not-allowed' }" type="radio"
                name="primaryDoctor" (change)="setPrimaryDoctor(doctor.doctorId)" [value]="doctor.doctorId"
                [checked]="doctor.primaryDoctor" />
              <span>{{ doctor.doctorFullName }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" class="label-row">
      <div fxLayout="row" class="add-patient-col" fxLayoutAlign="start center ">
        <input type="checkbox" class="custom-checkbox" id="moh" formControlName="moh" />
        <label for="moh" class="hio">{{
          "Chronic patient from MOH/DMSW" | translate
          }}</label>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
      <div fxLayout="col" fxFlex="45" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Immobility" | translate }}
      </div>

      <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Blood Type" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>

      <div fxLayout="col" fxFlex="15" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Height" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>

      <div fxLayout="col" fxFlex="15" class="add-patient-col" fxLayoutAlign="start center">
        {{ "Weight" | translate }}
        <span class="optional">{{ "(optional)" | translate }}</span>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
      <div fxLayout="column" fxFlex="45" class="add-patient-col" fxLayoutAlign="center start">
        <nz-select class="immobility-select" nzShowSearch nzAllowClear [nzPlaceHolder]="'Immobility' | translate"
          formControlName="immobilityValue" class="add-patient-inputZorro" [nzOptions]="immobilityList">
        </nz-select>
      </div>

      <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="center start">
        <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="bloodTypeId">
          <ng-container *ngFor="let bt of bloodTypesList">
            <nz-option [nzLabel]="bt.name" [nzValue]="bt.id"></nz-option>
          </ng-container>
        </nz-select>
      </div>

      <div fxLayout="column" fxFlex="15" class="add-patient-col" fxLayoutAlign="center start">
        <input type="number" class="add-patient-input" formControlName="height" />
      </div>

      <div fxLayout="column" fxFlex="15" class="add-patient-col" fxLayoutAlign="center start">
        <input type="number" class="add-patient-input" formControlName="weight" />
      </div>
    </div>

    <app-external-doctors *ngIf="admissionExternalDoctors" [externalDoctorsDropdown]="externalDoctorsDropdownVersion"
      [externalDoctorsApi]="externalDoctorsApi" [externalDoctorsCymaDropdown]="externalDoctorsCymaDropdownVersion"
      [externalDoctorsCymaApi]="externalDoctorsCymaApi" [isEditMode]="isEditMode" [formSubmitted]="formSubmitted"
      [countriesList]="countriesList" [doctorSpecialties]="doctorSpecialties"
      [admissionExternalDoctors]="admissionExternalDoctors"
      [admissionExternalDoctorsCyma]="admissionExternalDoctorsCyma"></app-external-doctors>
  </div>
</form>