import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const permissionsToken = this.authService.getPermissionsToken();
    let clonedRequest = req;

    if (permissionsToken) {
      clonedRequest = clonedRequest.clone({
        setHeaders: {
          'X-Backend-Token': permissionsToken,
        },
      });
    }

    return next.handle(clonedRequest);
  }
}
