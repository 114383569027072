import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { IDischargeDropdown } from '../interfaces/Discharge/IDischargeDropdown';
import { IAddDischarge } from '../interfaces/Discharge/IAddDischarge';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class DischargeService {
  constructor(private readonly _http: HttpClient) {}

  addDischarge(dischargeObj: IAddDischarge) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Discharge`,
      dischargeObj,
      {
        headers: headers,
      }
    );
  }

  getDischargeDropdown(
    eocID: string
  ): Observable<IGenericServiceResponse<IDischargeDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const params = new HttpParams().set('episodeCareId', eocID);
    return this._http.get<IGenericServiceResponse<IDischargeDropdown>>(
      `${environment.BACKEND_URL}Discharge/DropdownData`,
      {
        headers: headers,
        params: params,
      }
    );
  }
}
