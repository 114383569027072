<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Weight"
        [columns]="['Date', 'Weight (kg)', 'Description']"
        [rowFields]="['creationDate', 'weight', 'description']"
        [data]="weightData"
        [addComponent]="addComp"
        [deleteFun]="deleteFun"
        [isLoading]="isLoading"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-wei')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
