import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, filter } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { selectMenu } from 'src/app/selectors/menu.selector';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';

@Component({
  selector: 'app-ehr',
  templateUrl: './ehr.component.html',
  styleUrls: ['./ehr.component.css'],
})
export class EhrComponent {
  // All Patients
  // Default active Menu item
  private defaultTab: number = 0;

  public menuList: IModule[] = [];
  public activeButton: string = '';
  private subscription: Subscription;
  private subscriptions: Subscription[] = [];

  ongoingHysteroscopyExamination: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly hysteroscopyFileService: HysteroscopyFileService
  ) {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((url: any) => {
        if (url?.url) {
          const segments = url?.url?.split('/');
          const lastSegment = segments[segments.length - 1];

          this.activeButton = lastSegment;
        }
      });
    this.subscriptions.push(
      this.hysteroscopyFileService
        .getExaminationStatus()
        .subscribe((status: boolean) => {
          if (status) {
            this.ongoingHysteroscopyExamination = status;
          } else {
            this.ongoingHysteroscopyExamination = false;
          }
        })
    );
  }

  ngOnInit() {
    this.store.select(selectMenu).subscribe((menu) => {
      this.menuList = menu;
      if (this.router.url === '/ehr') {
        const defaultPath = this.menuList[this.defaultTab]?.path;
        this.router.navigate(['/ehr', defaultPath]);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
