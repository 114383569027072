import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ReusableTableExpandComponent } from 'src/app/common/reusable-table-expand/reusable-table-expand.component';
import { SwalToastService } from 'src/app/services/swal.service';
import { IGetEpisodesOfCare } from 'src/app/interfaces/IGetEpisodesOfCare';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';
import { COLORS } from 'src/styles/colors';
import Swal from 'sweetalert2';

@Component({
  standalone: true,
  selector: 'app-visit-expand',
  templateUrl: './visit-expand.component.html',
  styleUrls: ['./visit-expand.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    ReusableTableExpandComponent,
    FlexModule,
    NzIconModule,
    NzButtonModule,
    NzIconModule,
  ],
})
export class VisitExpandComponent {
  @Input() item: IGetEpisodesOfCare = {};

  isVisitloading: boolean = false;
  isReadOnly: boolean = true;

  constructor(
    private readonly router: Router,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly keycloakService: KeycloakService,
    private readonly tokenService: TokenService,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.keycloakService.getToken().then((token) => {
      if (token) {
        const base64Decoded = this.tokenService.base64UrlDecode(
          token.split('.')[1]
        );
        const payload = JSON.parse(this.tokenService.utf8Decode(base64Decoded));

        const roles = payload?.resource_access?.['ehr-ui']?.roles;

        if (roles?.includes('read-only')) {
          this.isReadOnly = true;
        } else {
          this.isReadOnly = false;
        }
      }
    });
  }

  isAnyVisitIncomplete(): boolean {
    return (
      (this.item.visits &&
        this.item.visits.some((v) => v && !v.isCompleted && v.isEditable)) ||
      false
    );
  }

  openVisit(eocID: number, visitID: number) {
    this.eocService.currentEocId.set(eocID?.toString());

    this.eocService.currentVisitId.set(visitID?.toString());

    this.router.navigate([
      '/ehr/inPatientView',
      this.patientService.patientIDsignal(),
      this.eocService.currentEocId(),
      this.eocService.currentVisitId(),
    ]);
  }

  discardVisit(visitId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'Are you sure that you want to discard this visit?'
      )}`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.eocService.deleteVisit(visitId.toString()).subscribe({
          next: () => {
            this.swalToastService.toastSuccess('Visit discarded successfully');
            this.eocService.deleteVisitFromTable(visitId.toString());
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to discard Visit');
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Changes are not saved')}`,
          '',
          'info'
        );
      }
    });
  }

  createNewVisit(eocID: number) {
    Swal.fire({
      title: `<span style="font-size: 20px;">${this.translate.instant(
        'Create New Visit'
      )}</span>`,
      html: `${this.translate.instant(
        'Are you sure that you want to create a new visit for'
      )}
      <span
      style="font-weight: bold;">
      ${
        this.patientService?.patientInfoSub?.value.firstName +
        ' ' +
        this.patientService?.patientInfoSub?.value.lastName
      }
      </span>?`,
      // text: `${this.translate.instant(
      //   'Are you sure that you want to create a new visit?'
      // )}`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Yes')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.isVisitloading = true;
        this.eocService.createNewVisit(eocID).subscribe({
          next: (res) => {
            res?.data?.episodeOfCareId?.toString() &&
              this.eocService.currentEocId.set(
                res?.data?.episodeOfCareId?.toString()
              );

            res?.data?.visitId?.toString() &&
              this.eocService.currentVisitId.set(
                res?.data?.visitId?.toString()
              );

            this.isVisitloading = false;

            this.item.isHospitalized
              ? this.router.navigate([
                  '/ehr/inPatientView',
                  this.patientService.patientIDsignal(),
                  this.eocService.currentEocId(),
                  this.eocService.currentVisitId(),
                ])
              : this.router.navigate([
                  '/ehr/episodeOfCare',
                  this.patientService.patientIDsignal(),
                  this.eocService.currentEocId(),
                  this.eocService.currentVisitId(),
                ]);

            this.swalToastService.toastSuccess(
              'New Visit created successfully'
            );
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to create Visit');
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('No new visit created')}`,
          '',
          'info'
        );
      }
    });
  }
}
