import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IQuestionnaireDetailed } from '../interfaces/Questionnaire/IQuestionnaireDetailed';
import { IAddQuestionnaireAnswers } from '../interfaces/Questionnaire/IAddQuestionnaireAnswers';
import { COLORS } from 'src/styles/colors';

@Injectable({
  providedIn: 'root',
})
export class DynamicQuestionnaireService {
  private outpatientJson = {
    title: 'Κοινωνικό Ιστορικό',
    logoPosition: 'right',
    pages: [
      {
        name: 'page1',
        elements: [
          {
            type: 'comment',
            name: 'question40',
            title: 'Διαθέτει ή έχει πρόσβαση σε η/υ ή tablet',
          },
          {
            type: 'comment',
            name: 'question41',
            title: 'Διαθέτει ή έχει πρόσβαση σε διαδίκτυο',
          },
        ],
        title: 'Δημογραφικά Στοιχεία',
      },
      {
        name: 'page2',
        elements: [
          {
            type: 'text',
            name: 'question1',
            title: 'Ονοματεπώνυμο Προσωπικού Ιατρού',
          },
          {
            type: 'text',
            name: 'question2',
            title: 'Τηλέφωνο Επικοινωνίας Προσωπικού Ιατρού',
          },
          {
            type: 'comment',
            name: 'question4',
            title:
              'Παρακολουθείτε από ιατρό διαφορετικής ειδικότητας (ειδικότητα, ονοματεπώνυμο\r\nκαι στοιχεία επικοινωνίας);',
          },
          {
            type: 'radiogroup',
            name: 'question7',
            title: 'Κινητική λειτουργικότητα:',
            choices: [
              {
                value: 'Item 1',
                text: 'Κλινήρης',
              },
              {
                value: 'Item 2',
                text: 'Κινείται με αναπηρικό αμαξίδιο',
              },
              {
                value: 'Item 3',
                text: 'Κινείται με υποβοήθηση (Π ή μπαστούνι)',
              },
              {
                value: 'Item 4',
                text: 'Κινείται κανονικά',
              },
            ],
            showOtherItem: true,
            otherText: 'Άλλο:',
          },
        ],
        title: 'Πληροφορίες για το Νοσηλευτικό Προσωπικό',
      },
      {
        name: 'page3',
        elements: [
          {
            type: 'comment',
            name: 'question9',
            title: 'Κοινωνικο – οικονομικές συνθήκες της οικογένειας',
          },
          {
            type: 'comment',
            name: 'question10',
            title:
              'Σχέσεις μεταξύ των μελών και είδος επαφής που διατηρεί με την οικογένεια',
          },
        ],
        title: 'Οικογενειακό Ιστορικό',
      },
      {
        name: 'page4',
        elements: [
          {
            type: 'comment',
            name: 'question11',
            title: 'Λόγος παραπομπής;',
          },
          {
            type: 'comment',
            name: 'question12',
            title: 'Ποιος έχει την ευθύνη της φροντίδας του εξυπηρετούμενου;',
          },
          {
            type: 'comment',
            name: 'question13',
            title: 'Ποιος έκανε την παραπομπή;',
          },
        ],
        title: 'Εικόνα του ασθενή στο παρόν/Παρουσιαζόμενο πρόβλημα',
      },
      {
        name: 'page6',
        elements: [
          {
            type: 'comment',
            name: 'question15',
            title: 'Χρόνιες Παθήσεις / Μολύνσεις',
          },
          {
            type: 'comment',
            name: 'question16',
            title: 'Διαταραχές Διατροφής',
          },
          {
            type: 'comment',
            name: 'question17',
            title: 'Σωματικά Τραύματα',
          },
          {
            type: 'comment',
            name: 'question18',
            title: 'Παράγοντες κινδύνου λόγω οικογενειακού ιατρικού ιστορικού',
          },
          {
            type: 'comment',
            name: 'question19',
            title: 'Φαρμακευτική Αγωγή',
          },
          {
            type: 'comment',
            name: 'question3',
            title: 'Άλλο',
          },
        ],
        title: 'Ιατρικό Ιστορικό',
      },
      {
        name: 'page7',
        elements: [
          {
            type: 'comment',
            name: 'question20',
            title:
              'Ψυχική κατάσταση: εμφάνιση, στάση, συμπεριφορά και επεξεργασία σκέψης',
          },
          {
            type: 'comment',
            name: 'question21',
            title: 'Κατάθλιψη',
          },
          {
            type: 'boolean',
            name: 'question22',
            title: 'Νιώθει λυπημένος/στεναχωρημένος;',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
          {
            type: 'boolean',
            name: 'question23',
            title: 'Νιώθει αγχωμένος;',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
          {
            type: 'boolean',
            name: 'question24',
            title: 'Διαταραχές ύπνου/εφιάλτες;',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
          {
            type: 'comment',
            name: 'question25',
            title:
              'Έχει προβλήματα στον ύπνο του; (όνειρα/εφιάλτες, δυσκολεύετε να κοιμηθεί,\r\nξυπνά μέσα στη νύχτα;)',
          },
          {
            type: 'boolean',
            name: 'question26',
            title: 'Μετατραυματικό Στρες',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
          {
            type: 'comment',
            name: 'question27',
            title:
              'Αναβιώνει (π.χ εφιάλτες, flashbacks) ένα ή περισσότερα τραυματικά γεγονότα;',
          },
          {
            type: 'comment',
            name: 'question28',
            title:
              'Νιώθει ότι δεν μπορεί να θυμηθεί σημαντικά κομμάτια του/των τραυματικού/ων\r\nγεγονότος/των;',
          },
          {
            type: 'comment',
            name: 'question29',
            title: 'Ξεχνά εύκολα;',
          },
          {
            type: 'boolean',
            name: 'question30',
            title: 'Αυτοκτονικότητα',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
          {
            type: 'boolean',
            name: 'question31',
            title: 'Σκέφθηκε ποτέ να κάνει κακό στον εαυτό του;',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
          {
            type: 'boolean',
            name: 'question32',
            title: 'Κάνει συχνές σκέψεις για το θάνατο;',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
          {
            type: 'boolean',
            name: 'question33',
            title: 'Νιώθει ότι δεν έχει νόημα να ζει;',
            labelTrue: 'Ναί',
            labelFalse: 'Όχι',
          },
        ],
        title: 'Ψυχολογικό Ιστορικό / Αξιολόγηση',
      },
      {
        name: 'page8',
        elements: [
          {
            type: 'comment',
            name: 'question35',
            title:
              'Ποιες είναι οι θρησκευτικές και πολιτιστικές συνήθειες του;',
          },
        ],
        title: 'Θρησκευτικές και πολιτιστικές συνήθειες',
      },
      {
        name: 'page9',
        elements: [
          {
            type: 'comment',
            name: 'question36',
            title: 'Ενδιαφέροντα, δεξιότητες, ταλέντα, χόμπυ',
          },
        ],
        title: 'Ενδιαφέρoντα και Δεξιότητες',
      },
      {
        name: 'page',
        elements: [
          {
            type: 'comment',
            name: 'question37',
            title:
              'Ιστορικό διαμονής σε άλλη Ευρωπαϊκή χώρα (υπό ποιο καθεστώς)',
          },
          {
            type: 'comment',
            name: 'question38',
            title: 'Ιστορικό δικαστικών υποθέσεων',
          },
        ],
        title: 'Νομικό Ιστορικό',
      },
      {
        name: 'page10',
        elements: [
          {
            type: 'text',
            name: 'question39',
            title: 'Παρακαλώ περιγράψτε',
          },
        ],
        title: 'Άλλες σημαντικές πληροφορίες',
      },
    ],
    showPageNumbers: true,
  };

  private hasUnsavedChanges = new BehaviorSubject<boolean>(false);

  setUnsavedChanges(state: boolean): void {
    this.hasUnsavedChanges.next(state);
  }

  getUnsavedChanges() {
    return this.hasUnsavedChanges.asObservable();
  }

  constructor(
    private http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  getQuestionnaireData(
    questionnaireTemplateId: number
  ): Observable<IQuestionnaireDetailed> {
    const url = this.eocService.currentVisitId()
      ? `${
          environment.BACKEND_URL
        }Questionnaire/${this.patientService.patientIDsignal()}/${questionnaireTemplateId}?visitId=${this.eocService.currentVisitId()}`
      : `${
          environment.BACKEND_URL
        }Questionnaire/${this.patientService.patientIDsignal()}/${questionnaireTemplateId}`;

    return this.http.get<any>(url).pipe(
      map((response) => {
        if (response.succeed && response.data && response.data.length > 0) {
          return response.data[0];
        }
        return null;
      })
    );
  }

  addQuestionnaireAnswers(qaObj: IAddQuestionnaireAnswers) {
    // if its inpatient then get the visitId and patientId
    if (qaObj.questionnaireTemplateId == 1) {
      qaObj.visitId = Number(this.eocService.currentVisitId());
      qaObj.patientId = Number(this.patientService.patientIDsignal());
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(`${environment.BACKEND_URL}Questionnaire`, qaObj, {
      headers: headers,
    });
  }

  updateQuestionnaireAnswers(
    answerId: number,
    qaObj: IAddQuestionnaireAnswers
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      `${environment.BACKEND_URL}Questionnaire/${answerId}`,
      qaObj,
      {
        headers: headers,
      }
    );
  }
}
