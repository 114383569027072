<div class="profile-container">
  <app-patients-header [isComingFromInPatient]="isComingFromInPatient" />
  <div class="patient-profile-container">
    <nz-layout class="inner-layout">
      <nz-sider nzWidth="15%" [nzCollapsed]="isCollapsed">
        <ul nz-menu nzMode="inline" class="sider-menu">
          <ng-container *ngFor="let m of this.menuData?.childModules">
            <li
              nz-menu-item
              *ngIf="m.childModules.length < 1"
              [ngClass]="{
                'single-menu': componentPaths.pilotStudy != m.path,
                'single-menu-collapse': isCollapsed,
                '': !isCollapsed,
                'pilot-study': componentPaths.pilotStudy == m.path
              }"
              [nzSelected]="m.path == selectedOption"
              (click)="clickItem(m)"
            >
              <span
                *ngIf="componentPaths.pilotStudy != m.path"
                nz-icon
                [nzType]="'icons:' + m.icon"
                [ngClass]="{
                  'single-menu-icon': isCollapsed,
                  'single-menu-icon-nc': !isCollapsed
                }"
              ></span>
              <span
                [ngClass]="{ 'label-single': isCollapsed, '': !isCollapsed }"
                >{{ m.name | translate }}</span
              >
            </li>

            <li
              nz-submenu
              [nzOpen]="false"
              [nzTitle]="m.name | translate"
              nzIcon="icons:{{ m.icon }}"
              *ngIf="m.childModules.length >= 1"
            >
              <ul>
                <li
                  *ngFor="let option of m.childModules"
                  nz-menu-item
                  [nzSelected]="option.path == selectedOption"
                  (click)="
                    updateSelectedOption(option.path);
                    scrollToSection(option.path)
                  "
                >
                  {{ option.name | translate }}
                  <ul
                    *ngIf="
                      option.childModules && option.childModules.length > 0
                    "
                  >
                    <li
                      *ngFor="let subOption of option.childModules"
                      nz-menu-item
                      [nzSelected]="subOption.path == selectedOption"
                      (click)="
                        updateSelectedOption(subOption.path);
                        scrollToSection(subOption.path)
                      "
                    >
                      {{ subOption.name | translate }}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>
      </nz-sider>
      <nz-content
        [ngClass]="isCollapsed ? 'inner-content-collapse' : 'inner-content'"
      >
        <button mat-fab class="collapse-menu" (click)="toggleCollapsed()">
          <mat-icon *ngIf="!isCollapsed" class="collapse-menu-icon"
            >keyboard_arrow_left</mat-icon
          >
          <mat-icon *ngIf="isCollapsed" class="collapse-menu-icon"
            >keyboard_arrow_right</mat-icon
          >
        </button>

        <app-admission-profile
          [canNavigateBackInput]="true"
          [patientIdInput]="this.patientID"
          [showPatientInformation]="showPatientInformation"
        ></app-admission-profile>

        <app-alerts-section
          *ngIf="activeSubmenu() == componentPaths.alerts"
          [submenu]="getSubmenu(componentPaths.alerts)"
          [(selectedOption)]="selectedOption"
        />

        <app-somatometrics-section
          *ngIf="activeSubmenu() == componentPaths.somatometrics"
          [submenu]="getSubmenu(componentPaths.somatometrics)"
          [(selectedOption)]="selectedOption"
        />

        <app-daily-monitoring-section
          *ngIf="activeSubmenu() == componentPaths.dailyMonitoring"
          [submenu]="getSubmenu(componentPaths.dailyMonitoring)"
          [(selectedOption)]="selectedOption"
        />

        <app-medical-problems-section
          *ngIf="activeSubmenu() == componentPaths.medicalProblems"
          [submenu]="getSubmenu(componentPaths.medicalProblems)"
          [(selectedOption)]="selectedOption"
        />

        <app-medical-history-section
          *ngIf="activeSubmenu() == componentPaths.medicalHistorySection"
          [submenu]="getSubmenu(componentPaths.medicalHistorySection)"
          [(selectedOption)]="selectedOption"
        />

        <app-hysteroscopy-section
          *ngIf="selectedOption == componentPaths.hysteroscopy"
          [submenu]="getGynecologySubMenu(componentPaths.hysteroscopy)"
        />

        <app-pregnancy-section
          *ngIf="selectedOption == componentPaths.pregnancy"
          [submenu]="getGynecologySubMenu(componentPaths.pregnancy)"
        />

        <app-medication-summary-section
          *ngIf="activeSubmenu() == componentPaths.medicationSummary"
          [submenu]="getSubmenu(componentPaths.medicationSummary)"
          [(selectedOption)]="selectedOption"
        />

        <app-social-history-section
          *ngIf="activeSubmenu() == componentPaths.socialHistory"
          [submenu]="getSubmenu(componentPaths.socialHistory)"
          [(selectedOption)]="selectedOption"
        />

        <app-laboratory-section
          *ngIf="activeSubmenu() == componentPaths.laboratory"
          [submenu]="getSubmenu(componentPaths.laboratory)"
          [(selectedOption)]="selectedOption"
        />

        <app-imaging-section
          *ngIf="activeSubmenu() == componentPaths.imaging"
          [submenu]="getSubmenu(componentPaths.imaging)"
          [(selectedOption)]="selectedOption"
        />

        <app-health-care-plan-section
          *ngIf="activeSubmenu() == componentPaths.healthCarePlan"
        />

        <app-travel-history-section
          *ngIf="activeSubmenu() == componentPaths.travelHistory"
        />

        <app-eoc-section
          *ngIf="activeSubmenu() == componentPaths.episodeOfCare"
        />

        <app-tracheostomy-section
          *ngIf="activeSubmenu() == componentPaths.tracheostomy"
          [submenu]="getSubmenu(componentPaths.tracheostomy)"
          [(selectedOption)]="selectedOption"
        />

        <app-etiology-section
          *ngIf="activeSubmenu() == componentPaths.etiology"
        />

        <app-capnography-section
          *ngIf="activeSubmenu() == componentPaths.capnography"
        />

        <app-complication-section
          *ngIf="activeSubmenu() == componentPaths.complication"
        />

        <app-comorbidity-section
          *ngIf="activeSubmenu() == componentPaths.comorbidity"
        />

        <app-precious-ai
          *ngIf="activeSubmenu() == componentPaths.preciousAi"
          [submenu]="getSubmenu(componentPaths.preciousAi)"
        />

        <app-patient-characteristics
          *ngIf="activeSubmenu() == componentPaths.patientCharacteristics"
        />

        <app-initial-assessment-amen-section
          *ngIf="activeSubmenu() == componentPaths.initialAssessments"
          [submenu]="getSubmenu(componentPaths.initialAssessments)"
          [(selectedOption)]="selectedOption"
        />
      </nz-content>
    </nz-layout>
  </div>
</div>
