import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { ICoding } from 'src/app/interfaces/ICoding';
import { AdmissionService } from 'src/app/services/admission.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import { Router, NavigationExtras } from '@angular/router';
import { IAddInPatientEOC } from 'src/app/interfaces/AdmissionPatient/IAddInPatientEOC';
import { TranslateService } from '@ngx-translate/core';
import {
  IBed,
  IBuilding,
  IFloor,
  IRoom,
  IWard,
} from 'src/app/interfaces/Dropdown/IAccommodationDropdown';
import { InPatientExternalRefresh } from 'src/app/services/inPatientExternalRefresh.service';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-admission-admit-modal',
  templateUrl: './admission-admit-modal.component.html',
  styleUrls: ['./admission-admit-modal.component.css'],
})
export class AdmissionAdmitModalComponent implements OnInit {
  @Output() admitSuccess = new EventEmitter<void>();

  admitForm: FormGroup;
  formSubmitted = false;
  isLoading = false;

  patientId: string;
  admissionReasonsList: ICoding[];

  ddBuildings: IBuilding[] = [];
  ddWards: IWard[] = [];
  ddFloors: IFloor[] = [];
  ddRooms: IRoom[] = [];
  ddBeds: IBed[] = [];

  selectedBuilding: IBuilding | null = null;
  selectedFloor: IFloor | null = null;
  selectedWard: IWard | null = null;
  selectedRoom: IRoom | null = null;
  selectedBed: IBed | null = null;

  floorDisabled = true;
  wardDisabled = true;
  roomDisabled = true;
  bedDisabled = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: NzModalRef,
    private readonly refreshService: InPatientExternalRefresh,
    private readonly episodeOfCareService: EpisodeOfCareService,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly swalToastService: SwalToastService,
    private readonly translate: TranslateService,
    @Inject(NZ_MODAL_DATA)
    public data: {
      patientId: string;
      bedsList: IBuilding[];
      admissionReasonsList: ICoding[];
    }
  ) {
    this.patientId = data.patientId;

    this.ddBuildings = data.bedsList;
    this.admissionReasonsList = data.admissionReasonsList;

    this.admitForm = this.fb.group({
      building: [{ value: '', disabled: false }, Validators.required],
      floor: [{ value: '', disabled: true }, Validators.required],
      ward: [{ value: '', disabled: true }, Validators.required],
      room: [{ value: '', disabled: true }, Validators.required],
      bed: [{ value: '', disabled: true }, Validators.required],
      admissionReason: [{ value: '', disabled: false }, Validators.required],
      notes: [{ value: '', disabled: false }],
    });

    this.admitForm.get('building')?.valueChanges.subscribe((value) => {
      this.onBuildingChange(value);
    });

    this.admitForm.get('floor')?.valueChanges.subscribe((value) => {
      this.onFloorChange(value);
    });

    this.admitForm.get('ward')?.valueChanges.subscribe((value) => {
      this.onWardChange(value);
    });

    this.admitForm.get('room')?.valueChanges.subscribe((value) => {
      this.onRoomChange(value);
    });

    // this.admitForm.get('bed')?.valueChanges.subscribe(value => {
    //   this.onBedChange(value);
    // });
  }

  ngOnInit(): void {}

  submitForm(): void {
    this.formSubmitted = true;
    // Mark all controls as touched to show validation errors
    Object.keys(this.admitForm.controls).forEach((field) => {
      const control = this.admitForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
    if (this.admitForm.valid) {
      this.isLoading = true;
      const admitData = this.admitForm.value;

      this.episodeOfCareService
        .createInpatient(
          this.patientId,
          admitData.bed.id,
          admitData.admissionReason,
          admitData.notes
        )
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            this.admitSuccess.emit();
            this.modal.destroy();
            res?.data?.episodeOfCareId?.toString() &&
              this.eocService.currentEocId.set(
                res?.data?.episodeOfCareId?.toString()
              );

            res?.data?.visitId?.toString() &&
              this.eocService.currentVisitId.set(
                res?.data?.visitId?.toString()
              );

            this.patientService.patientIDsignal.set(this.patientId);

            setTimeout(() => {
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  patientID: this.patientId,
                },
              };
              this.router.navigate(['/ehr/admission']);
            }, 500);
            this.refreshService.triggerRefresh();
            this.swalToastService.toastSuccess(
              'Patient assigned to bed successfully'
            );
          },
          error: () => {
            this.swalToastService.toastError('Unable to admit patient!');
            this.isLoading = false;
          },
        });
    }
  }

  cancel(): void {
    this.modal.destroy();
  }

  isBedDropdownEnabled(): boolean {
    return (
      this.selectedBuilding !== null &&
      this.selectedFloor !== null &&
      this.selectedWard !== null &&
      this.selectedRoom !== null
    );
  }

  onBuildingChange(building: IBuilding | null) {
    this.selectedBuilding = building;
    this.selectedFloor = null;
    this.selectedWard = null;
    this.selectedRoom = null;
    this.selectedBed = null;

    this.ddFloors = building?.floors || [];
    this.ddWards = [];
    this.ddRooms = [];
    this.ddBeds = [];

    this.admitForm.patchValue({
      floor: null,
      ward: null,
      room: null,
      bed: null,
    });

    this.floorDisabled = !building;
    this.wardDisabled = true;
    this.roomDisabled = true;
    this.bedDisabled = true;

    // Automatically select floor if there is only one option
    if (this.ddFloors.length === 1) {
      this.selectedFloor = this.ddFloors[0];
      this.onFloorChange(this.selectedFloor);
    }

    if (building) {
      this.admitForm.get('floor')?.enable();
    } else {
      this.admitForm.get('floor')?.disable();
    }
  }

  onFloorChange(floor: IFloor | null) {
    this.selectedFloor = floor;
    this.selectedWard = null;
    this.selectedRoom = null;
    this.selectedBed = null;

    this.ddWards = floor?.wards || [];
    this.ddRooms = [];
    this.ddBeds = [];

    this.admitForm.patchValue({
      ward: null,
      room: null,
      bed: null,
    });

    this.wardDisabled = !floor;
    this.roomDisabled = true;
    this.bedDisabled = true;

    // Automatically select ward if there is only one option
    if (this.ddWards.length === 1) {
      this.admitForm.get('ward')?.setValue(this.ddWards[0]);
      this.onWardChange(this.admitForm.get('ward')?.getRawValue());
    }

    if (floor) {
      this.admitForm.get('ward')?.enable();
    } else {
      this.admitForm.get('ward')?.disable();
    }
  }

  onWardChange(ward: IWard | null) {
    this.selectedWard = ward;
    this.selectedRoom = null;
    this.selectedBed = null;

    this.ddRooms = ward?.rooms || [];
    this.ddBeds = [];

    this.admitForm.patchValue({
      room: null,
      bed: null,
    });

    this.roomDisabled = !ward;
    this.bedDisabled = true;

    // Automatically select room if there is only one option
    if (this.ddRooms.length === 1) {
      this.admitForm.get('room')?.setValue(this.ddRooms[0]);
      this.onRoomChange(this.admitForm.get('room')?.getRawValue());
    }

    if (ward) {
      this.admitForm.get('room')?.enable();
    } else {
      this.admitForm.get('room')?.disable();
    }
  }

  onRoomChange(room: IRoom | null) {
    this.selectedRoom = room;
    this.selectedBed = null;

    this.ddBeds = room?.beds || [];

    this.admitForm.patchValue({
      bed: null,
    });

    this.bedDisabled = !room;

    if (room) {
      this.admitForm.get('bed')?.enable();
    } else {
      this.admitForm.get('bed')?.disable();
    }
  }

  onBedChange(bed: IBed | null) {
    this.selectedBed = bed;

    this.admitForm.get('bed')?.setValue(bed);

    this.bedDisabled = !this.isBedDropdownEnabled();
  }

  // onBedChange(bed: IBed | null) {
  //   this.selectedBed = bed;
  //
  //   const building = this.ddBuildings.find((b) =>
  //     b.floors.some((f) => f.id === floor?.id)
  //   );
  //   if (building) {
  //     this.selectedBuilding = building;
  //     this.admitForm.get('building')?.setValue(building);
  //   }
  //
  //   const floor = this.ddFloors.find((f) =>
  //     f.wards.some((w) => w.id === ward?.id)
  //   );
  //   if (floor) {
  //     this.selectedFloor = floor;
  //     this.admitForm.get('floor')?.setValue(floor);
  //   }
  //
  //   const ward = this.ddWards.find((w) =>
  //     w.rooms.some((r) => r.id === room?.id)
  //   );
  //   if (ward) {
  //     this.selectedWard = ward;
  //     this.admitForm.get('ward')?.setValue(ward);
  //   }
  //
  //   const room = this.ddRooms.find((r) => r.beds.some((b) => b.id === bed?.id));
  //   if (room) {
  //     this.selectedRoom = room;
  //     this.admitForm.get('room')?.setValue(room);
  //   }
  //
  //   this.bedDisabled = !this.isBedDropdownEnabled();
  // }
}
