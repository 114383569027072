<!-- Initial Button to Show the Form -->
<button
  *ngIf="externalDoctors.length === 0"
  class="add-patient-button"
  (click)="addNewDoctor()"
  type="button"
>
  {{ "Add External Doctor" | translate }}
</button>

<!-- Form for external doctors -->
<form
  *ngIf="externalDoctors.length > 0"
  [formGroup]="externalDoctorsForm"
  [ngClass]="{ submitted: formSubmitted }"
>
  <div formArrayName="externalDoctors">
    <div
      *ngFor="let doctor of externalDoctors.controls; let i = index"
      [formGroupName]="i"
    >
      <h3>{{ "External Doctor" | translate }} {{ i + 1 }}</h3>

      <!-- External doctor lookup -->
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="add-patient-row"
        style="gap: 16px; margin-left: 14px"
      >
        <label>{{ "Search from Cyma list" | translate }}</label>
        <!-- <nz-switch
          [formControlName]="'toggleControl'"
          (ngModelChange)="onExternalDoctorToggle($event, i)"
        ></nz-switch> -->
        <nz-switch
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
          (ngModelChange)="onExternalDoctorToggle($event, i)"
          [formControlName]="'toggleControl'"
        ></nz-switch>
        <ng-template #checkedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
        <div fxFlex="40">
          <nz-select
            *ngIf="!externalDoctors.at(i).get('toggleControl')?.value"
            class="add-patient-inputZorro"
            nzShowSearch
            nzAllowClear
            formControlName="doctorId"
            [nzOptions]="externalDoctorsDropdown"
            (ngModelChange)="onDoctorSelectionChange($event, i, false)"
            nzPlaceHolder="{{ 'Select external Doctor' | translate }}"
          ></nz-select>

          <nz-select
            *ngIf="externalDoctors.at(i).get('toggleControl')?.value"
            class="add-patient-inputZorro"
            nzShowSearch
            nzAllowClear
            formControlName="doctorId"
            [nzOptions]="externalDoctorsCymaDropdown"
            (ngModelChange)="onDoctorSelectionChange($event, i, true)"
            nzPlaceHolder="{{ 'Select external Doctor' | translate }}"
          ></nz-select>
        </div>
      </div>

      <!-- Doctor Information -->
      <div
        class="layout"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        style="margin-top: 20px"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="label-row large-screen w-100"
        >
          <div fxFlex="25" class="add-patient-col w-100">
            <div>{{ "First Name" | translate }}</div>
            <input
              class="add-patient-input"
              formControlName="firstName"
              [maxLength]="50"
            />
          </div>
          <div fxFlex="25" class="add-patient-col w-100">
            <div>{{ "Last Name" | translate }}</div>
            <input
              class="add-patient-input"
              formControlName="lastName"
              [maxLength]="50"
            />
          </div>
          <div fxFlex="25" class="add-patient-col w-100">
            <div>{{ "Phone Number" | translate }}</div>
            <input
              class="add-patient-input"
              formControlName="phoneNumber"
              [maxLength]="50"
            />
          </div>
          <div fxFlex="25" class="add-patient-col w-100">
            <div>{{ "Registration Number" | translate }}</div>
            <input
              class="add-patient-input"
              formControlName="registrationNumber"
              [maxLength]="50"
            />
          </div>
        </div>

        <!-- Address Section -->
        <div formGroupName="addressData" class="w-100">
          <div class="title">{{ "Address" | translate }}</div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            class="label-row large-screen small-screen w-100"
          >
            <div
              fxLayout="column"
              fxFlex="60"
              class="add-patient-col w-100"
              fxLayoutAlign="center start"
            >
              <div>
                {{ "Street" | translate }}
                <span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input
                class="add-patient-input"
                formControlName="street"
                [maxLength]="50"
              />
            </div>

            <div
              fxLayout="column"
              fxFlex="20"
              class="add-patient-col w-100"
              fxLayoutAlign="center start"
            >
              <div>
                {{ "Street Number" | translate }}
                <span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input
                class="add-patient-input"
                formControlName="streetNumber"
                [maxLength]="50"
              />
            </div>

            <div
              fxLayout="column"
              fxFlex="20"
              class="add-patient-col w-100"
              fxLayoutAlign="center start"
            >
              <div>
                {{ "Apartment Number" | translate }}
                <span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input
                class="add-patient-input"
                formControlName="apartmentNumber"
                [maxLength]="50"
              />
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="label-row large-screen small-screen w-100"
          >
            <div
              fxLayout="column"
              fxFlex="25"
              class="add-patient-col w-100"
              fxLayoutAlign="center start"
            >
              <div>
                {{ "Postcode" | translate }}
                <span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input class="add-patient-input" formControlName="postCode" />
            </div>

            <div
              fxLayout="column"
              fxFlex="25"
              class="add-patient-col w-100"
              fxLayoutAlign="center start"
            >
              <div>
                {{ "Country" | translate }}
                <span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <nz-select
                class="add-patient-inputZorro"
                nzShowSearch
                nzAllowClear
                formControlName="countryId"
                (ngModelChange)="onCountryChange($event)"
              >
                <ng-container *ngFor="let place of countriesList">
                  <nz-option
                    [nzLabel]="place.name"
                    [nzValue]="place.id"
                  ></nz-option>
                </ng-container>
              </nz-select>
            </div>

            <div
              fxLayout="column"
              fxFlex="25"
              class="add-patient-col w-100"
              fxLayoutAlign="center start"
            >
              <div>
                {{ "Town" | translate }}
                <span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <ng-container *ngIf="!isCountryCyprus; else cyprusDropdown">
                <input
                  class="add-patient-input"
                  formControlName="town"
                  [maxLength]="50"
                />
              </ng-container>
              <!-- Dropdown if Cyprus -->
              <ng-template #cyprusDropdown>
                <nz-select
                  class="add-patient-inputZorro"
                  nzShowSearch
                  nzAllowClear
                  [nzPlaceHolder]="'Select a Town' | translate"
                  formControlName="town"
                >
                  <ng-container *ngFor="let city of cyprusCities">
                    <nz-option
                      [nzLabel]="city | translate"
                      [nzValue]="city"
                    ></nz-option>
                  </ng-container>
                </nz-select>
              </ng-template>
            </div>

            <div
              fxLayout="column"
              fxFlex="25"
              class="add-patient-col w-100"
              fxLayoutAlign="center start"
            >
              <div>
                {{ "District" | translate }}
                <span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input
                class="add-patient-input"
                formControlName="district"
                [maxLength]="50"
              />
            </div>
          </div>
        </div>

        <!-- Specialties Section -->
        <div class="title">{{ "Specialties" | translate }}</div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="label-row large-screen w-100"
        >
          <div fxFlex="100" class="add-patient-col w-100">
            <div>{{ "Specialties" | translate }}</div>
            <nz-select
              [nzMode]="'multiple'"
              formControlName="specialties"
              class="add-patient-inputZorro"
              nzShowSearch
            >
              <nz-option
                *ngFor="let specialty of allSpecialties"
                [nzValue]="specialty.id"
                [nzLabel]="specialty.name"
              ></nz-option>
            </nz-select>
          </div>
        </div>
      </div>

      <!-- Remove Doctor Button -->
      <button
        *ngIf="externalDoctors.length > 0"
        class="back-button"
        (click)="removeDoctor(i)"
        type="button"
      >
        {{ "Remove" | translate }}
      </button>
    </div>
  </div>

  <!-- Add Another Doctor Button -->
  <button class="add-patient-button" (click)="addNewDoctor()" type="button">
    {{ "Add another external doctor" | translate }}
  </button>
</form>
