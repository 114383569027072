<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Tube Characteristics"
        [columns]="[
          'Tube Length',
          'Outer Diameter',
          'Inner Diameter',
          'Cuff Diameter',
          'Consistency of Tube',
          'Tube Type'
        ]"
        [rowFields]="[
          'tubeLength',
          'outerDiameter',
          'innerDiameter',
          'cuffDiameter',
          'consistencyOfTube',
          'tubeType'
        ]"
        [data]="tubeCharacteristics"
        [addComponent]="addComp"
        [deleteFun]="deleteFun"
        [isLoading]="isLoading"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-tubc')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
