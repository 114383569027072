import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { COLORS } from 'src/styles/colors';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userRoleSub = new BehaviorSubject<string | null>(null);

  public userNameSub = new BehaviorSubject<string | null>(null);

  public tenantIdSub = new BehaviorSubject<number | null>(null);

  public emailVerifiedSub = new BehaviorSubject<boolean | null>(null);

  public nameSub = new BehaviorSubject<string | null>(null);

  public preferredUsernameSub = new BehaviorSubject<string | null>(null);

  public localeSub = new BehaviorSubject<string | null>(null);

  public givenNameSub = new BehaviorSubject<string | null>(null);

  public familyNameSub = new BehaviorSubject<string | null>(null);

  public emailSub = new BehaviorSubject<string | null>(null);

  public rolesSub = new BehaviorSubject<string[] | null>(null);

  setUserName(userName: string) {
    this.userNameSub.next(userName);
  }

  setUserRole(role: string) {
    this.userRoleSub.next(role);
  }

  setTenantId(tenantId: number) {
    this.tenantIdSub.next(tenantId);
  }

  setEmailVerified(emailVerified: boolean) {
    this.emailVerifiedSub.next(emailVerified);
  }

  setName(name: string) {
    this.nameSub.next(name);
  }

  setPreferredUsername(preferredUsername: string) {
    this.preferredUsernameSub.next(preferredUsername);
  }

  setLocale(locale: string) {
    this.localeSub.next(locale);
  }

  setGivenName(givenName: string) {
    this.givenNameSub.next(givenName);
  }

  setFamilyName(familyName: string) {
    this.familyNameSub.next(familyName);
  }

  setEmail(email: string) {
    this.emailSub.next(email);
  }

  setRoles(roles: string[]) {
    this.rolesSub.next(roles);
  }
}
