/* src/app/components/ehr/admission/admission-profile/admission-profile.component.css */
.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header-title {
  padding-left: 3em;
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: var(--color-3);
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}
.custom-button:hover {
  background-color: var(--color-0);
}
.information-icon {
  font-size: 24px;
  color: white;
}
.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 3em;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
::ng-deep .ant-tabs-tab-btn {
  color: black !important;
}
::ng-deep .ant-tabs-card > .ant-tabs-nav ::ng-deep .ant-tabs-tab {
  background: var(--color-3);
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: 500;
}
::ng-deep .ant-tabs-card > .ant-tabs-nav ::ng-deep .ant-tabs-tab-active {
  background: white;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: 500;
}
::ng-deep .ant-tabs-tab.ant-tabs-tab-active ::ng-deep .ant-tabs-tab-btn {
  color: black !important;
}
::ng-deep .ant-tabs-tab {
  min-width: 200px;
  margin-right: 3px !important;
}
::ng-deep .ant-tabs {
  line-height: 2;
  min-height: 70vh;
}
::ng-deep .ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}
::ng-deep .ant-tabs-content-holder {
  height: 100%;
  padding: 2em 1em;
  border: 1px solid black;
}
.full-height {
  min-height: calc(100vh - 66px) !important;
}
::ng-deep .anticon {
  scale: 1.5 !important;
}
.admission-pending-button {
  background-color: var(--pending) !important;
  border: 1px solid var(--pending) !important;
  color: white !important;
}
.admission-waitinglist-button {
  background-color: var(--waiting-list) !important;
  border: 1px solid var(--waiting-list) !important;
  color: white !important;
}
.admission-approve-button {
  background-color: var(--approved) !important;
  border: 1px solid var(--approved) !important;
  color: white !important;
}
.admission-admit-button {
  background-color: var(--admitted) !important;
  border: 1px solid var(--admitted) !important;
  color: white !important;
}
.admission-reject-button {
  background-color: var(--rejected) !important;
  border: 1px solid var(--rejected) !important;
  color: white !important;
}
.admission-edit-button {
  border: 1px solid var(--color-0) !important;
  background-color: white !important;
  color: var(--color-0) !important;
  margin-left: 1em;
}
.admission-timeline-button {
  border: 1px solid var(--color-0) !important;
  background-color: white !important;
  color: var(--color-0) !important;
  margin-left: 1em;
}
.admission-edit-button:hover {
  color: white !important;
  background-color: var(--color-0) !important;
}
.admission-cancel-button {
  border: 1px solid var(--red) !important;
  background-color: white !important;
  color: var(--red) !important;
}
.admission-cancel-button:hover {
  color: white !important;
  background-color: var(--red) !important;
}
::ng-deep .ant-tabs-content-holder {
  background-color: white;
}
nz-tabset {
  margin-bottom: 3em;
}
::ng-deep .ant-btn {
  border-radius: 4px;
  cursor: pointer;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--color-1);
}
@media only screen and (max-width: 840px) {
  .btn-text {
    display: none !important;
  }
}
.custom-icon:hover svg {
  fill: white !important;
}
.spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
::ng-deep .ant-spin-dot-item {
  background-color: var(--color-1) !important;
}
.swal-textarea {
  margin-top: 1em;
}
.registration-status {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
/*# sourceMappingURL=admission-profile.component-7AP7RRUX.css.map */
