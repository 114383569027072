import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { COLORS } from 'src/styles/colors';
import { NeckService } from 'src/app/services/neck.service';
import { IAddNeck } from 'src/app/interfaces/Neck/IAddNeck';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-neck-add-edit',
  standalone: true,
  templateUrl: './neck-add-edit.component.html',
  styleUrl: './neck-add-edit.component.css',
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class NeckAddEditComponent {
  private destroy$ = new Subject<void>();

  isVisible = false;
  isLoading = false;

  isEdit = false;
  editNeckId?: number;

  neckSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  neckForm: FormGroup = new FormGroup({});

  constructor(
    private readonly neckService: NeckService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.isEdit = false;
    this.isLoading = false;

    this.neckForm = this.formBuilder.group({
      neckValue: [null, Validators.required],
      descriptionValue: [null],
    });

    this.neckService.neckEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editNeckId = data?.id;

          this.neckForm.patchValue({
            neckValue: data?.neckSize,
            descriptionValue: data?.description,
          });
        } else {
          this.isEdit = false;
          this.editNeckId = undefined;

          this?.neckForm.reset();
        }
      });

    this.neckService.neckModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  handleCancel() {
    this.neckForm.reset();

    this.neckService.neckModalSub.next(false);

    this.neckSubmitted = false;

    this.isLoading = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.neckSubmitted = true;

    if (this.neckForm.valid) {
      const neckObj: IAddNeck = {};
      neckObj.id = this.editNeckId;
      neckObj.neckSize = this.neckForm.value?.neckValue;
      neckObj.description = this.neckForm.value?.descriptionValue;

      if (this.isEdit) {
        this.neckService.editNeck(neckObj).subscribe({
          next: () => {
            this.swalToastService.toastSuccess(
              'Neck record edited successfully'
            );
            this.neckService.getData();

            this.handleCancel();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to edit Neck record');
            this.handleCancel();
          },
        });
      } else {
        this.neckService.addNeck(neckObj).subscribe({
          next: () => {
            this.swalToastService.toastSuccess(
              'Neck record added successfully'
            );
            this.neckService.getData();

            this.handleCancel();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to add Neck record');
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }
}
