import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import {
  IUserManagement,
  IUserManagementAll,
} from '../../../interfaces/IUserManagement';
import { UserManagementService } from '../../../services/userManagement.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import { IGenericServiceResponse } from 'src/app/interfaces/IServiceResponse';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent {
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  userCount?: number;
  maxUserLimit?: number;
  public firstNameValue?: string;
  public lastNameValue?: string;
  public roleValue?: string[];
  public emailValue?: string;
  public phoneNumberValue?: string;
  public totalRecords: number = 0;
  public roleOptions: { id: string; name: string; orderBy: number }[] = [];
  public selectedRoleNames: string[] = []; // to be used in the search

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    // this.getAllUsers();
    this.userManagement.getDropdownData().subscribe({
      next: (res) => {
        this.roleOptions = res?.data?.roles || [];
      },
      error: (err) => {
        console.error('Error fetching roles:', err);
      },
    });
    this.userManagement.getUsers().subscribe({
      next: (res: IGenericServiceResponse<IUserManagementAll>) => {
        const resultArray = Array.isArray(res?.data?.getUserDbDto)
          ? res.data.getUserDbDto
          : [];
        this.data = new MatTableDataSource(resultArray);
        this.totalRecords = res.totalRecords || resultArray.length || 0;
        this.data.paginator = this.paginator;

        this.totalRecords = res.totalRecords || 0;
        this.data.paginator = this.paginator;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
      },
    });
  }

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }

  public userManagementData: IUserManagement[] = [];
  public data: MatTableDataSource<IUserManagement> =
    new MatTableDataSource<IUserManagement>([]);

  public displayedColumns: string[] = [
    'firstName',
    'lastName',
    'role',
    'email',
    'phoneNumber',
    'umactions',
  ];

  public firstName: string = '';
  public lastName: string = '';
  public role: string = '';
  public email: string = '';
  public phoneNumber: string = '';

  public isLoading: boolean = false;

  clearSearchFields(currentField: string) {
    if (currentField !== 'firstName') {
      this.firstName = '';
    }
    if (currentField !== 'lastName') {
      this.lastName = '';
    }

    if (currentField !== 'role') {
      this.selectedRoleNames = [];
    }

    if (currentField !== 'email') {
      this.email = '';
    }

    if (currentField !== 'phoneNumber') {
      this.phoneNumber = '';
    }
  }

  searchUsersForm() {
    this.paginator.pageIndex = 0;
    this.firstNameValue = this.firstName?.trim();
    this.lastNameValue = this.lastName?.trim();
    // this.roleValue = this.role?.trim();
    this.roleValue = this.selectedRoleNames; // now an array
    this.emailValue = this.email?.trim();
    this.phoneNumberValue = this.phoneNumber?.trim();
    this.pageChange();
  }
  async pageChange() {
    // if (!this.hasSearched) return;

    const searchObj: any = {};

    if (this.firstNameValue) searchObj.firstName = this.firstNameValue;
    if (this.lastNameValue) searchObj.lastName = this.lastNameValue;
    if (this.roleValue?.length! > 0) {
      searchObj.roleNames = this.roleValue; // array of names
    }

    if (this.emailValue) searchObj.email = this.emailValue;
    if (this.phoneNumberValue) searchObj.phoneNumber = this.phoneNumberValue;

    this.isLoading = true;
    this.userManagement
      .searchUsers(
        searchObj,
        this.paginator.pageSize,
        this.paginator.pageIndex + 1
      )
      .subscribe({
        next: (res: IGenericServiceResponse<IUserManagementAll>) => {
          const resultArray = Array.isArray(res?.data?.getUserDbDto)
            ? res.data.getUserDbDto
            : [];
          this.data = new MatTableDataSource(resultArray);
          this.totalRecords = res.totalRecords || resultArray.length || 0;
          this.data.paginator = this.paginator;

          this.totalRecords = res.totalRecords || 0;
          this.data.paginator = this.paginator;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          console.error(err);
        },
      });
  }

  deleteUser(userId: string) {
    // Use SweetAlert for a confirmation dialog
    Swal.fire({
      title: `${this.translate.instant('Delete User?')}`,
      text: `${this.translate.instant(
        "You won't be able to revert this user back!"
      )}`,
      icon: 'warning',
      iconColor: '#d33',
      showCancelButton: true,
      confirmButtonColor: COLORS.green1,
      cancelButtonColor: '#d33',
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Delete User')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.userManagement.deleteUser(userId).subscribe({
          next: (response) => {
            Swal.fire(
              `${this.translate.instant('Deleted')}!`,
              `${this.translate.instant('Your user has been deleted')}.`,
              'success'
            );
            this.getAllUsers();
          },
          error: (err) => {
            Swal.fire({
              title: `${this.translate.instant('Error')}!`,
              text: `${this.translate.instant(err.error.message)}`,
              icon: 'error',
              iconColor: '#d33',
              confirmButtonColor: COLORS.green1,
              confirmButtonText: `Ok`,
            });
            console.error('Error deleting user:', err);
          },
        });
      }
    });
  }

  registerUser() {
    this.router.navigate(['/ehr/userRegistration'], {});
  }
  onEdit(row: IUserManagement) {
    this.router.navigate(['/ehr/userProfile'], {
      queryParams: {
        id: row?.id,
      },
    });
  }

  getAllUsers() {
    this.isLoading = true;
    this.userManagement
      .getUsers(this.paginator.pageSize, this.paginator.pageIndex + 1)
      .subscribe({
        next: (response) => {
          this.data.data = response?.data?.getUserDbDto;
          this.userManagementData = response?.data?.getUserDbDto;

          this.userCount = response?.data.usersAvailable;
          this.maxUserLimit = response?.data.maxUserLimit;

          this.data.paginator = this.paginator; // Reapply paginator

          this.isLoading = false;
        },
        error: (err) => {},
      });
  }

  getRoles(element: IUserManagement) {
    return element.roles?.map((r) => r.roleName).join('; ');
  }
}
