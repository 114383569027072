import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from 'src/app/services/swal.service';
import { IDynamicDropdown } from 'src/app/interfaces/DynamicTable/IDynamicDropdown';
import { DynamicService } from 'src/app/services/dynamic.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-laboratory-result-section',
  templateUrl: './laboratory-result-section.component.html',
  styleUrls: ['./laboratory-result-section.component.css'],
})
export class LaboratoryResultSectionComponent {
  endpoint = 'Laboratory';

  public data: IDynamicDropdown[] = [];

  constructor(
    private readonly dynamicService: DynamicService,
    private readonly swalToastService: SwalToastService,
    private readonly translate: TranslateService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.dynamicService.getDynamicDropdown(this.endpoint).subscribe({
      next: (data) => {
        this.data = data.data;
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve laboratory data');
      },
    });
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }
}
