<form [formGroup]="emergencyContactsInfoForm" [ngClass]="{ submitted: formSubmitted }">
  <div formArrayName="contacts">
    <ng-container *ngFor="
        let contact of contacts.controls;
        let i = index;
        trackBy: trackByFn
      ">
      <div *ngIf="i === currentPage" [formGroupName]="i">
        <h3 class="navigator">
          <button (click)="previousPage()" nz-button [disabled]="currentPage === 0" class="nav-btn">
            <span nz-icon nzType="caret-left" nzTheme="outline"></span>
          </button>
          {{ "Emergency Contact" | translate }}
          <span class="num-con">{{ currentPage + 1 }}</span>
          {{ "of" | translate }} <span class="num-con">{{ totalPages }}</span>
          <button (click)="nextPage()" nz-button [disabled]="currentPage >= totalPages - 1" class="nav-btn">
            <span nz-icon nzType="caret-right" nzTheme="outline"></span>
          </button>
          <span *ngIf="currentPage == 0" class="mediator">
            ({{ "Admission Mediator" | translate }})
          </span>

          <button (click)="addNewEmergencyContact()" nz-button [disabled]="!isEditMode" class="nav-btn-plus" nz-tooltip
            nzTooltipTitle="{{ 'Add new emergency contact' | translate }}" nzTooltipPlacement="top">
            <span class="plus-cricle" nz-icon nzType="plus-circle" nzTheme="outline"></span>
          </button>

          <button (click)="removeEmergencyContact(i)" nz-button [disabled]="!isEditMode" class="nav-btn-delete"
            nz-tooltip nzTooltipTitle="{{ 'Remove emergency contact' | translate }}" nzTooltipPlacement="top">
            <span class="delete" nz-icon nzType="delete" nzTheme="outline"></span>
          </button>
        </h3>
        <!-- Emergency contact form fields here -->

        <div class="layout" fxLayout="column" fxLayoutAlign="center start" style="height: 100%">
          <div fxLayout="row" fxLayoutAlign="start center" class="label-row large-screen w-100">
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "First name" | translate }}
              </div>
              <input class="add-patient-input" formControlName="firstName" [maxLength]="50" />
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "Last name" | translate }}
              </div>
              <input class="add-patient-input" formControlName="lastName" [maxLength]="50" />
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "Email" | translate
                }}<span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input class="add-patient-input" formControlName="email" />
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "Phone number" | translate }}
              </div>
              <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="[CountryISO.Cyprus, CountryISO.Greece]"
                [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]" [selectFirstCountry]="false" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="false"
                name="phone" formControlName="phoneMockControl"
                (ngModelChange)="onPhoneNumberChange($event, i)"></ngx-intl-tel-input>
              <input type="hidden" formControlName="phoneNumber" />
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" class="label-row large-screen w-100">
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "Relationship with patient" | translate
                }}<span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="center start" class="w-100">
                <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="closestRelativeId">
                  <ng-container *ngFor="let relative of closestRelativesList">
                    <nz-option [nzLabel]="relative.name" [nzValue]="relative.id"></nz-option>
                  </ng-container>
                </nz-select>
                <!-- 
            Conditionally show the free text input. 
            The id 7 is the regular id and id 15 is the translation id of option "Other" in closest relatives
            -->
                <input *ngIf="contact.get('closestRelativeId')?.value == 7" class="add-patient-input"
                  formControlName="closestRelativeOther" placeholder="{{ 'Please specify' | translate }}"
                  [maxLength]="50" />
              </div>
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "Occupation" | translate
                }}<span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input class="add-patient-input" formControlName="occupation" [maxLength]="100" />
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100"></div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100"></div>
          </div>

          <div class="w-100">
            <div class="title">{{ "Identifier" | translate }}</div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" class="label-row large-screen w-100">
            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "Document Type" | translate }}
              </div>
              <nz-select class="identifier-type-select" [nzPlaceHolder]="
                  'Emergency Contact\'s Identifier Type' | translate
                " nzShowSearch nzAllowClear formControlName="documentTypeId">
                <ng-container *ngFor="let document of documentTypeList">
                  <nz-option [nzLabel]="document.name" [nzValue]="document.id"></nz-option>
                </ng-container>
              </nz-select>
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{ "Document Number" | translate
                }}<span class="optional">{{ "(optional)" | translate }}</span>
              </div>
              <input class="add-patient-input" formControlName="documentNumber" />
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100">
              <div>
                {{
                contact.get("documentTypeId")?.value == 3
                ? ""
                : ("Issuing Country" | translate)
                }}<span class="optional">{{
                  contact.get("documentTypeId")?.value == 3
                  ? ""
                  : ("optional" | translate)
                  }}</span>
              </div>
              <nz-select *ngIf="contact.get('documentTypeId')?.value != 3" class="add-patient-inputZorro" nzShowSearch
                nzAllowClear formControlName="documentCountryIssuedId">
                <ng-container *ngFor="let country of countriesList">
                  <nz-option [nzLabel]="country.name" [nzValue]="country.id"></nz-option>
                </ng-container>
              </nz-select>
            </div>

            <div fxLayout="column" fxFlex="25" fxLayoutAlign="center start" class="add-patient-col w-100"></div>
          </div>

          <div formGroupName="addressData" class="w-100">
            <div class="title">{{ "Address" | translate }}</div>
            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start center"
              class="label-row large-screen small-screen w-100">
              <div fxLayout="column" fxFlex="60" class="add-patient-col w-100" fxLayoutAlign="center start">
                <div>
                  {{ "Street" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <input class="add-patient-input" formControlName="street" [maxLength]="50" />
              </div>

              <div fxLayout="column" fxFlex="20" class="add-patient-col w-100" fxLayoutAlign="center start">
                <div>
                  {{ "Street Number" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <input class="add-patient-input" formControlName="streetNumber" />
              </div>

              <div fxLayout="column" fxFlex="20" class="add-patient-col w-100" fxLayoutAlign="center start">
                <div>
                  {{ "Apartment Number" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <input class="add-patient-input" formControlName="apartmentNumber" />
              </div>
            </div>

            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="center center"
              class="label-row large-screen small-screen w-100">
              <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                <div>
                  {{ "Postcode" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <input class="add-patient-input" formControlName="postCode" />
              </div>

              <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                <div>
                  {{ "Country" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="countryId"
                  (ngModelChange)="onCountryChange($event)">
                  <ng-container *ngFor="let place of countriesList">
                    <nz-option [nzLabel]="place.name" [nzValue]="place.id"></nz-option>
                  </ng-container>
                </nz-select>
              </div>

              <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                <div>
                  {{ "Town" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <ng-container *ngIf="!isCountryCyprus; else cyprusDropdown">
                  <input class="add-patient-input" formControlName="town" [maxLength]="50" />
                </ng-container>
                <!-- Dropdown if Cyprus -->
                <ng-template #cyprusDropdown>
                  <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear
                    [nzPlaceHolder]="'Select a Town' | translate" formControlName="town">
                    <ng-container *ngFor="let city of cyprusCities">
                      <nz-option [nzLabel]="city | translate" [nzValue]="city"></nz-option>
                    </ng-container>
                  </nz-select>
                </ng-template>
              </div>

              <div fxLayout="column" fxFlex="25" class="add-patient-col w-100" fxLayoutAlign="center start">
                <div>
                  {{ "District" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
                <input class="add-patient-input" formControlName="district" [maxLength]="50" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>