import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { ICoding, ICodingSpecialties } from 'src/app/interfaces/ICoding';
import { IExternalDoctor } from 'src/app/interfaces/ExternalDoctors/IExternalDoctor';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { IExternalCymaDoctorDropdown } from 'src/app/interfaces/ExternalDoctors/IExternalCymaDoctorDropdown';

@Component({
  selector: 'app-registration-external-doctors',
  templateUrl: './registration-external-doctors.component.html',
  styleUrls: ['./registration-external-doctors.component.css'],
})
export class RegistrationExternalDoctorsComponent implements OnInit {
  @Input() externalDoctorsDropdown: NzSelectOptionInterface[] = [];
  @Input() externalDoctorsApi: IExternalDoctor[] = [];
  @Input() externalDoctorsCymaDropdown: NzSelectOptionInterface[] = [];
  @Input() externalDoctorsCymaApi: IExternalCymaDoctorDropdown[] = [];
  @Input() countriesList: ICoding[] = [];
  @Input() doctorSpecialties: ICodingSpecialties[] = [];
  @Input() formSubmitted?: boolean = false;

  externalDoctorsForm: FormGroup;
  isCountryCyprus: boolean = false;
  allSpecialties: ICodingSpecialties[] = [];

  cyprusCities: string[] = [
    'Nicosia',
    'Limassol',
    'Larnaca',
    'Paphos',
    'Famagusta',
    'Kyrenia',
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.externalDoctorsForm = this.formBuilder.group({
      externalDoctors: this.formBuilder.array([]),
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['doctorSpecialties']) {
      this.loadAllSpecialties();
    }
  }

  loadAllSpecialties() {
    this.allSpecialties = [...this.doctorSpecialties];
    this.cdr.detectChanges();
  }

  get externalDoctors(): FormArray {
    return this.externalDoctorsForm.get('externalDoctors') as FormArray;
  }

  createDoctorFormGroup(): FormGroup {
    return this.formBuilder.group({
      doctorId: [null, Validators.required],
      firstName: [{ value: '', disabled: false }, Validators.required],
      lastName: [{ value: '', disabled: false }, Validators.required],
      phoneNumber: [{ value: '', disabled: false }, Validators.required],
      registrationNumber: [{ value: '', disabled: false }, Validators.required],
      addressData: this.formBuilder.group({
        street: [{ value: '', disabled: false }],
        streetNumber: [{ value: '', disabled: false }],
        apartmentNumber: [{ value: '', disabled: false }],
        town: [{ value: '', disabled: false }],
        postCode: [{ value: '', disabled: false }],
        district: [{ value: '', disabled: false }],
        countryId: [{ value: null, disabled: false }],
      }),
      specialties: [{ value: [], disabled: false }],
      toggleControl: [false],
    });
  }

  addNewDoctor() {
    this.externalDoctors.push(this.createDoctorFormGroup());
  }

  removeDoctor(index: number) {
    this.externalDoctors.removeAt(index);
  }

  onCountryChange(placeId: number): void {
    this.isCountryCyprus = placeId === 55; // Cyprus ID is 55
  }

  private isUpdating = false;

  onDoctorSelectionChange(
    externalDoctorCymaId: number,
    index: number,
    cymaDropdown: boolean
  ) {
    // Prevent recursive calls
    if (this.isUpdating) return;

    this.isUpdating = true;

    const doctorGroup = this.externalDoctors.at(index) as FormGroup;

    if (!externalDoctorCymaId) {
      // If dropdown is cleared, reset the form group
      doctorGroup.reset();

      // Enable manual input
      this.enableDoctorFields(index);
      this.cdr.detectChanges();
      this.isUpdating = false;
      return;
    }

    const currentDoctorId = doctorGroup.get('doctorId')?.value;

    const existingIds = this.externalDoctors.controls
      .map((control, idx) =>
        idx !== index ? control.get('doctorId')?.value : null
      )
      .filter((id) => id !== null);

    if (existingIds.includes(externalDoctorCymaId)) {
      // Reset the current dropdown selection and show an error message
      this.externalDoctors.at(index).reset();
      Swal.fire({
        icon: 'error',
        title: 'Duplicate Doctor',
        text: `${this.translate.instant(
          'This doctor is already added in the form'
        )}.`,
        confirmButtonText: 'OK',
        confirmButtonColor: '#d33',
      });
      this.isUpdating = false;
      return;
    }

    if (cymaDropdown) {
      // Find the selected doctor data from the API list
      const cymaDoctorData = this.externalDoctorsCymaApi.find(
        (doc) => doc.id === externalDoctorCymaId
      );

      if (cymaDoctorData) {
        const specialtyIds = [
          cymaDoctorData.specialty1,
          cymaDoctorData.specialty2,
          cymaDoctorData.specialty3,
        ].filter((id) => id);

        // Match the doctor from admissionExternalDoctors to get the ID
        // const matchedExistingDoctor = this.admissionExternalDoctors.find(
        //   (doc) => doc.externalDoctorCymaId === externalDoctorCymaId
        // );

        // Get the current form data for merging
        const currentFormData = this.externalDoctors.at(index).value;

        // Ensure addressData exists to prevent undefined errors
        const currentAddressData = currentFormData.addressData || {};

        // Merge the selected doctor's data with any existing manual input
        const updatedDoctor = {
          id: /*matchedExistingDoctor?.id ||*/ currentDoctorId || null,
          firstName:
            cymaDoctorData.firstName || currentFormData.firstName || '',
          lastName: cymaDoctorData.lastName || currentFormData.lastName || '',
          phoneNumber:
            cymaDoctorData.phoneNumber || currentFormData.phoneNumber || '',
          registrationNumber:
            cymaDoctorData.registrationNumber ||
            currentFormData.registrationNumber ||
            '',
          addressData: {
            street:
              `${cymaDoctorData.address1 ?? ''} ${
                cymaDoctorData.address2 ?? ''
              }`.trim() ||
              currentAddressData.street ||
              '',
            town: cymaDoctorData.city || currentAddressData.town || '',
            postCode:
              cymaDoctorData.postalCode || currentAddressData.postCode || '',
            district: currentAddressData.district || '', // Use default value if undefined
            streetNumber: currentAddressData.streetNumber || '',
            apartmentNumber: currentAddressData.apartmentNumber || '',
            countryId:
              cymaDoctorData.country?.toLowerCase() === 'cy' ||
              cymaDoctorData.country?.toLowerCase() === 'cyprus'
                ? 55
                : currentAddressData.countryId || null,
          },
          specialties:
            specialtyIds.length > 0
              ? specialtyIds
              : currentFormData.specialties || [],
          toggleControl: cymaDropdown, // Indicates the doctor is selected from the dropdown
          externalDoctorCymaId: externalDoctorCymaId || null,
        };

        // Patch the form with the updated doctor data
        this.externalDoctors
          .at(index)
          .patchValue(updatedDoctor, { emitEvent: false });
      }
    } else {
      // Find the selected doctor data from the API list
      const doctorData = this.externalDoctorsApi.find(
        (doc) => doc.id === externalDoctorCymaId
      );
      // console.log(doctorData);

      if (doctorData) {
        const specialtyIds = doctorData.externalDoctorSpecialties;
        // Match the doctor from admissionExternalDoctors to get the ID
        // const matchedExistingDoctor = this.admissionExternalDoctors.find(
        //   (doc) => doc.externalDoctorCymaId === externalDoctorCymaId
        // );

        // Get the current form data for merging
        const currentFormData = this.externalDoctors.at(index).value;

        // Ensure addressData exists to prevent undefined errors
        const currentAddressData = currentFormData.addressData || {};

        // Merge the selected doctor's data with any existing manual input
        const updatedDoctor = {
          id: /*matchedExistingDoctor?.id ||*/ currentDoctorId || null,
          firstName: doctorData.firstName || currentFormData.firstName || '',
          lastName: doctorData.lastName || currentFormData.lastName || '',
          phoneNumber:
            doctorData.phoneNumber || currentFormData.phoneNumber || '',
          registrationNumber:
            doctorData.registrationNumber ||
            currentFormData.registrationNumber ||
            '',
          addressData: {
            street:
              `${doctorData.addressData.street}`.trim() ||
              currentAddressData.street ||
              '',
            town: doctorData.addressData.town || currentAddressData.town || '',
            postCode:
              doctorData.addressData.streetNumber ||
              currentAddressData.postCode ||
              '',
            district: currentAddressData.district || '', // Use default value if undefined
            streetNumber: currentAddressData.streetNumber || '',
            apartmentNumber: currentAddressData.apartmentNumber || '',
            countryId:
              doctorData.addressData.countryId === 55
                ? 55
                : currentAddressData.countryId || null,
          },
          specialties:
            specialtyIds.length > 0
              ? specialtyIds
              : currentFormData.specialties || [],
          toggleControl: cymaDropdown, // Indicates the doctor is selected from the dropdown
          externalDoctorCymaId: externalDoctorCymaId || null,
        };

        // Patch the form with the updated doctor data
        this.externalDoctors
          .at(index)
          .patchValue(updatedDoctor, { emitEvent: false });
      }
    }
    this.disableDoctorFields(index);
    this.cdr.detectChanges();
    this.isUpdating = false;
  }

  resetDoctorFields(index: number) {
    const doctorGroup = this.externalDoctors.at(index) as FormGroup;
    doctorGroup.reset();
    doctorGroup.get('toggleControl')?.setValue(false);
    this.enableDoctorFields(index);
  }

  disableDoctorFields(index: number) {
    const doctorGroup = this.externalDoctors.at(index) as FormGroup;
    doctorGroup.get('firstName')?.disable();
    doctorGroup.get('lastName')?.disable();
    doctorGroup.get('phoneNumber')?.disable();
    doctorGroup.get('registrationNumber')?.disable();
    doctorGroup.get('specialties')?.disable();
    doctorGroup.get('addressData')?.disable();
    doctorGroup.get('toggleControl')?.disable();
    doctorGroup.get('doctorId')?.enable(); // Keep dropdown enabled
  }

  enableDoctorFields(index: number) {
    const doctorGroup = this.externalDoctors.at(index) as FormGroup;
    doctorGroup.enable();
    doctorGroup.get('doctorId')?.enable(); // Keep dropdown enabled
  }
  onExternalDoctorToggle(isExternal: boolean, index: number) {
    const doctorGroup = this.externalDoctors.at(index) as FormGroup;

    if (!isExternal) {
      doctorGroup.enable();
    } else {
      doctorGroup.get('specialties')?.disable();
      doctorGroup.get('firstName')?.disable();
      doctorGroup.get('lastName')?.disable();
      doctorGroup.get('phoneNumber')?.disable();
      doctorGroup.get('registrationNumber')?.disable();
      doctorGroup.get('addressData')?.disable();
    }
  }

  enter(event: any) {
    event.preventDefault();
    document.getElementById('emergency-next-btn')?.click();
  }
}
