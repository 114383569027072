import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IMedicationDetailed } from '../interfaces/Medication/IMedicationDetailed';
import { IAddMedication } from '../interfaces/Medication/IAddMedication';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class PastMedicationService {
  public pastMedicationDataSub = new BehaviorSubject<IMedicationDetailed[]>([]);

  public pastMedicationLoadingSub = new BehaviorSubject<boolean>(false);
  public pastMedicationLoadingSub$ =
    this.pastMedicationLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.pastMedicationLoadingSub.next(true);
    this.getPastMedication(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.pastMedicationDataSub.next(response?.data!);
        this.pastMedicationLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve past medications');
        console.error('Error fetching past medications:', err);
      },
    });
  }

  getPastMedication(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IMedicationDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IMedicationDetailed[]>>(
        `${environment.BACKEND_URL}Medication/PastMedications/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IMedicationDetailed[]>>(
        `${environment.BACKEND_URL}Medication/PastMedications/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  editPastMedication(pastMedicationsObj: IAddMedication) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Medication/${pastMedicationsObj.id}`,
      pastMedicationsObj,
      {
        headers: headers,
      }
    );
  }

  deletePastMedication(pastMedicationId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Medication/${pastMedicationId}`,
      {
        headers: headers,
      }
    );
  }

  deletePastMedicationFromTable(medicationId: string) {
    // REMOVE THE Medical History
    this.pastMedicationDataSub.next(
      this.pastMedicationDataSub.value?.filter(
        (pm) => pm.id?.toString() != medicationId
      )
    );
  }
}
