export const COLORS = {
  headercolor: 'rgba(22, 29, 32, 1)',
  labelcolor: 'rgba(22, 29, 32, 1)',
  green0: 'rgba(49, 125, 136, 1)',
  green1: 'rgba(13, 148, 136, 1)',
  green2: 'rgba(118, 195, 188, 0.8)',
  green3: 'rgba(118, 195, 188, 0.5)',
  green4: 'rgba(118, 195, 188, 0.4)',
  green5: 'rgba(118, 195, 188, 0.2)',
  green6: 'rgba(118, 195, 188, 0.15)',
  green7: 'rgba(118, 195, 188, 0.1)',
  green8: 'rgba(118, 195, 188, 0.01)',
  greenhover: 'rgba(49, 125, 136, 0.15)',
  swalgreen: 'rgba(13, 148, 136, 1)',
  swalorange: 'rgba(255, 212, 112, 1)',
  swalred: 'rgba(255, 105, 105, 1)',
  black: 'rgba(0, 0, 0, 1)',
  green: 'rgba(82, 190, 157, 1)',
  red: 'rgba(250, 79, 79, 1)',
  redhover: 'rgba(149, 62, 62, 1)',
  orange: 'rgba(253, 197, 93, 1)',
  creation: 'rgba(173, 216, 230, 1)',
  pending: 'rgba(253, 197, 93, 1)',
  waitinglist: 'rgba(56, 157, 224, 1)',
  approved: 'rgba(82, 190, 157, 1)',
  admitted: 'rgba(135, 206, 250, 1)',
  discharged: 'rgba(255,160,122, 1)',
  rejected: 'rgba(255, 69, 80, 1)',
  textcolor: '#333',
  lightgray: 'rgba(245, 245, 245, 1)',
};

// export const COLORS = {
//   headercolor: 'rgba(22, 29, 32, 1)',
//   labelcolor: 'rgba(22, 29, 32, 1)',
//   green0: 'rgba(20, 75, 124, 1)',
//   green1: 'rgba(28, 121, 201, 1)',
//   green2: 'rgba(28, 121, 201, 0.8)',
//   green3: 'rgba(118, 176, 233, 0.5)',
//   green4: 'rgba(118, 176, 233, 0.4)',
//   green5: 'rgba(118, 176, 233, 0.2)',
//   green6: 'rgba(118, 176, 233, 0.15)',
//   green7: 'rgba(118, 195, 188, 0.1)',
//   green8: 'rgba(118, 195, 188, 0.01)',
//   greenhover: 'rgba(20, 75, 124, 0.15)',
//   swalgreen: 'rgba(13, 148, 136, 1)',
//   swalorange: 'rgba(255, 212, 112, 1)',
//   swalred: 'rgba(255, 105, 105, 1)',
//   black: 'rgba(0, 0, 0, 1)',
//   green: 'rgba(82, 190, 157, 1)',
//   red: 'rgba(250, 79, 79, 1)',
//   redhover: 'rgba(149, 62, 62, 1)',
//   orange: 'rgba(253, 197, 93, 1)',
//   creation: 'rgba(173, 216, 230, 1)',
//   pending: 'rgba(253, 197, 93, 1)',
//   waitinglist: 'rgba(56, 157, 224, 1)',
//   approved: 'rgba(82, 190, 157, 1)',
//   admitted: 'rgba(135, 206, 250, 1)',
//   discharged: 'rgba(255,160,122, 1)',
//   rejected: 'rgba(255, 69, 80, 1)',
//   textcolor: '#333',
//   lightgray: 'rgba(245, 245, 245, 1)',
// };
