/* src/app/components/ehr/patients/components/hysteroscopy-section/hysteroscopy-examination/hysteroscopy-examination.component.css */
.App {
  height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  margin: 0 !important;
  padding: 0 !important;
}
.main-container {
  display: flex !important;
  flex: 1 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.left-column {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  overflow: hidden !important;
  position: relative !important;
}
.top-left-logo {
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  z-index: 10 !important;
}
.video-container {
  position: relative !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.video-container video,
.video-container img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out !important;
  backface-visibility: hidden !important;
}
.overlay {
  position: absolute !important;
  top: 0 !important;
  width: 65% !important;
  z-index: 10 !important;
  color: #ffffff !important;
  display: flex !important;
  justify-content: space-around !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
}
.select-status-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.select-status-container select,
.select-status-container h3 {
  margin: 0 !important;
  padding: 0 5px !important;
  line-height: normal !important;
}
.overlay .right-info {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}
.right-info span {
  margin-left: 5px !important;
}
.hidden {
  opacity: 0 !important;
  pointer-events: none !important;
}
.toggle-visual-button {
  position: absolute !important;
  top: 10px !important;
  left: 10px !important;
  z-index: 1000 !important;
}
.right-column {
  min-width: fit-content !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px !important;
  flex-shrink: 0 !important;
  min-height: fit-content !important;
}
.button-container {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
  width: 100% !important;
  align-items: center !important;
  margin: 10px 0 !important;
}
.status-container,
.video-source {
  text-align: center !important;
  margin-bottom: 10px !important;
  width: 100% !important;
  margin-top: 1em !important;
}
.status-container h2,
.video-source h2 {
  font-size: 1rem !important;
  margin: 0 !important;
}
.video-source select,
select {
  width: fit-content !important;
  padding: 5px !important;
  margin-top: 5px !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
}
.button-container {
  margin-top: 1em !important;
}
.hysteroscopy-exam-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 60px !important;
  height: 60px !important;
  border: none !important;
  padding: 5px 10px !important;
  outline: none !important;
  cursor: pointer !important;
  font-size: 2em !important;
  border-radius: 32px !important;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.26) !important;
  background-color: #ffffff !important;
}
.btn-stop-exam {
  background-color: #ff4343 !important;
}
button:disabled {
  background-color: #d3d3d3 !important;
  cursor: not-allowed !important;
}
@media (max-width: 1024px) {
  .main-container {
    flex-direction: column !important;
  }
  .left-column,
  .right-column {
    width: 100% !important;
  }
  .right-column,
  .button-container {
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .button-container {
    gap: 5px !important;
  }
  .status-container h2,
  .video-source h2 {
    font-size: 0.9rem !important;
  }
}
::ng-deep .anticon {
  scale: 1 !important;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--color-1);
}
/*# sourceMappingURL=hysteroscopy-examination.component-XKTTYZDW.css.map */
