import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IPregnancyOutcomeDetailed } from '../interfaces/PregnancyOutcome/IPregnancyOutcomeDetailed';
import { IAddPregnancyOutcome } from '../interfaces/PregnancyOutcome/IAddPregnancyOutcome';
import { IPregnancyOutcomeDropdown } from '../interfaces/PregnancyOutcome/IPregnancyOutcomeDropdown';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class PregnancyOutcomeService {
  public pregnancyOutcomeModalSub = new BehaviorSubject<boolean>(false);

  public pregnancyOutcomeEditSub = new BehaviorSubject<
    IPregnancyOutcomeDetailed | undefined
  >(undefined);

  public pregnancyOutcomeDataSub = new BehaviorSubject<
    IPregnancyOutcomeDetailed[]
  >([]);

  public pregnancyOutcomeLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.pregnancyOutcomeLoadingSub.next(true);
    this.getPregnancyOutcome(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.pregnancyOutcomeDataSub.next(response?.data!);
        this.pregnancyOutcomeLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError(
          'Unable to retrieve Pregnancy Outcome'
        );
        console.error('Error fetching Pregnancy Outcome:', err);
      },
    });
  }

  getPregnancyOutcome(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IPregnancyOutcomeDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IPregnancyOutcomeDetailed[]>
      >(
        `${environment.BACKEND_URL}PregnancyOutcome/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IPregnancyOutcomeDetailed[]>
      >(`${environment.BACKEND_URL}PregnancyOutcome/${patientId}`, {
        headers: headers,
      });
    }
  }

  getDropdownData(): Observable<
    IGenericServiceResponse<IPregnancyOutcomeDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IPregnancyOutcomeDropdown>>(
      `${environment.BACKEND_URL}PregnancyOutcome/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addPregnancyOutcome(pregnancyOutcomeObj: IAddPregnancyOutcome) {
    pregnancyOutcomeObj.visitId = this.eocService.currentVisitId();
    pregnancyOutcomeObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}PregnancyOutcome`,
      pregnancyOutcomeObj,
      {
        headers: headers,
      }
    );
  }

  editPregnancyOutcome(pregnancyOutcomeObj: IAddPregnancyOutcome) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}PregnancyOutcome/${pregnancyOutcomeObj.id}`,
      pregnancyOutcomeObj,
      {
        headers: headers,
      }
    );
  }

  deletePregnancyOutcome(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}PregnancyOutcome/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deletePregnancyOutcomeFromTable(pregnancyOutcomeId: string) {
    // REMOVE THE Pregnancy Outcome
    this.pregnancyOutcomeDataSub.next(
      this.pregnancyOutcomeDataSub.value?.filter(
        (mh) => mh.id?.toString() != pregnancyOutcomeId
      )
    );
  }
}
