import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { IUserManagement } from '../../../../interfaces/IUserManagement';
import { IUserManagementEdit } from '../../../../interfaces/IUserManagementEdit';
import { UserManagementService } from '../../../../services/userManagement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { skip } from 'rxjs/operators';
import { UnsavedChangesGuard } from 'src/app/guards/unsaved-changes.guard';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.css'],
})
export class UserManagementEditComponent {
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = '';
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.showDiscardChangesDialog();
  }

  showDiscardChangesDialog(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      Swal.fire({
        text: `${this.translate.instant(
          'All unsaved data will be lost. Do you want to continue?'
        )}`,
        showDenyButton: false,
        showCancelButton: true,
        cancelButtonText: `${this.translate.instant('No')}`,
        confirmButtonText: `${this.translate.instant('Yes')}`,
      }).then((result) => {
        if (result.isConfirmed) {
          observer.next(true);
        } else if (result.isDenied) {
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }

  formGroup!: FormGroup;
  roles: any[] = [];
  locales: any[] = [];
  loading = false;
  isFormChanged: boolean = false;
  formSubscription!: Subscription;
  isInitialLoading: boolean = true;
  private initialPhoneNumberValue: any;
  usernamePattern = /^[a-zA-Z0-9.]{6,20}$/;

  editedUser: IUserManagement = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    roles: [],
    locale: '',
    email: '',
    phoneNumber: '',
    requiredActions: false,
  };

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private readonly unsavedChangesGuard: UnsavedChangesGuard,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.fetchData();
  }

  ngOnDestroy() {
    if (this.formSubscription) this.formSubscription.unsubscribe();
  }

  buildForm() {
    // Initialize form controls
    this.formGroup = this.formBuilder.group({
      username: [
        '',
        [Validators.required, Validators.pattern(this.usernamePattern)],
      ],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      roles: [[], Validators.required],
      subRoles: [[]],
      locale: ['', Validators.required],
    });
  }

  fetchData() {
    this.loading = true;

    forkJoin({
      dropdownData: this.userManagement.getDropdownData(),
      userData: this.userManagement.getUserById(
        this.route.snapshot.queryParams['id']
      ),
    }).subscribe({
      next: ({ dropdownData, userData }) => {
        this.roles = dropdownData.data.roles.map((role: any) => ({
          ...role,
          translatedName: this.translate.instant(role.name),
          name: role.name,
        }));

        this.locales = dropdownData.data.locale;
        this.editedUser = { ...userData.data };

        this.patchFormValues();

        this.initialPhoneNumberValue = this.formGroup.get('phoneNumber')?.value;
        this.subscribeToFormChanges();

        this.isInitialLoading = false;
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching data:', err);
        Swal.fire({
          text: `${this.translate.instant(
            'Failed to load data. Please try again.'
          )}`,
          icon: 'error',
        });
        this.loading = false;
      },
    });
  }

  patchFormValues() {
    const flatRoles = this.roles.map((role) => role.id);

    const flatSubroles = this.roles.flatMap((role) =>
      role.subRoles.map((subrole: any) => subrole.id)
    );

    this.onRolesChange(
      this.editedUser?.roles
        ?.filter((r) => flatRoles.includes(r.id))
        .map((r) => r.id)
    );

    this.formGroup.patchValue(
      {
        username: this.editedUser.username,
        firstName: this.editedUser.firstName,
        lastName: this.editedUser.lastName,
        email: this.editedUser.email,
        phoneNumber: this.editedUser.phoneNumber,
        roles: this.editedUser?.roles
          ?.filter((r) => flatRoles.includes(r.id))
          .map((r) => r.id),
        subRoles: this.editedUser?.roles
          ?.filter((r) => flatSubroles.includes(r.id))
          .map((r) => r.id),
        locale:
          this.locales.find((locale) => locale.code === this.editedUser.locale)
            ?.id || null,
      },
      { emitEvent: false } // Suppress valueChanges emission
    );

    this.formGroup.markAsPristine();
    this.isFormChanged = false;
  }
  subscribeToFormChanges() {
    this.formSubscription = this.formGroup.valueChanges
      .pipe(skip(1)) // Skip the first emission to avoid the phoneNumber emit
      .subscribe(() => {
        this.isFormChanged = this.isFormDirtyExcludingPhoneNumber();
        this.cdr.detectChanges();
      });
  }
  private isFormDirtyExcludingPhoneNumber(): boolean {
    const controls = this.formGroup.controls;
    const isOtherControlsDirty = Object.keys(controls).some((key) => {
      if (key === 'phoneNumber') {
        return false; // We'll handle phoneNumber separately
      }
      return controls[key].dirty;
    });

    // Compare current phoneNumber value with initial value
    const phoneControl = this.formGroup.get('phoneNumber');
    const isPhoneNumberChanged =
      JSON.stringify(phoneControl?.value) !==
      JSON.stringify(this.initialPhoneNumberValue);

    return isOtherControlsDirty || isPhoneNumberChanged;
  }

  sendMail() {
    const userId = this.route.snapshot.queryParams['id'];

    this.userManagement.sendMailVerification(userId).subscribe({
      next: (response) => {
        this.swalToastService.toastSuccess(
          'Mail verification sent successfully'
        );
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to send mail verification');
        console.error('Error sending mail verification:', err);
      },
    });
  }

  saveChanges() {
    if (this.formGroup.valid) {
      this.loading = true;

      const userId = this.route.snapshot.queryParams['id'];

      const selectedLocaleId = this.formGroup.get('locale')?.value;
      const selectedLocale = this.locales.find(
        (locale) => locale.id == selectedLocaleId
      );
      const selectedRoleIds = this.formGroup.get('roles')?.value;
      const selectedSubroleIds = this.formGroup.get('subRoles')?.value;

      let requestBody: IUserManagementEdit = {
        userUpdate: {
          username: this.formGroup.get('username')?.value,
          firstName: this.formGroup.get('firstName')?.value,
          lastName: this.formGroup.get('lastName')?.value,
          email: this.formGroup.get('email')?.value,
          attributes: {
            locale: selectedLocale?.code,
            phoneNumber: this.formGroup.get('phoneNumber')?.value.e164Number,
          },
        },
        roleInfo: [
          // Map roles to userRole
          ...this.roles
            .filter((role) => selectedRoleIds?.includes(role.id))
            .map((role) => ({
              id: role.id,
              name: role.name,
            })),

          // Append subroles as userRole
          ...this.roles
            .flatMap((role) => role.subRoles)
            .filter((subrole: any) => selectedSubroleIds?.includes(subrole.id))
            .map((subrole: any) => ({
              id: subrole.id,
              name: subrole.name,
            })),
        ],
      };

      this.userManagement
        .saveUser(userId, requestBody)
        .subscribe({
          next: (response) => {
            this.swalToastService.toastSuccess('User updated successfully');

            this.formGroup.markAsPristine();
            this.isFormChanged = false;

            const originalCanDeactivate =
              this.unsavedChangesGuard.canDeactivate;
            this.unsavedChangesGuard.canDeactivate = () => true;
            this.router.navigate(['ehr/userManagement']).then(() => {
              this.unsavedChangesGuard.canDeactivate = originalCanDeactivate;
            });
          },
          error: (err) => {
            this.swalToastService.toastError(err.error.message);
            console.error('Error saving user changes:', err);
            this.fetchData();
          },
        })
        .add(() => {
          this.loading = false;
        });
    } else {
      this.formGroup.markAllAsTouched();
      this.swalToastService.toastWarning(
        'Please fill in all required fields with valid data'
      );
    }
  }

  cancelEdit() {
    this.formGroup.markAsPristine(); // Mark the form as pristine
    this.isFormChanged = false; // Reset the change flag
    this.router.navigate(['ehr/userManagement']);
  }

  getPhoneNumberControl(): FormControl<string | null> {
    return (
      (this.formGroup.get('phoneNumber') as FormControl<string | null>) ||
      new FormControl<string | null>(null)
    );
  }

  filteredSpecialties: any[] = [];

  onRolesChange(selectedRoles: any) {
    const selectedSubroles = this.roles
      .filter((role) => selectedRoles?.includes(role?.id))
      .flatMap((role) => role?.subRoles);

    this.filteredSpecialties = selectedSubroles;
    this.formGroup.get('subRoles')?.reset([]);
  }
}
