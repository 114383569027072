import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import Swal from 'sweetalert2';
import { ISocialHistoryDetailed } from '../interfaces/SocialHistory/ISocialHistoryDetailed';
import { IAddSocialHistory } from '../interfaces/SocialHistory/IAddSocialHistory';
import { ISocialHistoryDropdown } from '../interfaces/SocialHistory/ISocialHistoryDropdown';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class InitialAssessmentService {
  public initialAssessmentModalSub = new BehaviorSubject<boolean>(false);

  public initialAssessmentEditSub = new BehaviorSubject<
    ISocialHistoryDetailed | undefined
  >(undefined);

  public initialAssessmentDataSub = new BehaviorSubject<
    ISocialHistoryDetailed[]
  >([]);

  public initialAssessmentLoadingSub = new BehaviorSubject<boolean>(false);
  public initialAssessmentLoadingSub$ =
    this.initialAssessmentLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}
}
