/* src/app/components/ehr/patients/components/alerts-section/medical-alert/medical-alert-add-edit/medical-alert-add-edit.component.css */
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 150px;
  margin-left: 1em;
}
.alert-submitted .alert.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=medical-alert-add-edit.component-AP6UVGU7.css.map */
