import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import Swal from 'sweetalert2';

@Injectable()
export class SwalToastService {
  constructor(private readonly translateService: TranslateService) {}

  toastSuccess(msg?: string, msg2?: string) {
    const translatedMsg = msg
      ? this.translateService.instant(msg) +
        (msg2 ? this.translateService.instant(msg2) : '') +
        '!'
      : undefined;

    Swal.fire({
      text: translatedMsg,
      toast: true,
      position: 'bottom-end',
      showCancelButton: false,
      showConfirmButton: false,
      color: 'white',
      background: COLORS.swalgreen,
      timer: 3000,
    });
  }

  toastError(msg?: string, html?: string) {
    const translatedMsg = msg
      ? this.translateService.instant(msg) + '!'
      : undefined;

    Swal.fire({
      text: translatedMsg,
      html: html,
      toast: true,
      position: 'bottom-end',
      showCancelButton: false,
      showConfirmButton: false,
      color: 'white',
      background: COLORS.swalred,
      timer: 3000,
    });
  }

  toastWarning(msg?: string) {
    const translatedMsg = msg
      ? this.translateService.instant(msg) + '!'
      : undefined;

    Swal.fire({
      text: translatedMsg,
      toast: true,
      position: 'bottom-end',
      showCancelButton: false,
      showConfirmButton: false,
      color: 'white',
      background: COLORS.swalorange,
      timer: 3000,
    });
  }
}
