/* src/app/components/ehr/patients/components/alerts-section/allergies/allergies-add-edit/allergies-add-edit.component.css */
.reactions-container {
  margin-top: 2em;
  padding: 1.5em;
  border: 2px solid rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}
.allergy-submitted .allergy.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=allergies-add-edit.component-H6ESJ7N5.css.map */
