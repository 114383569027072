<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Pregnancy Outcome"
        [columns]="['Outcome', 'Number of Children']"
        [rowFields]="['outcome', 'numberOfChildren']"
        [data]="pregnancyOutcome"
        [addComponent]="addComp"
        [isLoading]="isLoading"
        [deleteFun]="deleteFun"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-pho')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
