import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IQuickAdmissionDetailed } from '../interfaces/QuickAdmission/IQuickAdmissionDetailed';
import { IQuickAdmissionDropdown } from '../interfaces/QuickAdmission/IQuickAdmissionDropdown';
import { IAddQuickAdmission } from '../interfaces/QuickAdmission/IAddQuickAdmission';
@Injectable({
  providedIn: 'root',
})
export class QuickAdmissionService {
  constructor(private _http: HttpClient) {}

  getTimeline(
    patientId: string
  ): Observable<IGenericServiceResponse<IQuickAdmissionDetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IQuickAdmissionDetailed>>(
      `${environment.BACKEND_URL}QuickAdmissionUpdate/${patientId}`,
      {
        headers: headers,
      }
    );
  }

  getDropdownData(): Observable<IQuickAdmissionDropdown> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IQuickAdmissionDropdown>(
      `${environment.BACKEND_URL}QuickAdmissionUpdate/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  updateQuickAdmission(patientId: string, qaObj: IAddQuickAdmission) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}QuickAdmissionUpdate/${patientId}`,
      qaObj,
      {
        headers: headers,
      }
    );
  }
}
