<nz-layout>
  <nz-header>
    <span class="section-header">{{
      "Preview Summary" | translate
    }}</span></nz-header
  >
  <nz-content fxLayoutAlign="center">
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoading">
      <nz-spin nzSimple></nz-spin>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngIf="!isLoading && data?.isEmpty"
    >
      {{ "No records added" | translate }}!
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="pd"
      *ngIf="!isLoading && !data?.isEmpty && !isInpatientView()"
    >
      <div fxLayout="col" fxFlex="15">{{ "Final Visit" | translate }}</div>
      <div fxLayout="col" fxFlex="85">
        <nz-switch
          [(ngModel)]="switchValue"
          (ngModelChange)="handleEndedValue($event)"
          class="pdd toggle"
        ></nz-switch>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="pd"
      *ngIf="!isLoading && !data?.isEmpty"
    >
      <div fxLayout="col" fxFlex="15">
        {{ "Potential Diagnosis" | translate }}
      </div>
      <div fxLayout="col" fxFlex="85">
        <nz-select
          nzShowSearch
          nzAllowClear
          ngModel="pd"
          class="pdd"
          nzAllowClear
          [nzPlaceHolder]="'Choose Potential Diagnosis' | translate"
          [required]="true"
          [nzLoading]="isPdLoading"
          (ngModelChange)="setPD($event)"
          [ngModel]="pdValue"
          [ngClass]="{ 'ng-invalid': !pdValue }"
          [nzOptions]="pdList"
          nzNoAnimation
        >
          <!-- <ng-container *ngFor="let pdi of pdList">
                        <nz-option nzLabel="{{pdi.name}}" nzValue="{{pdi.id}}"></nz-option>
                    </ng-container> -->
        </nz-select>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="pd last-row"
      *ngIf="!isLoading && !data?.isEmpty"
    >
      <div fxLayout="col" fxFlex="15">{{ "Notes" | translate }}</div>
      <div fxLayout="col" fxFlex="85">
        <textarea
          rows="2"
          nz-input
          [(ngModel)]="noteValue"
          class="nt"
          (ngModelChange)="setNote($event)"
        ></textarea>
      </div>
    </div>

    <!--      VITAL SIGNS-->
    <ng-container *ngIf="data?.getAllVitalSigns?.length! > 0">
      <app-reusable-reverse-table
        title="Vital Signs"
        [rowNames]="[
          'Temperature (°C)',
          'Respiratory Rate (bpm)',
          'Heart Rate (bpm)',
          'Systolic Blood Pressure (mm[Hg])',
          'Diastolic Blood Pressure (mm[Hg])',
          'Urine Output (ml/kg/h)',
          'SpO2 (%)'
        ]"
        [rowFields]="[
          'temperature',
          'respiratoryRate',
          'heartRate',
          'systolicBloodPressure',
          'diastolicBloodPressure',
          'urineOutput',
          'spO2'
        ]"
        colIdentifier="onSetDateTime"
        [data]="data?.getAllVitalSigns!"
        [isLoading]="isLoading"
        [isPreview]="true"
        [pagination]="false"
      />

      <!-- <app-reusable-table class="w-100" title="Vital Signs" [columns]="[
                        'Onset Date',
            'Temperature (°C)',
            'Respiratory Rate (bpm)',
            'Heart Rate (bpm)','Systolic Blood Pressure (mm[Hg])',
            'Diastolic Blood Pressure (mm[Hg])'
        ,'Urine Output (ml/kg/h)', 'SpO2 (%)']" [rowFields]="['onSetDateTime','temperature','respiratoryRate','heartRate','systolicBloodPressure',
            'diastolicBloodPressure',
            'urineOutput',
            'spO2']" [data]="data?.getAllVitalSigns" [isLoading]="isLoading" [isPreview]="true" [pagination]="false" /> -->
    </ng-container>

    <!--      ARTERIAL BLOOD GASES-->
    <ng-container *ngIf="data?.getAllArterialBloodGasses?.length! > 0">
      <app-reusable-table
        title="Arterial Blood Gases"
        [columns]="['Date & Time', 'pH', 'HCO3', 'PaCO2', 'PaO2', 'SaO2']"
        [rowFields]="['onSetDateTime', 'ph', 'hco3', 'paco2', 'pao2', 'sao2']"
        [data]="data?.getAllArterialBloodGasses"
        [isLoading]="isLoading"
        [isPreview]="true"
        [pagination]="false"
      />
    </ng-container>

    <!--      GLASGOW COMA SCALE-->
    <ng-container *ngIf="data?.getAllGlasgow?.length! > 0">
      <app-reusable-table
        title="Glasgow Coma Scale"
        [columns]="[
          'Date & Time',
          'Eye Opening',
          'Verbal Response',
          'Motor Response',
          'Score'
        ]"
        [rowFields]="[
          'onSetDateTime',
          'eyeOpeningName',
          'verbalResponseName',
          'motorResponseName',
          'totalScore'
        ]"
        [data]="data?.getAllGlasgow"
        [isLoading]="isLoading"
        [isPreview]="true"
        [pagination]="false"
      />
    </ng-container>

    <!--      WEIGHT-->
    <app-reusable-table
      *ngIf="data?.getAllWeights?.length! > 0"
      title="Weight"
      [columns]="['Date', 'Weight (kg)', 'Description']"
      [rowFields]="['creationDate', 'weight', 'description']"
      [data]="data?.getAllWeights"
      [pagination]="false"
      [isPreview]="true"
      [isLoading]="isLoading"
    />

    <!--      NECK-->
    <app-reusable-table
      *ngIf="data?.getAllNeckSizes?.length! > 0"
      title="Neck"
      title="Neck Circumference"
      [columns]="['Date', 'Neck Circumference (cm)', 'Description']"
      [rowFields]="['creationDate', 'neckSize', 'description']"
      [data]="data?.getAllNeckSizes"
      [pagination]="false"
      [isPreview]="true"
      [isLoading]="isLoading"
    />

    <!--      ALLERGIES-->
    <ng-container
      *ngIf="
        data?.getAllAllergiesDto?.getAll?.length! > 0 ||
        data?.getAllAllergiesDto?.noKnown
      "
    >
      <app-reusable-table
        title="Allergies"
        [columns]="[
          'Category',
          'Type',
          'Substance/Product',
          'Criticality',
          'Status'
        ]"
        [rowFields]="[
          'allergyCategory',
          'eventType',
          'allergy',
          'criticality',
          'allergyStatus'
        ]"
        [data]="data?.getAllAllergiesDto?.getAll"
        [dataGetList]="data?.getAllAllergiesDto"
        [pagination]="false"
        [expandComponent]="allergiesExpandComp"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      MEDICAL ALERT-->
    <ng-container *ngIf="data?.getAllMedicalAlerts?.length! > 0">
      <app-reusable-table
        title="Medical Alerts"
        [columns]="['Description']"
        [rowFields]="['description']"
        [data]="data?.getAllMedicalAlerts"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      MEDICAL HISTORY-->
    <ng-container *ngIf="data?.getAllMedicalHistories?.length! > 0">
      <app-reusable-table
        title="Medical History"
        [columns]="['Description']"
        [rowFields]="['description']"
        [data]="data?.getAllMedicalHistories"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      VACCINATION-->
    <ng-container *ngIf="data?.getAllVaccinations?.length! > 0">
      <app-reusable-table
        title="Vaccinations"
        [columns]="[
          'Vaccine',
          'Disease Targeted',
          'Number Of Series Doses',
          'Next Date'
        ]"
        [rowFields]="[
          'vaccine',
          'diseaseTargeted',
          'numSeriesDoses',
          'nextDate'
        ]"
        [data]="data?.getAllVaccinations"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      RESOLVED PROBLEMS-->
    <ng-container *ngIf="data?.getAllResolvedProblemsDto?.length! > 0">
      <app-reusable-table
        title="Resolved Problems"
        [columns]="[
          'Problem',
          'Diagnosis Assertion',
          'Resolution Circumstances',
          'End Date',
          'Status'
        ]"
        [rowFields]="[
          'problem',
          'diagnosisAssertion',
          'resolutionCircumstancesText',
          'endDate',
          'statusCode'
        ]"
        [data]="data?.getAllResolvedProblemsDto"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      CURRENT PROBLEMS-->
    <ng-container
      *ngIf="
        data?.getAllCurrentProblemsDto?.getAll?.length! > 0 ||
        data?.getAllCurrentProblemsDto?.noKnown
      "
    >
      <app-reusable-table
        title="Current Problems"
        [columns]="[
          'Problem',
          'Diagnosis Assertion',
          'Resolution Circumstances',
          'Start Date',
          'Status'
        ]"
        [rowFields]="[
          'problem',
          'diagnosisAssertion',
          'resolutionCircumstancesText',
          'onSetDate',
          'statusCode'
        ]"
        [data]="data?.getAllCurrentProblemsDto?.getAll"
        [dataGetList]="data?.getAllCurrentProblemsDto"
        [pagination]="false"
        [expandComponent]="problemExpandComp"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      MEDICAL DEVICES-->
    <ng-container
      *ngIf="
        data?.getAllMedicalDevicesDto?.getAll?.length! > 0 ||
        data?.getAllMedicalDevicesDto?.noKnown
      "
    >
      <app-reusable-table
        title="Devices and Implants"
        [columns]="['Medical Device', 'Onset Date', 'Removal Date', 'Action']"
        [rowFields]="[
          'medicalDevice',
          'onSetDate',
          'removalDate',
          'medicalDeviceAction'
        ]"
        [data]="data?.getAllMedicalDevicesDto?.getAll"
        [dataGetList]="data?.getAllMedicalDevicesDto"
        [pagination]="false"
        [expandComponent]="medicalDeviceExpandComp"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      PROCEDURES-->
    <ng-container
      *ngIf="
        data?.getAllProceduresDto?.getAll?.length! > 0 ||
        data?.getAllProceduresDto?.noKnown
      "
    >
      <app-reusable-table
        title="Procedures"
        [columns]="['Procedure', 'BodySite', 'Date']"
        [rowFields]="['procedure', 'bodySite', 'date']"
        [data]="data?.getAllProceduresDto?.getAll"
        [dataGetList]="data?.getAllProceduresDto"
        [pagination]="false"
        [expandComponent]="procedureExpandComp"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      PREGNANCY HISTORY-->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllPregnancyHistories?.length! > 0"
        title="Pregnancy History"
        [columns]="['Status', 'Description', 'Outcome Date']"
        [rowFields]="['status', 'description', 'outcomeDate']"
        [data]="data?.getAllPregnancyHistories"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      PREGNANCY OUTCOME-->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllPregnancyOutcomes?.length! > 0"
        title="Pregnancy Outcome"
        [columns]="['Outcome', 'Number of Children']"
        [rowFields]="['outcome', 'numberOfChildren']"
        [data]="data?.getAllPregnancyOutcomes"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      PREGNANCY Status-->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllPregnancyStatus?.length! > 0"
        title="Pregnancy Status"
        [columns]="[
          'Date of Observation',
          'Status',
          'Pregnancy Estimation',
          'Pregnancy Estimation Date'
        ]"
        [rowFields]="[
          'dateOfObservation',
          'status',
          'pregnancyEstimated',
          'estimatedDate'
        ]"
        [data]="data?.getAllPregnancyStatus"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      PREGNANCY Status-->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllPregnancyStatus?.length! > 0"
        title="Pregnancy Status"
        [columns]="[
          'Date of Observation',
          'Status',
          'Pregnancy Estimation',
          'Pregnancy Estimation Date'
        ]"
        [rowFields]="[
          'dateOfObservation',
          'status',
          'pregnancyEstimated',
          'estimatedDate'
        ]"
        [data]="data?.getAllPregnancyStatus"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      Hysteroscopy Files -->
    <ng-container *ngIf="data?.getAllHysteroscopyFiles?.length! > 0">
      <app-hysteroscopy-file
        [data]="data?.getAllHysteroscopyFiles"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      Current Medications-->
    <ng-container>
      <app-reusable-table
        *ngIf="
          data?.getAllCurrentMedicationsDto?.getAll?.length! > 0 ||
          data?.getAllCurrentMedicationsDto?.noKnown
        "
        title="Current Medication"
        [columns]="[
          'Onset Date & Time',
          'End Date & Time',
          'Product',
          'Package',
          'Unit',
          'Frequency',
          'Duration'
        ]"
        [rowFields]="[
          'onSetDateTime',
          'endDateTime',
          'productCombination',
          'packageCombination',
          'unitCombination',
          'frequencyCombination',
          'durationTreatmentCombination'
        ]"
        [data]="data?.getAllCurrentMedicationsDto?.getAll"
        [dataGetList]="data?.getAllCurrentMedicationsDto"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      Past Medications-->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllPastMedicationsDto?.length! > 0"
        title="Past Medication"
        [columns]="[
          'Onset Date & Time',
          'End Date & Time',
          'Product',
          'Package',
          'Unit',
          'Frequency',
          'Duration'
        ]"
        [rowFields]="[
          'onSetDateTime',
          'endDateTime',
          'productCombination',
          'packageCombination',
          'unitCombination',
          'frequencyCombination',
          'durationTreatmentCombination'
        ]"
        [data]="data?.getAllPastMedicationsDto"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
        [isPreview]="false"
      />
    </ng-container>

    <!--      Social History -->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllSocialHistories?.length! > 0"
        title="Social History"
        [columns]="['Social History', 'Unit Amount', 'Timing Unit']"
        [rowFields]="['socialHistory', 'unitAmount', 'customTimingUnit']"
        [data]="data?.getAllSocialHistories"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!-- SOCIAL HISTORY QUESTIONNAIRE -->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllQuestionnaires?.length! > 0"
        title="Social History Questionnaire"
        [columns]="[
          'Date & Time',
          'Total number of pages ',
          'Total number of questions',
          'Completion percentage',
          'Questions Completed',
          'Questions remaining',
          'Status'
        ]"
        [rowFields]="[
          'dateTime',
          'totalNumberOfPages',
          'totalNumberOfQuestions',
          'completionPercentage',
          'questionsCompleted',
          'questionsRemaining',
          'status'
        ]"
        [data]="questionnaireData"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      Travel History -->
    <ng-container>
      <app-reusable-table
        *ngIf="data?.getAllTravelHistories?.length! > 0"
        title="Travel History"
        [columns]="['Country', 'Arrival Date', 'Departure Date']"
        [rowFields]="['country', 'arrivalDate', 'departureDate']"
        [data]="data?.getAllTravelHistories"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      Laboratory Files -->
    <ng-container *ngIf="data?.getAllLaboratoriesFiles?.length! > 0">
      <app-laboratory-file
        [data]="data?.getAllLaboratoriesFiles"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      Laboratory Results -->
    <!-- <ng-container>
            <app-reusable-table *ngIf="data?.getAllLaboratoriesResults?.length! > 0" title="Laboratory Results"
                [columns]="['WBC (10⁹/L)','CRP (mg/L)','Platelet count, (10⁹/L)','Hemoglobin (g/dl)','Creatinine (µmol/L)']"
                [rowFields]="['wbc','crp','plateletCount','hemoglobin','creatinine']"
                [data]="data?.getAllLaboratoriesResults" [expandComponent]="laboratoryResultExpandComp"
                [pagination]="false" [isPreview]="true" [isLoading]="isLoading" />
        </ng-container> -->

    <!-- <ng-container *ngIf="data?.getAllLaboratoryData?.length! > 0">
            <app-reusable-reverse-table title="Laboratory Results" [rowNames]="['WBC (10⁹/L)',
        'Lymphocytes (10⁹/L)', 'Neutrophils (10⁹/L)', 'Platelet count (10⁹/L)', 'CRP (mg/L)', 'PT (sec)', 'INR', 'aPTT (sec)', 'Hemoglobin (g/dl)', 'Hematocrit (L/L)'
        ,'Creatinine (µmol/L)', 'Urea (mg/dL)']" [rowFields]="['wbc','lymphocytes','neutrophils','plateletCount', 'crp', 'pt',
        'inr', 'aptt', 'hemoglobin','hematocrit','creatinine', 'urea']" colIdentifier="date"
                [data]="data?.getAllLaboratoryData || []" [isLoading]="isLoading" [pagination]="false"
                [isPreview]="true" />
        </ng-container> -->

    <!--      Laboratory Results -->
    <ng-container
      *ngIf="
        data?.getAllLaboratoryData?.length! > 0 && data?.getLaboratoryDropdown
      "
    >
      <ng-container *ngFor="let package of data?.getLaboratoryDropdown">
        <app-reusable-dynamic-table
          *ngIf="data?.getAllLaboratoryData?.length! > 0"
          [title]="package.groupName || '' || ''"
          [groupId]="package.groupId || 0"
          [dropdownData]="package.items || []"
          colIdentifier="date"
          [isPreview]="true"
          [pagination]="false"
          endpoint="Laboratory"
          [data]="
            getLaboratoryData(data?.getAllLaboratoryData!, package.groupId!)
          "
        />
      </ng-container>
    </ng-container>

    <!--      Imaging-->
    <ng-container *ngIf="data?.getAllImaging?.length! > 0">
      <app-imaging-files
        [data]="data?.getAllImaging"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      HEALTH CARE PLAN-->
    <ng-container *ngIf="data?.getAllCarePlans?.length! > 0">
      <app-reusable-table
        title="Health Care Plans"
        [columns]="['Description']"
        [rowFields]="['description']"
        [data]="data?.getAllCarePlans"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      3D-CT PARAMETERS -->
    <ng-container *ngIf="data?.getAllCtParameters?.length! > 0">
      <app-reusable-table
        title="3D-CT Parameters"
        [columns]="[
          'Depth of Trachea',
          'Distance from Skin to Trache',
          'Distance from Cricothyroid to Carina',
          'Distance between Tracheal and Cartilages',
          'Anatomical Variations'
        ]"
        [rowFields]="[
          'depthOfTrachea',
          'distanceFromSkinToTrachea',
          'distanceFromCricothyroidToCarina',
          'distanceBetweenTrachealCartilages',
          'anatomicalVariations'
        ]"
        [data]="data?.getAllCtParameters"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      TUBE CHARACTERISTICS -->
    <ng-container *ngIf="data?.getAllTubeCharacteristics?.length! > 0">
      <app-reusable-table
        title="Tube Characteristics"
        [columns]="[
          'Tube Length',
          'Outer Diameter',
          'Inner Diameter',
          'Cuff Diameter',
          'Consistency of Tube',
          'Tube Type'
        ]"
        [rowFields]="[
          'tubeLength',
          'outerDiameter',
          'innerDiameter',
          'cuffDiameter',
          'consistencyOfTube',
          'tubeType'
        ]"
        [data]="data?.getAllTubeCharacteristics"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      ETIOLOGIES -->
    <ng-container *ngIf="data?.getAllEtiologies?.length! > 0">
      <app-reusable-table
        title="Patient's Etiologies"
        [columns]="['Condition']"
        [rowFields]="['etiologyDescription']"
        [data]="data?.getAllEtiologies"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!--      COMPLICATIONS -->
    <ng-container *ngIf="data?.getAllComplications?.length! > 0">
      <app-reusable-table
        title="Patient's Complications"
        [columns]="['Condition']"
        [rowFields]="['complicationDescription']"
        [data]="data?.getAllComplications"
        [pagination]="false"
        [isPreview]="true"
        [isLoading]="isLoading"
      />
    </ng-container>

    <!-- CAPNOGRAPHY -->
    <ng-container>
      <app-reusable-reverse-table
        *ngIf="data?.getAllCapnographies?.length! > 0"
        title="Capnography"
        [rowNames]="[
          'Positive end-expiratory pressure (PEEP)',
          'End Tidal CO2',
          'Fraction of inhaled oxygen (FiO2)',
          'Tidal Volume (Vt)',
          'Respiratory Rate (RR)',
          'Pressure of inhaled oxygen (Paw)'
        ]"
        [rowFields]="[
          'positiveEndExpiratoryPressure',
          'endTidal',
          'fractionInhaledOxygen',
          'tidalVolume',
          'respiratoryRate',
          'pressureInhaledOxygen'
        ]"
        colIdentifier="date"
        [data]="data?.getAllCapnographies || []"
        [isLoading]="isLoading"
        [pagination]="false"
        [isPreview]="true"
      />
    </ng-container>

    <!-- COMORBIDITIES -->
    <ng-container>
      <app-reusable-reverse-table
        *ngIf="data?.getAllComorbidities?.length! > 0"
        title="Comorbidities"
        [rowNames]="[
          'No. of comorbidities',
          'Respiratory disease, n(%)',
          'Hypertension, n (%)',
          'COPD, n (%)',
          'Heart Disease, n(%)',
          'Diabetes mellitus type II, n (%)',
          'Malignancy, n (%)',
          'Stroke, n (%)',
          'Immunosuppression, n (%)'
        ]"
        [rowFields]="[
          'isNoComorbidities',
          'isRespiratoryDisease',
          'isHypertension',
          'isCopd',
          'isHeartDisease',
          'isDiabetesMellitus',
          'isMalignancy',
          'isStroke',
          'isImmunosuppression',
          'isNoComorbidities'
        ]"
        colIdentifier="date"
        [data]="data?.getAllComorbidities || []"
        [isLoading]="isLoading"
        [pagination]="false"
        [isPreview]="true"
      />
    </ng-container>
  </nz-content>
</nz-layout>
