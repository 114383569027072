import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from 'src/app/services/swal.service';
import { IAddQuestionnaireAnswers } from 'src/app/interfaces/Questionnaire/IAddQuestionnaireAnswers';
import {
  IQuestionnairePage,
  IQuestionnaireQuestion,
} from 'src/app/interfaces/Questionnaire/IQuestionnaire';
import { IGetAnswerQuestionnaire } from 'src/app/interfaces/Questionnaire/IQuestionnaireAnswers';
import { IQuestionnaireDetailed } from 'src/app/interfaces/Questionnaire/IQuestionnaireDetailed';
import { DynamicQuestionnaireService } from 'src/app/services/dynamicQuestionnaire.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { COLORS } from 'src/styles/colors';
import Swal from 'sweetalert2';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-dynamic-questionnaire',
  templateUrl: './dynamic-questionnaire.component.html',
  styleUrls: ['./dynamic-questionnaire.component.css'],
})
export class DynamicQuestionnaireComponent implements OnInit {
  formGroup: FormGroup;
  pages: IQuestionnairePage[] = [];
  answers!: IGetAnswerQuestionnaire;
  data!: IQuestionnaireDetailed;
  current = 0;
  disable = false;
  isLargeScreen = true;
  isLoading = false;
  @Output() QuestionnaireLoadingStatus = new EventEmitter<boolean>();
  formInitialized = false;
  initialFormValues: { [key: string]: any } = {};
  hasChanges = false;
  ignoreChanges = false;

  constructor(
    private fb: FormBuilder,
    private questionnaireService: DynamicQuestionnaireService,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {
    this.formGroup = this.fb.group({});
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: Event): void {
    this.isLargeScreen = window.innerWidth >= 1900;
  }

  isInpatientView(): boolean {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.QuestionnaireLoadingStatus.emit(this.isLoading);
    this.onResize();
    this.getData();
    this.formGroup.valueChanges.subscribe(() => {
      if (this.formInitialized) {
        this.checkFormChanges();
      }
    });
  }

  checkFormChanges(): void {
    if (
      !this.formInitialized ||
      Object.keys(this.initialFormValues).length === 0
    ) {
      return;
    }
    this.hasChanges = false;

    Object.keys(this.formGroup.controls).forEach((key) => {
      const currentValue = this.formGroup.get(key)?.value;
      const initialValue = this.initialFormValues[key];

      // Avoid false negatives like "" !== null or 0 !== "0"
      const normalize = (val: any) =>
        val === null || val === undefined ? '' : val.toString();

      if (normalize(currentValue) !== normalize(initialValue)) {
        this.hasChanges = true;
      }
    });

    this.questionnaireService.setUnsavedChanges(this.hasChanges);
  }

  getData(): void {
    this.ignoreChanges = true;
    this.questionnaireService
      .getQuestionnaireData(1) //questionnaire template id (inpatient =1 , outpatient=2) code: this.isInpatientView() || isEOC ? 1 : 2
      .subscribe((data: IQuestionnaireDetailed) => {
        if (data && data.questionnaire) {
          this.data = data;
          this.pages = data.questionnaire.pages;
          this.pages.forEach((page) => {
            page.elements.forEach((question) => {
              this.formGroup.addControl(
                question.name,
                this.fb.control({
                  value: '',
                  disabled:
                    (!this.isInpatientView() && !this.isEOC()) ||
                    !this.hasPermission('a-sqa'),
                })
              );
              // If there are answers in the response prefill the questionnaire
              if (data.answers) {
                data.answers.answer.forEach((ans) => {
                  if (ans.questionTemplateId === question.id) {
                    // Check if the question type is boolean and convert the value accordingly
                    let value =
                      ans.questionType.toLowerCase() === 'boolean'
                        ? ans.value === 'true'
                        : ans.value;

                    this.formGroup
                      .get(question.name as string)
                      ?.setValue(value);
                  }
                });
              }
              // Save the initial value snapshot (after prefilling answers)
              this.initialFormValues[question.name] = this.formGroup.get(
                question.name
              )?.value;
            });
          });
        }
        this.isLoading = false; // <-- Set loading to false after data is loaded
        setTimeout(() => {
          this.QuestionnaireLoadingStatus.emit(this.isLoading); // Delayed emit
        }, 500);
        this.formInitialized = true;
        this.ignoreChanges = false;
        this.checkFormChanges();
        this.cdr.detectChanges();
      });
  }

  pre(event: Event): void {
    event.preventDefault(); // Prevent the form from being submitted
    if (this.current > 0) {
      this.current -= 1;
    }
  }

  next(event: Event): void {
    event.preventDefault(); // Prevent the form from being submitted
    if (this.current < this.pages.length - 1) {
      this.current += 1;
    }
  }

  onIndexChange(index: number): void {
    this.current = index;
    this.cdr.detectChanges();
  }

  onSubmit(): void {
    this.isLoading = true;
    const formValues = this.formGroup.value;
    const transformedValues: { [key: string]: any } = {};

    this.pages.forEach((page) => {
      page.elements.forEach((element: IQuestionnaireQuestion) => {
        const value = formValues[element.name];
        // Check if the value is not null, undefined, or an empty string
        if (value !== undefined && value !== null && value !== '') {
          if (typeof value === 'boolean') {
            transformedValues[element.id.toString()] = value.toString();
          } else {
            transformedValues[element.id.toString()] = value;
          }
        }
      });
    });

    const qaObj: IAddQuestionnaireAnswers = {
      questionnaireTemplateId: 1, //questionnaire template id (inpatient =1 , outpatient=2) code: this.isInpatientView() || isEOC ? 1 : 2
      questionnaireData: transformedValues,
    };
    this.questionnaireService.addQuestionnaireAnswers(qaObj).subscribe({
      next: () => {
        this.swalToastService.toastSuccess(
          'Questionnaire answers added successfully'
        );
        this.hasChanges = false;
        this.formGroup.markAsPristine();
        this.questionnaireService.setUnsavedChanges(false);
        this.getData();
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.swalToastService.toastError('Unable to add Questionnaire answers');
      },
    });
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  revertForm(): void {
    Swal.fire({
      title: this.translate.instant('Revert changes?'),
      text: this.translate.instant(
        'Are you sure you want to discard all changes and revert to the initial values?'
      ),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('Yes, revert'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        Object.keys(this.initialFormValues).forEach((key) => {
          if (this.formGroup.contains(key)) {
            this.formGroup.get(key)?.setValue(this.initialFormValues[key]);
            this.formGroup.get(key)?.markAsPristine();
            this.formGroup.get(key)?.markAsUntouched();
          }
        });

        this.questionnaireService.setUnsavedChanges(false);
        this.swalToastService.toastSuccess(
          this.translate.instant('Changes reverted')
        );

        // Swal.fire({
        //   icon: 'success',
        //   title: this.translate.instant('Changes reverted'),
        //   timer: 1500,
        //   showConfirmButton: false,
        // });
      }
    });
  }
}
