/* src/app/components/ehr/workspace/incoming-referrals/add-calendar-event/add-calendar-event.component.css */
textarea {
  width: 100%;
  box-sizing: border-box;
}
.nz-switch {
  margin-top: 8px;
}
input,
textarea,
.nz-select {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
.form-row {
  margin-top: 1em;
}
.footer {
  margin-top: 2em;
}
.save-btn {
  margin-right: 1em;
}
.add-btn {
  margin-bottom: 1em;
}
.material-icons {
  font-size: 22px;
}
.asterisk {
  color: var(--red);
}
.color-options {
  display: flex;
  gap: 10px;
}
.color-options label {
  position: relative;
  cursor: pointer;
}
.color-options input[type=radio] {
  display: none;
}
.color-options .color-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}
.color-options input[type=radio]:checked + .color-circle {
  border-color: black;
}
.error-message {
  color: var(--red);
  font-size: 12px;
  margin-top: 5px;
}
.info-message {
  color: #505253;
  font-size: 12px;
  margin-top: 5px;
}
::ng-deep .ant-switch,
nz-switch {
  flex-shrink: 0 !important;
  width: auto !important;
}
.color-circle.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
/*# sourceMappingURL=add-calendar-event.component-X46ABI5L.css.map */
