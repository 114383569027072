import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IPregnancyStatusDetailed } from '../interfaces/PregnancyStatus/IPregnancyStatusDetailed';
import { IAddPregnancyStatus } from '../interfaces/PregnancyStatus/IAddPregnancyStatus';
import { IPregnancyStatusDropdown } from '../interfaces/PregnancyStatus/IPregnancyStatusDropdown';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class PregnancyStatusService {
  public pregnancyStatusModalSub = new BehaviorSubject<boolean>(false);

  public pregnancyStatusEditSub = new BehaviorSubject<
    IPregnancyStatusDetailed | undefined
  >(undefined);

  public pregnancyStatusDataSub = new BehaviorSubject<
    IPregnancyStatusDetailed[]
  >([]);

  public pregnancyStatusLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.pregnancyStatusLoadingSub.next(true);
    this.getPregnancyStatus(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.pregnancyStatusDataSub.next(response?.data!);
        this.pregnancyStatusLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve Pregnancy Status');
        console.error('Error fetching Pregnancy Status:', err);
      },
    });
  }

  getPregnancyStatus(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IPregnancyStatusDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IPregnancyStatusDetailed[]>
      >(
        `${environment.BACKEND_URL}PregnancyStatus/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IPregnancyStatusDetailed[]>
      >(`${environment.BACKEND_URL}PregnancyStatus/${patientId}`, {
        headers: headers,
      });
    }
  }

  getDropdownData(): Observable<
    IGenericServiceResponse<IPregnancyStatusDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IPregnancyStatusDropdown>>(
      `${environment.BACKEND_URL}PregnancyStatus/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addPregnancyStatus(pregnancyStatusObj: IAddPregnancyStatus) {
    pregnancyStatusObj.visitId = this.eocService.currentVisitId();
    pregnancyStatusObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}PregnancyStatus`,
      pregnancyStatusObj,
      {
        headers: headers,
      }
    );
  }

  editPregnancyStatus(pregnancyStatusObj: IAddPregnancyStatus) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}PregnancyStatus/${pregnancyStatusObj.id}`,
      pregnancyStatusObj,
      {
        headers: headers,
      }
    );
  }

  deletePregnancyStatus(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}PregnancyStatus/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deletePregnancyStatusFromTable(pregnancyStatusId: string) {
    // REMOVE THE Pregnancy Status
    this.pregnancyStatusDataSub.next(
      this.pregnancyStatusDataSub.value?.filter(
        (mh) => mh.id?.toString() != pregnancyStatusId
      )
    );
  }
}
