/* src/app/components/ehr/admission/admission-reject-modal/admission-reject-modal.component.css */
.reject-form {
  padding: 20px;
}
.form-row {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.label-column {
  flex: 0 0 20%;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.input-column {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
}
.full-width-select {
  width: 100%;
  margin-left: 1em;
}
.text-area {
  border: 1px solid #d9d9d9;
  padding: 6px 11px;
  border-radius: 4px;
}
.optional-text {
  font-weight: 300;
  display: block;
  font-size: 12px;
  margin-top: 0.25em;
  border-radius: 1px;
}
.optional-text::selection {
  border-color: var(--color-1);
  border-radius: 1px;
}
.button-row {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
/*# sourceMappingURL=admission-reject-modal.component-UVJTQAZH.css.map */
