import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';
import { IAdmissionMedicalInfo } from 'src/app/interfaces/AdmissionPatient/AdmissionProfile/IAdmissionMedicalInfo';
import { IExternalDoctor } from 'src/app/interfaces/ExternalDoctors/IExternalDoctor';
import { ICoding, ICodingSpecialties } from 'src/app/interfaces/ICoding';
import { ExternalDoctorsComponent } from './external-doctors/external-doctors.component';
import { IExternalCymaDoctorDropdown } from 'src/app/interfaces/ExternalDoctors/IExternalCymaDoctorDropdown';
import { IExternalDoctorCyma } from 'src/app/interfaces/ExternalDoctors/IExternalDoctorCyma';

@Component({
  selector: 'app-admission-medical-info',
  templateUrl: './admission-medical-info.component.html',
  styleUrls: ['./admission-medical-info.component.css'],
})
export class AdmissionMedicalInfoComponent {
  @ViewChild(ExternalDoctorsComponent)
  externalDoctorsComp!: ExternalDoctorsComponent;
  @Input() admissionMedicalInfoData?: IAdmissionMedicalInfo;
  @Input() formSubmitted?: boolean = false;
  @Input() bloodTypesList: ICoding[] = [];
  @Input() immobilityList: NzSelectOptionInterface[] = [];
  @Input() doctors: NzSelectOptionInterface[] = [];
  @Input() placeOfBirthList: ICoding[] = [];
  @Input() closestRelativesList: ICoding[] = [];
  @Input() educationLevelList: ICoding[] = [];
  @Input() familyStatusList: ICoding[] = [];
  @Input() sourceOfIncomeList: ICoding[] = [];
  @Input() religionList: ICoding[] = [];
  @Input() isEditMode: boolean = false;
  @Input() externalDoctorsDropdownVersion: NzSelectOptionInterface[] = [];
  @Input() externalDoctorsApi: IExternalDoctor[] = [];

  @Input() externalDoctorsCymaApi: IExternalCymaDoctorDropdown[] = [];
  @Input() externalDoctorsCymaDropdownVersion: NzSelectOptionInterface[] = [];

  @Input() countriesList: ICoding[] = [];
  @Input() doctorSpecialties: ICodingSpecialties[] = [];
  @Input() admissionExternalDoctors: IExternalDoctor[] = [];
  @Input() admissionExternalDoctorsCyma: IExternalDoctorCyma[] = [];

  selectedDoctors: any[] = [];
  selectedExternalDoctors: IExternalDoctor[] = [];
  medicalInfoForm: FormGroup = this.formBuilder.group({});

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['admissionMedicalInfoData'] && this.admissionMedicalInfoData) {
      // if (!this.medicalInfoForm?.controls?.['assignedDoctors']) {
      //   console.warn('Form controls are not initialized yet. Building form...');
      //   this.buildForm();
      // }
      this.initializeForm();
    }

    if (changes['isEditMode']) {
      this.setFormControlsState();
    }
  }

  buildForm() {
    this.medicalInfoForm = this.formBuilder.group({
      assignedDoctors: [
        { value: [], disabled: !this.isEditMode },
        Validators.required,
      ],
      immobilityValue: [
        { value: null, disabled: !this.isEditMode },
        Validators.required,
      ],
      bloodTypeId: [{ value: null, disabled: !this.isEditMode }],
      height: [{ value: null, disabled: !this.isEditMode }],
      weight: [{ value: null, disabled: !this.isEditMode }],
      primaryDoctorId: [{ value: null, disabled: !this.isEditMode }],
      moh: [{ value: null, disabled: !this.isEditMode }],
    });
  }

  initializeForm() {
    this.medicalInfoForm.patchValue({
      assignedDoctors:
        this.admissionMedicalInfoData?.assignedDoctors?.map(
          (doc) => doc.doctorId
        ) || [],
      immobilityValue:
        this.admissionMedicalInfoData?.immobilityStatusId || null,
      bloodTypeId: this.admissionMedicalInfoData?.bloodTypeId || null,
      height: this.admissionMedicalInfoData?.height || null,
      weight: this.admissionMedicalInfoData?.weight || null,
      primaryDoctorId:
        this.admissionMedicalInfoData?.assignedDoctors?.find(
          (d) => d.primaryDoctor
        )?.doctorId || null,
      moh: this.admissionMedicalInfoData?.moh || null,
    });

    // Trigger change detection to update view
    this.cdr.detectChanges();
  }

  setFormControlsState() {
    if (this.isEditMode) {
      this.medicalInfoForm.enable();
    } else {
      this.medicalInfoForm.disable();
    }
  }

  onDoctorSelectionChange(selectedDoctorIds: number[]) {
    this.selectedDoctors = selectedDoctorIds.map((id) => {
      const existingDoctor =
        this.admissionMedicalInfoData?.assignedDoctors?.find(
          (doc) => doc.doctorId == id
        );
      return {
        doctorId: id,
        doctorFullName:
          this.doctors.find((doc) => doc.value == id)?.label || '',
        primaryDoctor: existingDoctor ? existingDoctor.primaryDoctor : false,
      };
    });

    this.updatePrimaryDoctor();
  }

  setPrimaryDoctor(doctorId: number) {
    this.selectedDoctors.forEach((doc) => {
      doc.primaryDoctor = doc.doctorId === doctorId;
    });
    this.medicalInfoForm.patchValue({
      primaryDoctorId: doctorId,
    });

    if (this.formSubmitted) {
      this.checkPrimaryDoctorSelection();
    }
  }

  checkPrimaryDoctorSelection() {
    const hasPrimaryDoctor = this.selectedDoctors.some(
      (doc) => doc.primaryDoctor
    );
    if (!hasPrimaryDoctor) {
      alert('Please select at least one primary doctor.');
    }
  }

  populateSelectedDoctors() {
    if (this.admissionMedicalInfoData?.assignedDoctors) {
      this.selectedDoctors = this.admissionMedicalInfoData.assignedDoctors.map(
        (doc) => ({
          doctorId: doc.doctorId,
          doctorFullName: doc.doctorFullName,
          primaryDoctor: doc.primaryDoctor,
        })
      );
    }
    this.updatePrimaryDoctor();
  }

  updatePrimaryDoctor() {
    if (this.selectedDoctors.length > 0) {
      const primaryDoctorExists = this.selectedDoctors.some(
        (doc) => doc.primaryDoctor
      );
      if (!primaryDoctorExists) {
        this.selectedDoctors[0].primaryDoctor = true;
      }
      this.medicalInfoForm.patchValue({
        primaryDoctorId: this.selectedDoctors.find((doc) => doc.primaryDoctor)
          ?.doctorId,
      });
    } else {
      this.medicalInfoForm.patchValue({
        primaryDoctorId: '',
      });
    }
  }
}
