import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { TenantSettingsService } from './services/tenantSettings.service';
import { TranslateService } from '@ngx-translate/core';
import { PatientService } from './services/patient.service';
import { IModule } from './interfaces/IModule';
import { MenuService } from './services/menu.service';
import { Store } from '@ngrx/store';
import { loadMenuSuccess } from './actions/menu.actions';
import { SwalToastService } from './services/swal.service';

export function initializeApp(
  keycloak: KeycloakService,
  authService: AuthService,
  tenantService: TenantSettingsService,
  translate: TranslateService,
  patientService: PatientService,
  menuService: MenuService,
  store: Store,
  swalToastService: SwalToastService
): () => Promise<void> {
  keycloak.keycloakEvents$.subscribe({
    next(event) {
      if (event.type == KeycloakEventType.OnTokenExpired) {
        keycloak.updateToken(20);
      }
    },
  });

  return () => {
    return keycloak
      .init({
        bearerExcludedUrls: ['/resetUsername'],
        config: environment.keycloak,
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
          enableLogging: true,
        },
        enableBearerInterceptor: true,
        bearerPrefix: 'Bearer',
      })
      .then((keycloakData) => {
        if (keycloakData) {
          return authService
            .auth()
            .toPromise()
            .then((data) => {
              authService.setPermissionsToken(data!);

              // Ensure that all asynchronous requests finish before returning
              return new Promise<void>((resolve, reject) => {
                // Make sure each service completes its request
                const menuRequest = menuService.getMenu().toPromise();
                const tenantSettingsRequest = tenantService
                  .tenantSettings()
                  .toPromise();
                const definitionsRequest = patientService
                  .getDefinitions()
                  .toPromise();

                Promise.all([
                  menuRequest,
                  tenantSettingsRequest,
                  definitionsRequest,
                ])
                  .then(
                    ([
                      menuData,
                      tenantSettingsResponse,
                      definitionsResponse,
                    ]) => {
                      if (
                        !menuData ||
                        !tenantSettingsResponse ||
                        !definitionsResponse
                      ) {
                        swalToastService.toastError(
                          'Unable to retrieve app settings'
                        );

                        console.error('Error fetching app settings!');
                        reject();
                      }
                      // Handling menu data
                      let menuList: IModule[] = [];
                      const mainMenu = menuData?.data.at(0);
                      if (mainMenu) {
                        menuList = mainMenu.childModules || [];
                        store.dispatch(loadMenuSuccess({ menu: menuList }));
                      }

                      // Handling tenant settings
                      tenantService.tenantSettingsSignal.set(
                        tenantSettingsResponse?.data!
                      );

                      // Handling patient definitions
                      patientService.definitionsSignal.set(
                        definitionsResponse?.data?.allAreaDefinitions!
                      );

                      resolve(); // All requests finished successfully
                    }
                  )
                  .catch((error) => {
                    reject(error); // If any request fails
                  });
              });
            });
        } else {
          // If the user is not logged in, skip fetching additional data
          return Promise.resolve();
        }
      });
  };
}
