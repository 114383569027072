<nz-layout>
  <nz-content
    ><ng-container>
      <app-reusable-table
        title="Pregnancy History"
        [columns]="['Status', 'Description', 'Outcome Date']"
        [rowFields]="['status', 'description', 'outcomeDate']"
        [data]="pregnancyHistory"
        [addComponent]="addComp"
        [isLoading]="isLoading"
        [deleteFun]="deleteFun"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-pgh')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
