<div
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap="20px"
  [ngClass]="{ preview: isPreview }"
  *ngIf="!isLoading"
>
  <div
    [fxFlex]="data?.length! > 0 && !isPreview ? '50' : '100'"
    fxFlex.lt-lg="100"
  >
    <nz-layout class="table-margin">
      <nz-header class="table-header">
        <!-- Table Header -->
        <div
          *ngIf="isEOC() || isInpatientView() || isPreview || data?.length! > 0"
          class="table-header-row margin-lr"
        >
          <div class="table-header-cell">
            {{ "Supporting Documents" | translate }}
          </div>
          <!-- Add Button -->
          <div class="table-header-cell margin-lrr">
            <button
              nz-button
              nzShape="round"
              (click)="openModal()"
              *ngIf="!isPreview && hasPermission('a-sqa')"
            >
              <div fxLayout="row">
                <div fxLayout="col">
                  <mat-icon class="add-icon">add_circle_outline</mat-icon>
                </div>
                <div fxLayout="col" class="add-title">
                  {{ "Add" | translate }}
                  {{ "Supporting Document" | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>

        <!-- No Data Available Message -->
        <div
          fxLayout="row"
          class="table-header-row"
          *ngIf="
            !(isEOC() || isInpatientView()) &&
            !isPreview &&
            !(data?.length! > 0)
          "
        >
          <div fxLayout="col" class="title" *ngIf="!isLoading">
            {{ "Supporting Documents" | translate }} -
            {{ "No data available" | translate }}
          </div>
        </div>
      </nz-header>
      <nz-content *ngIf="data?.length! > 0 || isLoading">
        <nz-table
          #nzTable
          nzTableLayout="fixed"
          nzShowTitle="false"
          nzSize="middle"
          nzNoResult="No Supporting Documents found"
          [nzLoading]="isLoading"
          [nzData]="data || []"
        >
          <thead>
            <tr>
              <th>
                {{ "File Type" | translate }}
                <app-tooltip fieldName="File Type" area="SupportingDocuments" />
              </th>
              <th>
                {{ "Notes" | translate }}
                <app-tooltip fieldName="Notes" area="SupportingDocuments" />
              </th>
              <th *ngIf="!isPreview" nzWidth="70px">
                {{ "Actions" | translate }}
                <app-tooltip fieldName="Actions" area="SupportingDocuments" />
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of getData()">
              <tr
                [ngClass]="{
                  'draft-entry': isEOC() || isInpatientView(),
                  'selected-preview': pdfItem?.id === item.id
                }"
                (click)="requestPDF(item)"
              >
                <td>{{ getValue("fileType", item.fileType) }}</td>
                <td>{{ getValue("notes", item.notes) }}</td>

                <td *ngIf="!isPreview">
                  <i
                    nz-icon
                    nzType="delete"
                    nzTheme="outline"
                    class="delete-btn"
                    (click)="deleteFun(item?.id!); $event.stopPropagation()"
                  ></i>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </nz-content>
    </nz-layout>
  </div>
  <div *ngIf="data?.length! > 0 && !isPreview" [fxFlex]="50" fxFlex.lt-lg="100">
    <div class="document-view">
      <div class="preview-title">
        {{ "Preview" | translate }}
        {{
          pdfItem?.filePaths?.at(0)?.path
            ? " - " + pdfItem?.filePaths?.at(0)?.path
            : ""
        }}
        <span
          *ngIf="pdfObj && !isPreviewLoading"
          nz-icon
          nzType="download"
          nzTheme="outline"
          class="download-btn"
          (click)="downloadPDF(pdfItem!)"
        ></span>
      </div>
      <div class="preview-content" fxLayoutAlign="center center">
        <div *ngIf="!pdfObj && !isPreviewLoading">
          <i
            nz-icon
            nzType="folder-view"
            nzTheme="outline"
            class="folder-view-icon"
          ></i>
          <div>{{ "Select a Document to preview!" | translate }}</div>
        </div>
        <div *ngIf="isPreviewLoading"><nz-spin nzSimple></nz-spin></div>
        <pdf-viewer
          *ngIf="pdfObj && pdfItem && !isPreviewLoading"
          class="pdf-viewer"
          [src]="pdfObj"
          [render-text]="true"
          [original-size]="false"
          [fit-to-page]="true"
          (click)="isVisible = true"
        ></pdf-viewer>
      </div>
    </div>
  </div>
</div>

<nz-modal
  *ngIf="!isLoading"
  #modalRef
  [(nzVisible)]="isVisible"
  [nzTitle]="
    ('Preview' | translate) +
    (pdfItem?.fileType ? ' - ' + pdfItem?.fileType : '')
  "
  (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel"
  nzOkText="Save"
  nzOkType="primary"
  [nzFooter]="null"
  class="custom-modal-class"
>
  <ng-container *nzModalContent>
    <pdf-viewer
      class="modal-pdf"
      [src]="pdfObj"
      [render-text]="true"
      [original-size]="false"
    ></pdf-viewer>
  </ng-container>
</nz-modal>
<div *ngComponentOutlet="addComp"></div>
<nz-spin *ngIf="isLoading" [nzSpinning]="true" nzSize="large"></nz-spin>
