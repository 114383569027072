import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from 'src/app/services/swal.service';
import { IPatient } from 'src/app/interfaces/IPatient';
import { ISearchPatient } from 'src/app/interfaces/ISearchPatient';
import { AllergyService } from 'src/app/services/allergy.service';
import { CarePlanService } from 'src/app/services/carePlan.service';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { CurrentProblemsService } from 'src/app/services/currentProblems.service';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { ImagingService } from 'src/app/services/imaging.service';
import { LaboratoryFileService } from 'src/app/services/laboratoryFile.service';
import { MedicalAlertService } from 'src/app/services/medicalAlert.service';
import { MedicalDeviceService } from 'src/app/services/medicalDevice.service';
import { MedicalHistoryService } from 'src/app/services/medicalHistory.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import { PatientService } from 'src/app/services/patient.service';
import { PregnancyHistoryService } from 'src/app/services/pregnancyHistory.service';
import { PregnancyOutcomeService } from 'src/app/services/pregnancyOutcome.service';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { ResolvedProblemsService } from 'src/app/services/resolvedProblems.service';
import { SocialHistoryService } from 'src/app/services/socialHistory.service';
import { TravelHistoryService } from 'src/app/services/travelHistory.service';
import { VaccinationService } from 'src/app/services/vaccination.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import { COLORS } from 'src/styles/colors';
import Swal from 'sweetalert2';
import { IGetVisit } from 'src/app/interfaces/IGetVisit';

@Component({
  selector: 'app-patients-goc',
  templateUrl: './patients-goc.component.html',
  styleUrls: ['./patients-goc.component.css'],
})
export class PatientsGocComponent {
  // @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  public documentID?: string;
  public firstName?: string;
  public lastName?: string;
  public dateOfBirth?: Date;

  // DUPLICATE VARIABLES FOR SEARCH FIELDS
  // SET THE VALUE ON PRESS OF BUTTON "SEARCH"
  //
  // IF WE DON'T HAVE THOSE THE SEARCH WILL GET
  // THE VALUES FROM THE SEARCH FORM WITHOUT THE
  // NEED TO PRESS THE SEARCH BUTTON
  // AND THE PAGINATION WILL BREAK
  public documentValue?: string;
  public firstNameValue?: string;
  public lastNameValue?: string;
  public dateOfBirthValue?: Date;

  public data: MatTableDataSource<IPatient> = new MatTableDataSource<IPatient>(
    []
  );

  public totalRecords: number = 0;

  public displayedColumns: string[] = [
    'documentNumber',
    'firstName',
    'lastName',
    'dateOfBirth',
    'actions',
  ];
  isLoading: boolean = false;
  isNewVisitLoading: boolean = false;

  ngAfterViewInit() {
    this.data.paginator = this.paginator;
  }

  constructor(
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly allergyService: AllergyService,
    private readonly vaccinationService: VaccinationService,
    private readonly medicalAlertService: MedicalAlertService,
    private readonly medicalHistoryService: MedicalHistoryService,
    private readonly procedureService: ProcedureService,
    private readonly medicalDeviceService: MedicalDeviceService,
    private readonly currentProblemsService: CurrentProblemsService,
    private readonly resolvedProblemsService: ResolvedProblemsService,
    private readonly pregnancyHistoryService: PregnancyHistoryService,
    private readonly pregnancyOutcomeService: PregnancyOutcomeService,
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly carePlanService: CarePlanService,
    private readonly socialHistoryService: SocialHistoryService,
    private readonly travelHistoryService: TravelHistoryService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly pastMedicationService: PastMedicationService,
    private readonly imagingService: ImagingService,
    private readonly labService: LaboratoryFileService,
    private readonly hysteroscopyFileService: HysteroscopyFileService,
    private readonly vitalSignService: VitalSignService,
    private readonly eocService: EpisodeOfCareService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  registerPatient() {
    this.router.navigate(['/ehr/register-patient']);
  }

  clearPatientsForm() {
    this.documentID = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.dateOfBirth = undefined;
    this.data = new MatTableDataSource<IPatient>([]);
    this.data.paginator = this.paginator;
  }

  // RESET DATA  WHEN ACCESSING A PATIENT
  clearDataFromServices() {
    this.allergyService.allergyDataSub.next({});
    this.vaccinationService.vaccinationDataSub.next([]);
    this.medicalAlertService.medicalAlertDataSub.next([]);
    this.medicalHistoryService.medicalHistoryDataSub.next([]);
    this.procedureService.procedureDataSub.next({});
    this.medicalDeviceService.medicalDeviceDataSub.next({});
    this.currentProblemsService.currentProblemDataSub.next({});
    this.resolvedProblemsService.resolvedProblemDataSub.next([]);
    this.pregnancyHistoryService.pregnancyHistoryDataSub.next([]);
    this.pregnancyOutcomeService.pregnancyOutcomeDataSub.next([]);
    this.pregnancyStatusService.pregnancyStatusDataSub.next([]);
    this.carePlanService.carePlanDataSub.next([]);
    this.socialHistoryService.socialHistoryDataSub.next([]);
    this.travelHistoryService.travelHistoryDataSub.next([]);
    this.currentMedicationService.currentMedicationDataSub.next({});
    this.pastMedicationService.pastMedicationDataSub.next([]);
    this.imagingService.imagingDataSub.next([]);
    this.labService.laboratoryDataSub.next([]);
    this.hysteroscopyFileService.hysteroscopyDataSub.next([]);
    this.vitalSignService.vitalSignDataSub.next([]);
  }

  onRowClicked(row: IPatient) {
    // RESET DATA  BEFORE ACCESSING A PATIENT
    this.clearDataFromServices();

    this.router.navigate(['/ehr/profile'], {
      queryParams: {
        patientID: row?.id,
      },
    });
  }

  async pageChange() {
    const patientObj: ISearchPatient = {};
    if (this?.documentValue) patientObj.documentNumber = this.documentValue;
    if (this?.firstNameValue) patientObj.firstName = this.firstNameValue;
    if (this?.lastNameValue) patientObj.lastName = this.lastNameValue;
    if (this?.dateOfBirthValue)
      patientObj.dateOfBirth = this.dateOfBirthValue!.toString();

    await this.patientService
      .getPatientsGOC(
        patientObj,
        this.paginator.pageSize,
        this.paginator.pageIndex + 1
      )
      .subscribe({
        next: (d) => {
          this.data = new MatTableDataSource(d?.data?.map((d: IPatient) => d));

          this.totalRecords = d.totalRecords || 0;
        },
        error: (err) => {},
      });
  }

  async searchPatientsForm() {
    this.paginator.pageIndex = 0;

    this.documentValue = this.documentID;
    this.firstNameValue = this.firstName;
    this.lastNameValue = this.lastName;
    this.dateOfBirthValue = this.dateOfBirth;

    this.pageChange();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  // TO REMOVE LATER
  ngOnInit() {
    // this.searchPatientsForm();
  }

  // handleView(item: IPatient) {
  //   if (item?.id) {
  //     // REQUEST NEW VISIT AND ASSIGN VISIT ID TO EOC SERVICE THEN NAVIGATE
  //     // this.eocService.currentVisitId.set(res?.data?.visitId?.toString());
  //     // this.router.navigate(['/inPatientView']);
  //     Swal.fire({
  //       title: `<span style="font-size: 20px;">${this.translate.instant(
  //         'Create New Visit'
  //       )}</span> <span style="font-size: 16px; font-weight: normal"></span>`,
  //       html: `${this.translate.instant(
  //         'Are you sure that you want to create a new visit for'
  //       )} <span style="font-weight: bold;">${
  //         item?.firstName + ' ' + item?.lastName
  //       }</span> ?`,
  //       showDenyButton: false,
  //       showCancelButton: true,
  //       cancelButtonText: `${this.translate.instant('Cancel')}`,
  //       confirmButtonText: `${this.translate.instant('Ok')}`,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         this.eocService.createNewVisitNoEOC(item?.id).subscribe({
  //           next: (res) => {
  //             res?.data?.episodeOfCareId?.toString() &&
  //               this.eocService.currentEocId.set(
  //                 res?.data?.episodeOfCareId?.toString()
  //               );

  //             res?.data?.visitId?.toString() &&
  //               this.eocService.currentVisitId.set(
  //                 res?.data?.visitId?.toString()
  //               );

  //             this.patientService.patientIDsignal.set(item?.id?.toString()!);

  //             // RESET DATA  BEFORE ACCESSING A PATIENT
  //             this.clearDataFromServices();

  //             this.router.navigate([
  //               '/ehr/inPatientView',
  //               this.patientService.patientIDsignal(),
  //               this.eocService.currentEocId(),
  //               this.eocService.currentVisitId(),
  //             ]);

  //             this.swalToastService.toastSuccess(
  //               'New Visit created successfully'
  //             );
  //           },

  //           error: (err) => {
  //             this.swalToastService.toastError('Unable to create New Visit');
  //           },
  //         });
  //       }
  //     });
  //   }
  // }
  handleView(item: IPatient) {
    this.isLoading = true;
    if (item?.episodeCareId) {
      let existingVisits: IGetVisit[] = [];
      this.eocService.getDraftVisitFromEOC(item.episodeCareId, true).subscribe({
        next: (res) => {
          existingVisits = res?.data;

          // REQUEST NEW VISIT AND ASSIGN VISIT ID TO EOC SERVICE THEN NAVIGATE
          // this.eocService.currentVisitId.set(res?.data?.visitId?.toString());
          // this.router.navigate(['/inPatientView']);
          Swal.fire({
            title: `<span style="font-size: 20px;">${this.translate.instant(
              'Visit'
            )}</span> <span style="font-size: 16px; font-weight: normal">(${this.translate.instant(
              'In Patient'
            )})</span>`,
            html: `
            <div>
              <input type="radio" id="newVisit" name="visitOption" value="new" checked>
              <label for="newVisit">${this.translate.instant(
                'Create New Visit'
              )}</label>
            </div>
          
            ${
              existingVisits?.length
                ? `
                <div style="padding-top: 15px">
                  <input type="radio" id="existingVisit" name="visitOption" value="existing">
                  <label for="existingVisit">${this.translate.instant(
                    'Select Draft Visit'
                  )}</label>
                </div>
                <div id="visitDropdownContainer" style="margin-top: 10px;">
                  <select id="visitSelect" style="width: 100%; padding: 5px;">
                    <option value="">${this.translate.instant(
                      'Select Draft Visit'
                    )}</option>
                    ${existingVisits
                      .map(
                        (visit) =>
                          `<option value="${visit.id}">${visit.date}${
                            visit?.notes ? ' | ' + visit.notes : ''
                          }</option>`
                      )
                      .join('')}
                  </select>
                </div>
              `
                : ''
            }
          `,
            didOpen: () => {
              const dropdown = document.getElementById(
                'visitSelect'
              ) as HTMLSelectElement;
              const existingRadio = document.getElementById(
                'existingVisit'
              ) as HTMLInputElement;
              const newRadio = document.getElementById(
                'newVisit'
              ) as HTMLInputElement;

              // When selecting a visit from the dropdown, auto-check the existing radio
              if (dropdown && existingRadio) {
                dropdown.addEventListener('change', () => {
                  if (dropdown.value && !existingRadio.checked) {
                    existingRadio.checked = true;
                  }
                });
              }

              // When reselecting "Create New Visit", reset dropdown selection
              if (dropdown && newRadio) {
                newRadio.addEventListener('change', () => {
                  if (newRadio.checked) {
                    dropdown.value = '';
                  }
                });
              }
            },

            preConfirm: () => {
              const selectedOption = document.querySelector(
                'input[name="visitOption"]:checked'
              ) as HTMLInputElement;
              const selectedVisitId = (
                document.getElementById('visitSelect') as HTMLSelectElement
              )?.value;

              if (selectedOption.value === 'existing' && !selectedVisitId) {
                Swal.showValidationMessage(
                  this.translate.instant('Select Draft Visit')
                );
                return false;
              }

              if (selectedOption.value === 'new' && selectedVisitId) {
                Swal.showValidationMessage(
                  this.translate.instant('Review your selection')
                );
                return false;
              }

              return {
                selectedOption: selectedOption.value,
                selectedVisitId,
              };
            },
            showDenyButton: false,
            showCancelButton: true,
            cancelButtonText: `${this.translate.instant('Cancel')}`,
            confirmButtonText: `${this.translate.instant('Ok')}`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.isNewVisitLoading = true;

              const selectedOption = document.querySelector(
                'input[name="visitOption"]:checked'
              ) as HTMLInputElement;
              const selectedVisitId = (
                document.getElementById('visitSelect') as HTMLSelectElement
              )?.value;

              if (selectedOption.value == 'existing') {
                item.episodeCareId &&
                  this.eocService.currentEocId.set(
                    item.episodeCareId.toString()
                  );

                selectedVisitId &&
                  this.eocService.currentVisitId.set(selectedVisitId);

                this.isLoading = false;
                this.isNewVisitLoading = false;
                this.patientService.patientIDsignal.set(item.id!.toString());

                // RESET DATA  BEFORE ACCESSING A PATIENT
                this.clearDataFromServices();

                this.router.navigate([
                  '/ehr/inPatientView',
                  this.patientService.patientIDsignal(),
                  this.eocService.currentEocId(),
                  this.eocService.currentVisitId(),
                ]);
              } else {
                this.eocService.createNewVisit(item.episodeCareId!).subscribe({
                  next: (res) => {
                    res?.data?.episodeOfCareId?.toString() &&
                      this.eocService.currentEocId.set(
                        res?.data?.episodeOfCareId?.toString()
                      );

                    res?.data?.visitId?.toString() &&
                      this.eocService.currentVisitId.set(
                        res?.data?.visitId?.toString()
                      );

                    this.isLoading = false;
                    this.isNewVisitLoading = false;
                    this.patientService.patientIDsignal.set(
                      item.id!.toString()
                    );

                    // RESET DATA  BEFORE ACCESSING A PATIENT
                    this.clearDataFromServices();

                    this.router.navigate([
                      '/ehr/inPatientView',
                      this.patientService.patientIDsignal(),
                      this.eocService.currentEocId(),
                      this.eocService.currentVisitId(),
                    ]);

                    this.swalToastService.toastSuccess(
                      'New Visit created successfully'
                    );
                  },
                  error: (err) => {
                    this.swalToastService.toastError(
                      'Unable to create New Visit'
                    );
                  },
                });
              }
            } else {
              this.isLoading = false;
              this.isNewVisitLoading = false;
            }
          });
        },
        error: (err) => {
          this.swalToastService.toastError('Something went wrong');
        },
      });
    }
  }
}
