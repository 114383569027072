<nz-layout>
    <nz-header>
        <span class="section-header">{{'Tracheostomy'|translate}}</span>
    </nz-header>
    <nz-content>
        <ng-container *ngFor="let cm of submenu">
            <app-ct-parameters *ngIf="cm.path==componentPaths.ctParameters" [id]="cm.path" />
            <app-tube-characteristics *ngIf="cm.path==componentPaths.tubeCharacteristics" [id]="cm.path" />
        </ng-container>
    </nz-content>
</nz-layout>