import { Component } from '@angular/core';
import { NeckAddEditComponent } from './neck-add-edit/neck-add-edit.component';
import { Subject, takeUntil } from 'rxjs';
import { INeckDetailed } from 'src/app/interfaces/Neck/INeckDetailed';
import { NeckService } from 'src/app/services/neck.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-neck',
  templateUrl: './neck.component.html',
  styleUrl: './neck.component.css',
})
export class NeckComponent {
  public addComp = NeckAddEditComponent;

  private destroy$ = new Subject<void>();

  neckData?: INeckDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly neckService: NeckService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {}

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  openModalFun(item?: INeckDetailed) {
    this.neckService.neckModalSub.next(true);
    this.neckService.neckEditSub.next(item);
  }

  deleteFun(neckId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Neck')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.neckService.deleteNeck(neckId.toString()).subscribe({
          next: (d) => {
            this.swalToastService.toastSuccess(
              'Neck record deleted successfully'
            );
            this.neckService.deleteNeckFromTable(neckId.toString());
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to delete Neck record');
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Neck record is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
  ngOnInit() {
    this.neckService.neckLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.neckService.neckDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: INeckDetailed[]) => {
        this.neckData = data;
      });

    this.neckService.getData();
  }
}
