/* src/app/components/ehr/patients/components/medication-summary-section/medication-summary-section.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
}
.add-medication-btn {
  margin-right: 3em;
}
/*# sourceMappingURL=medication-summary-section.component-XSVRILUL.css.map */
