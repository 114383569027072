import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IAddHysteroscopyFile } from '../interfaces/HysteroscopyFile/IAddHysteroscopyFile';
import { IHysteroscopyFileDropdown } from '../interfaces/HysteroscopyFile/IHysteroscopyFileDropdown';
import { IHysteroscopyFileDetailed } from '../interfaces/HysteroscopyFile/IHysteroscopyFileDetailed';
import { saveAs } from 'file-saver-es';
import { IAddHysteroscopyVideo } from '../interfaces/HysteroscopyFile/IAddHysteroscopyVideo';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class HysteroscopyFileService {
  // BehaviorSubject to track the ongoing examination status
  private ongoingHysteroscopyExaminationSub = new BehaviorSubject<boolean>(
    false
  );

  public hysteroscopyModalSub = new BehaviorSubject<boolean>(false);

  public hysteroscopyEditSub = new BehaviorSubject<
    IHysteroscopyFileDetailed | undefined
  >(undefined);

  public hysteroscopyDataSub = new BehaviorSubject<IHysteroscopyFileDetailed[]>(
    []
  );

  public hysteroscopyLoadingSub = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  getExaminationStatus(): Observable<boolean> {
    return this.ongoingHysteroscopyExaminationSub.asObservable();
  }
  setExaminationStatus(status: boolean): void {
    this.ongoingHysteroscopyExaminationSub.next(status);
  }

  async getData() {
    this.hysteroscopyLoadingSub.next(true);
    await this.getHysteroscopy(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.hysteroscopyDataSub.next(response?.data!);
        this.hysteroscopyLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError(
          'Unable to retrieve Hysteroscopy data'
        );
        console.error('Error fetching procedures:', err);
      },
    });
  }

  getHysteroscopy(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IHysteroscopyFileDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IHysteroscopyFileDetailed[]>
      >(
        `${environment.BACKEND_URL}HysteroscopyFile/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IHysteroscopyFileDetailed[]>
      >(`${environment.BACKEND_URL}HysteroscopyFile/${patientId}`, {
        headers: headers,
      });
    }
  }

  getImage(imageId: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get(
      `${environment.BACKEND_URL}HysteroscopyFile/DownloadImage/${imageId}`,
      {
        headers: headers,
        responseType: 'blob',
      }
    );
  }

  downloadFile(item: IHysteroscopyFileDetailed): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .get(
        `${
          environment.BACKEND_URL
        }HysteroscopyFile/DownloadImage/${item?.id?.toString()!}`,
        {
          headers: headers,
          observe: 'response',
          responseType: 'blob',
        }
      )
      .subscribe(
        (response: HttpResponse<Blob>) => {
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          let fileName = 'downloadedFile';
          let fileExtension = '';

          if (contentDisposition) {
            const matches = contentDisposition.match(
              /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            );
            if (matches && matches[1]) {
              fileName = matches[1].replace(/['"]/g, ''); // Remove quotes if any
              fileExtension = fileName.split('.').pop()!;
            }
          }

          const file = new Blob([response.body!], {
            type: response.body!.type,
          });
          saveAs(file, `${item.anatomicalPosition}.${fileExtension}`);
        },
        (error) => {
          this.swalToastService.toastError('Unable to download file');
          console.error('Error downloading file:', error);
        }
      );
  }

  getHysteroscopyDropdown(): Observable<
    IGenericServiceResponse<IHysteroscopyFileDropdown>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IHysteroscopyFileDropdown>>(
      `${environment.BACKEND_URL}HysteroscopyFile/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  addHysteroscopy(hysteroscopyObj: IAddHysteroscopyFile) {
    hysteroscopyObj.visitId = this.eocService.currentVisitId();
    hysteroscopyObj.patientId = this.patientService.patientIDsignal();

    const formData = new FormData();
    formData.append(
      'AnatomicalPositionId',
      hysteroscopyObj?.anatomicalPositionId?.toString()!
    );
    formData.append('Date', hysteroscopyObj?.date!);
    formData.append('Description', hysteroscopyObj?.description!);
    formData.append('file', hysteroscopyObj?.file!);

    formData.append('PatientId', hysteroscopyObj?.patientId?.toString()!);
    formData.append('VisitId', hysteroscopyObj?.visitId?.toString()!);

    // const headers = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data; boundary=----',
    // });

    return this._http.post(
      `${environment.BACKEND_URL}HysteroscopyFile`,
      formData
    );
  }

  editHysteroscopy(hysteroscopyObj: IAddHysteroscopyFile) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}HysteroscopyFile/${hysteroscopyObj.id}`,
      hysteroscopyObj,
      {
        headers: headers,
      }
    );
  }

  deleteHysteroscopy(hysteroscopyId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}HysteroscopyFile/${hysteroscopyId}`,
      {
        headers: headers,
      }
    );
  }

  deleteHysteroscopyFromTable(hysteroscopyId: string) {
    // REMOVE THE Hysteroscopy
    this.hysteroscopyDataSub.next(
      this.hysteroscopyDataSub.value?.filter(
        (rp) => rp.id?.toString() != hysteroscopyId
      )
    );
  }

  navigatePositions(
    currentAnatomicalPosition: number,
    direction: number
  ): Observable<IGenericServiceResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IHysteroscopyFileDropdown>>(
      `${environment.BACKEND_URL}HysteroscopyFile/NavigatePositions/${currentAnatomicalPosition}/${direction}`,
      {
        headers: headers,
      }
    );
  }
  // startRecording(patientId: number, category: string): Observable<any> {
  //   return this._http.post(`${environment.BACKEND_URL}start_recording`, {
  //     patientId,
  //     category,
  //   });
  // }

  // stopRecording(
  //   patientId: string,
  //   category: number,
  //   recordedFrames: FormData
  // ): Observable<any> {
  //   recordedFrames.append('patientId', patientId);
  //   recordedFrames.append('category', category.toString());

  //   return this._http.post(
  //     `${environment.BACKEND_URL}SaveHysteroscopyVideo`,
  //     recordedFrames
  //   );
  // }

  addHysteroscopyVideo(
    hysteroscopyVideoObj: IAddHysteroscopyVideo
  ): Observable<any> {
    const formData = new FormData();
    formData.append(
      'AnatomicalPositionId',
      hysteroscopyVideoObj.anatomicalPositionId!.toString()
    );
    formData.append('Date', hysteroscopyVideoObj.date!);
    formData.append('Description', hysteroscopyVideoObj.description!);
    formData.append('PatientId', this.patientService.patientIDsignal());
    formData.append('VisitId', this.eocService.currentVisitId());
    formData.append('File', hysteroscopyVideoObj.videoBlob!);
    return this._http.post(
      `${environment.BACKEND_URL}HysteroscopyFile/SaveHysteroscopyVideo`,
      formData
    );
  }
}
