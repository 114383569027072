<div fxLayout="column">
  <app-header [menuList]="menuList" [activeButton]="activeButton"></app-header>
  <div fxLayout="column" fxLayoutAlign="start center" fxFlex class="ehr-body" [ngStyle]="
      ongoingHysteroscopyExamination
        ? { 'margin-top': '0px' }
        : { 'margin-top': '66px' }
    ">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>