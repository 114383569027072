import { Component } from '@angular/core';
import { WeightAddEditComponent } from './weight-add-edit/weight-add-edit.component';
import { Subject, takeUntil } from 'rxjs';
import { IWeightDetailed } from 'src/app/interfaces/Weight/IWeightDetailed';
import { WeightService } from 'src/app/services/weight.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-weight',
  templateUrl: './weight.component.html',
  styleUrl: './weight.component.css',
})
export class WeightComponent {
  public addComp = WeightAddEditComponent;

  private destroy$ = new Subject<void>();

  weightData?: IWeightDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly weightService: WeightService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {}

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  openModalFun(item?: IWeightDetailed) {
    this.weightService.weightModalSub.next(true);
    this.weightService.weightEditSub.next(item);
  }

  deleteFun(weightId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder1'
      )} ${this.translate.instant('Weight')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.weightService.deleteWeight(weightId.toString()).subscribe({
          next: (d) => {
            this.swalToastService.toastSuccess(
              'Weight record deleted successfully'
            );
            this.weightService.deleteWeightFromTable(weightId.toString());
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to delete Weight record');
          },
        });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Weight record is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
  ngOnInit() {
    this.weightService.weightLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.weightService.weightDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IWeightDetailed[]) => {
        this.weightData = data;
      });

    this.weightService.getData();
  }
}
