/* src/app/components/smart-health-links/smart-health-links.component.css */
.left-half {
  min-height: 100vh;
}
.left-image {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
}
@media only screen and (max-width: 1279.99px) {
  .left-image {
    width: 100%;
    max-height: 400px;
    min-height: 400px;
  }
  .left-half {
    min-height: 400px;
  }
}
.logo {
  max-width: max(20%, 300px);
  margin-bottom: 0em;
}
.login-text {
  font-size: 16px;
  font-weight: 600;
}
.patient-text {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0.5em;
}
.error-text {
  color: var(--red);
  font-size: 14px;
}
.username {
  margin-top: 1em;
  width: 100%;
  border-radius: 5px;
  padding: 14px;
  border: 1px solid #ccc;
  min-height: 52px;
}
.password {
  padding-right: 30px;
  margin-top: 1em;
  width: 100%;
  border-radius: 5px;
  padding: 14px;
  border: 1px solid #ccc;
  min-height: 52px;
}
.login-btn {
  margin-top: 1em;
  background-color: #317f88;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-family: inherit;
  min-height: 52px;
}
.login-btn-disabled {
  margin-top: 1em;
  background-color: #989898;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: not-allowed;
  width: 100%;
  font-size: 15px;
  font-family: inherit;
  min-height: 52px;
}
.remember-me {
  margin-left: 5px;
}
.forgot-line {
  margin-top: 1em;
  margin-bottom: 3em;
}
.password-input-container {
  position: relative;
  width: 100%;
}
.password {
  width: 100%;
  padding-right: 35px;
  box-sizing: border-box;
}
.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.fa {
  position: absolute;
  right: 10px;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
}
.full-screen-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.full-screen-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.background-image {
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.content-container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}
.content-column {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  width: max(20%, 300px);
}
/*# sourceMappingURL=smart-health-links.component-YOPGYP5J.css.map */
