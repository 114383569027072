/* src/app/components/ehr/workspace/my-patients/custom-table/custom-table.component.css */
nz-header {
  height: 100%;
}
::ng-deep nz-switch {
  margin-right: 0.5em;
}
.my-patients-table {
  background: #f8f8f8;
  margin-bottom: 2em;
}
.draft-entry td:first-child {
  border-left: 2px solid var(--color-1);
}
.deleted-draft-entry td:first-child {
  border-left: 2px solid #dd6153;
}
.edit-btn {
  margin-left: 1.5em;
  cursor: pointer;
}
.expandable-row {
  cursor: pointer;
}
/*# sourceMappingURL=custom-table.component-LIQXYETD.css.map */
