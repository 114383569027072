<nz-layout>
  <nz-header class="section-header">
    {{ "Initial Assessments" | translate }}
  </nz-header>
  <nz-content>
    <!-- <app-social-history-questonaire /> -->

    <ng-container *ngFor="let cm of submenu">
      <app-dynamic-questionnaire
        *ngIf="cm.path == componentPaths.socialQuestionnaireAmen"
        [id]="cm.path"
        (QuestionnaireLoadingStatus)="isLoading = $event"
      />
      <app-supporting-documents
        *ngIf="cm.path == componentPaths.supportingDocumentsAmen"
        [id]="cm.path"
        [isLoading]="isLoading"
        [pagination]="false"
      />
    </ng-container>
  </nz-content>
</nz-layout>
