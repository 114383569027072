/* src/app/components/ehr/admission/admission-profile/admission-medical-info/admission-medical-info.component.css */
.add-patient-body {
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}
::ng-deep .mat-vertical-content {
  padding: 15px 24px 24px 24px !important;
}
.add-patient-row {
  margin-bottom: 1.5em;
}
.add-patient-col {
  margin-left: 1em;
  margin-right: 2em;
  height: 35px !important;
}
.add-patient-profile {
  margin-left: 1em;
  margin-right: 1em;
  height: 100px !important;
}
.add-patient-input {
  width: 100%;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
.add-patient-inputZorro {
  width: 100%;
  color: #c2c2c2 !important;
}
.immobility-select {
  width: fit-content !important;
  color: #c2c2c2 !important;
}
nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
.ant-select-single {
  font-family: "Inter", sans-serif !important;
  color: black !important;
}
select.ng-valid.ng-touched {
  color: black;
}
input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}
::placeholder {
  color: #c2c2c2;
}
.add-patient-button {
  height: 100%;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: var(--color-0) !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-patient-picture-btn {
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: var(--color-0) !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-patient-button:hover {
  cursor: pointer;
}
select.ng-invalid.ng-touched {
  border: red 1px solid;
  border-radius: 2px;
}
.submitted .ng-invalid {
  border: red 1px solid;
  border-radius: 2px;
}
.selected-doctors {
  margin-left: 1em;
}
.radio-button-list label {
  display: block;
  padding: 5px 0;
}
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.radio-label input {
  margin-right: 8px;
}
input[type=radio] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid #c2c2c2;
  outline: none;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
}
input[type=radio]:checked {
  background-color: var(--color-1);
  border: 2px solid var(--color-1);
}
input[type=radio]:checked::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}
.assigned-doctors-title {
  margin-left: 1em;
}
.not-allowed:hover {
  cursor: not-allowed;
}
.layout {
  margin-left: 1em;
  margin-right: 1em;
}
.h100i {
  height: 100% !important;
}
.label-row {
  width: 100%;
}
.hio {
  margin-left: 1em;
  cursor: pointer;
}
/*# sourceMappingURL=admission-medical-info.component-FC3SSGQO.css.map */
