import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  constructor(private authService: AuthService) {}

  can(permission: string): boolean {
    return this.authService.hasPermission(permission);
  }

  all(): string[] {
    return this.authService.getPermissions();
  }
}
