import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { COLORS } from 'src/styles/colors';
import {
  admissionDateValidator,
  approvalDateValidator,
  dischargeDateValidator,
  dischargeOrRejectValidator,
  editDateValidator,
  pendingDateValidator,
  rejectionDateValidator,
  requiredIfAllNextFieldsEmpty,
  waitingListDateValidator,
} from './admission-timeline-history.validators';
import { TranslateService } from '@ngx-translate/core';
import { ITimelineDetailed } from 'src/app/interfaces/Timeline/ITimelineDetailed';
import { ITimelineDropdown } from 'src/app/interfaces/Timeline/ITimelineDropdown';
import { TimelineService } from 'src/app/services/timeline.service';
import { forkJoin } from 'rxjs';
import { IEditTimelineItem } from 'src/app/interfaces/Timeline/IEditTimeline';
import { IAddTimeline } from 'src/app/interfaces/Timeline/IAddTimeline';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-admission-timeline-history',
  templateUrl: './admission-timeline-history.component.html',
  styleUrl: './admission-timeline-history.component.css',
})
export class AdmissionTimelineHistoryComponent {
  moment = moment;

  @Input() patientId: string = '';

  @Output() isVisibleChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  timelineEditForm: FormGroup = new FormGroup({});
  editTimeline: boolean = false;

  timelineAddForm: FormGroup = new FormGroup({});
  addTimeline: boolean = false;

  timelineSubmitted: boolean = false;
  isLoading: boolean = false;
  isAddLoading: boolean = false;

  addErrorMessage?: string;

  data?: ITimelineDetailed;
  actionsList?: ITimelineDropdown;

  constructor(
    private readonly formBuilder: FormBuilder,
    public readonly translate: TranslateService,
    private readonly timelineService: TimelineService,
    private readonly swalToastService: SwalToastService
  ) {}

  getColor(statusId: number): string {
    switch (statusId) {
      case 1:
        return 'blue';
      case 2:
        return COLORS.approved;
      case 3:
        return COLORS.waitinglist;
      case 4:
        return COLORS.pending;
      case 5:
        return COLORS.rejected;
      case 6:
        return COLORS.admitted;
      case 7:
        return COLORS.discharged;
      default:
        return 'blue';
    }
  }

  formatDate(date?: string) {
    if (date) {
      return moment(date).format('YYYY-MM-DD h:mm a');
    }
    return '';
  }

  openEditForm(date: string, action: number, id: number) {
    this.timelineSubmitted = false;

    if (date && action) {
      this.timelineEditForm.patchValue({
        actionDate: date,
      });
    }

    this.timelineEditForm.patchValue({
      maxDate: this.getCurrentDate(),
    });

    if (id) {
      this.timelineEditForm.patchValue({
        actionId: id,
      });

      this.data?.patientCreationDate &&
        this.timelineEditForm.patchValue({
          minDate: this.data?.patientCreationDate,
        });

      const history =
        this.data?.historicalAccommodationDataItems.flatMap(
          (d) => d.accommodationDataItems
        ) || [];
      const index = history.findIndex((item) => item.id === id);
      const minDate = index > 0 ? history?.at(index - 1)?.statusDate : null;

      if (minDate) {
        let date = new Date(minDate);
        date.setMinutes(date.getMinutes() + 1);

        this.timelineEditForm.patchValue({
          minDate: moment(date).format().slice(0, 16),
        });
      }

      const maxDate = index > 0 ? history?.at(index + 1)?.statusDate : null;

      if (maxDate) {
        let date = new Date(maxDate);
        date.setMinutes(date.getMinutes() - 1);

        this.timelineEditForm.patchValue({
          maxDate: moment(date).format().slice(0, 16),
        });
      }
    }

    this.editTimeline = !this.editTimeline;
  }

  openCreateForm(date: string) {
    this.timelineSubmitted = false;

    if (date) {
      this.timelineEditForm.patchValue({
        actionDate: date,
      });
    }

    const historicalItems =
      this.data?.historicalAccommodationDataItems.flatMap(
        (hi) => hi.accommodationDataItems
      ) || [];
    const systemItems =
      this.data?.systemAccommodationDataItems.flatMap(
        (sa) => sa.accommodationDataItems
      ) || [];
    const allItems = [...historicalItems, ...systemItems];
    const dates = allItems
      .map((item) => new Date(item.statusDate))
      .filter((date) => !isNaN(date.getTime()));

    if (dates.length > 0) {
      const minDate = new Date(
        Math.min(...dates.map((date) => date.getTime()))
      );

      minDate.setMinutes(minDate.getMinutes() - 1);

      this.timelineEditForm.patchValue({
        maxDate: moment(minDate).format().slice(0, 16),
      });
    } else {
      this.timelineEditForm.patchValue({
        minDate: null,
        maxDate: this.getCurrentDate(),
      });
    }

    this.editTimeline = !this.editTimeline;
  }

  openAddForm() {
    this.timelineSubmitted = false;

    this.data?.patientCreationDate &&
      this.timelineAddForm.patchValue({
        minDate: this.getAdjustedDate(this.data?.patientCreationDate, 1),
      });

    const systemItems =
      this.data?.systemAccommodationDataItems.flatMap(
        (sa) => sa.accommodationDataItems
      ) || [];
    const dates = systemItems
      .map((item) => new Date(item.statusDate))
      .filter((date) => !isNaN(date.getTime()));

    if (dates.length > 0) {
      const minDate = new Date(
        Math.min(...dates.map((date) => date.getTime()))
      );

      minDate.setMinutes(minDate.getMinutes() - 1);

      this.timelineAddForm.patchValue({
        maxDate: moment(minDate).format().slice(0, 16),
      });
    } else {
      this.timelineAddForm.patchValue({
        minDate: null,
        maxDate: this.getCurrentDate(),
      });
    }

    this.addTimeline = !this.addTimeline;
  }

  handleEditCancel() {
    this.timelineEditForm?.reset();
    this.editTimeline = false;
  }

  handleAddCancel() {
    this.timelineAddForm?.reset();
    this.addTimeline = false;
  }

  submitEditForm() {
    this.isLoading = true;
    this.timelineSubmitted = true;

    if (
      !this.timelineEditForm.get('actionId')?.value &&
      this.timelineEditForm.get('actionDate')?.value
    ) {
      this.timelineService
        .updateCreationDate(
          this.patientId,
          moment(this.timelineEditForm.get('actionDate')?.value).utc().format()
        )
        .subscribe({
          next: () => {
            this.swalToastService.toastSuccess('Timeline edited successfully');
            this.isLoading = false;
            this.editTimeline = false;
            this.closeModal();
          },
          error: () => {
            this.isLoading = false;
            this.editTimeline = false;
          },
        });
    } else if (
      this.timelineEditForm.get('actionId')?.value &&
      this.timelineEditForm.get('actionDate')?.value
    ) {
      const editObj: IEditTimelineItem = {
        historyId: this.timelineEditForm.get('actionId')?.value,
        date: moment(this.timelineEditForm.get('actionDate')?.value)
          .utc()
          .format(),
      };
      this.timelineService.editTimelineItem(editObj, this.patientId).subscribe({
        next: () => {
          this.swalToastService.toastSuccess('Timeline edited successfully');
          this.isLoading = false;
          this.editTimeline = false;
          this.closeModal();
        },
        error: () => {
          this.isLoading = false;
          this.editTimeline = false;
        },
      });
    }
  }

  submitAddForm() {
    this.timelineAddForm.updateValueAndValidity();

    Object.keys(this.timelineAddForm.controls).forEach((controlName) => {
      const control = this.timelineAddForm.get(controlName);
      control?.updateValueAndValidity();
    });

    if (!this.timelineAddForm.invalid) {
      this.addErrorMessage = undefined;
      this.isAddLoading = true;
      this.timelineSubmitted = true;

      let addObj: IAddTimeline;

      if (this.timelineAddForm.get('rejectDate')?.value) {
        addObj = {
          pendingDate: moment(this.timelineAddForm.get('pendingDate')?.value)
            .utc()
            .format(),
          waitingListDate: this.timelineAddForm.get('waitingListDate')?.value
            ? moment(this.timelineAddForm.get('waitingListDate')?.value)
                .utc()
                .format()
            : undefined,
          rejectDate: moment(this.timelineAddForm.get('rejectDate')?.value)
            .utc()
            .format(),
        };
      } else {
        addObj = {
          pendingDate: moment(this.timelineAddForm.get('pendingDate')?.value)
            .utc()
            .format(),
          waitingListDate: moment(
            this.timelineAddForm.get('waitingListDate')?.value
          )
            .utc()
            .format(),
          approveDate: moment(this.timelineAddForm.get('approveDate')?.value)
            .utc()
            .format(),
          admitDate: moment(this.timelineAddForm.get('admissionDate')?.value)
            .utc()
            .format(),
          dischargeDate: moment(
            this.timelineAddForm.get('dischargeDate')?.value
          )
            .utc()
            .format(),
        };
      }

      this.timelineService.addTimeline(addObj, this.patientId).subscribe({
        next: (d) => {
          this.swalToastService.toastSuccess(
            'Timeline History addded successfully'
          );
          this.isAddLoading = false;
          this.closeModal();
        },
        error: (err) => {
          this.swalToastService.toastError('Unable to add Timeline');
          this.isAddLoading = false;
        },
      });

      // setTimeout(() => {
      //   this.isLoading = false;
      //   this.addTimeline = false;
      // }, 2000);
    } else {
      this.addErrorMessage = this.translate.instant(
        'Please fill in all the fields.'
      );
    }
  }

  deleteGroup(groupId: string) {
    this.isLoading = true;
    this.timelineService.deleteGroup(groupId).subscribe({
      next: (d) => {
        this.swalToastService.toastSuccess(
          'Timeline Group deleted successfully'
        );
        this.isLoading = false;
        this.closeModal();
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to delete Timeline Group');
        this.isLoading = false;
      },
    });
  }

  closeModal() {
    this.isVisibleChange.emit(false);
  }

  getCurrentDate() {
    return moment().format().slice(0, 16);
  }

  getAdjustedDate(date: string, minutes: number): string | null {
    if (!date) return null;
    const adjustedDate = new Date(date);
    adjustedDate.setMinutes(adjustedDate.getMinutes() + minutes);
    return adjustedDate.toISOString().slice(0, 16); // Format for datetime-local input
  }

  getStatusName(actionId: number) {
    return (
      this.actionsList?.admissionStatuses.find((s) => s.id === actionId)
        ?.name || ''
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.addErrorMessage = undefined;

    this.timelineEditForm = this.formBuilder.group({
      actionId: [undefined],
      actionDate: [undefined, [Validators.required, editDateValidator]],
      minDate: [undefined],
      maxDate: [undefined],
    });

    this.timelineAddForm = this.formBuilder.group(
      {
        minDate: [undefined],
        maxDate: [undefined],
        pendingDate: [undefined, [Validators.required, pendingDateValidator]],
        waitingListDate: [
          undefined,
          [
            waitingListDateValidator,
            requiredIfAllNextFieldsEmpty([
              'approveDate',
              'admissionDate',
              'dischargeDate',
              'rejectDate',
            ]),
          ],
        ],
        approveDate: [
          undefined,
          [
            approvalDateValidator,
            requiredIfAllNextFieldsEmpty([
              'admissionDate',
              'dischargeDate',
              'rejectDate',
            ]),
          ],
        ],
        admissionDate: [
          undefined,
          [
            admissionDateValidator,
            requiredIfAllNextFieldsEmpty(['dischargeDate', 'rejectDate']),
          ],
        ],
        dischargeDate: [undefined, [dischargeDateValidator]],
        rejectDate: [undefined, [rejectionDateValidator]],
      },
      { validators: dischargeOrRejectValidator() }
    );

    this.timelineAddForm.get('pendingDate')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.timelineAddForm
          .get('pendingDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineAddForm
          .get('waitingListDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineAddForm
          .get('approveDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineAddForm
          .get('admissionDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineAddForm
          .get('rejectDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineAddForm
          .get('dischargeDate')
          ?.setValue(null, { emitEvent: false });
      }
    });

    this.timelineAddForm
      .get('waitingListDate')
      ?.valueChanges.subscribe((value) => {
        if (!value) {
          this.timelineAddForm
            .get('waitingListDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineAddForm
            .get('approveDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineAddForm
            .get('admissionDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineAddForm
            .get('dischargeDate')
            ?.setValue(null, { emitEvent: false });
        }
      });

    this.timelineAddForm.get('approveDate')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.timelineAddForm
          .get('approveDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineAddForm
          .get('admissionDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineAddForm
          .get('dischargeDate')
          ?.setValue(null, { emitEvent: false });
      } else {
        this.timelineAddForm
          .get('rejectDate')
          ?.setValue(null, { emitEvent: false });
      }
    });

    this.timelineAddForm
      .get('admissionDate')
      ?.valueChanges.subscribe((value) => {
        if (!value) {
          this.timelineAddForm
            .get('admissionDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineAddForm
            .get('dischargeDate')
            ?.setValue(null, { emitEvent: false });
        }
      });

    this.timelineAddForm.get('rejectDate')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.timelineAddForm
          .get('rejectDate')
          ?.setValue(null, { emitEvent: false });
      }
    });

    this.timelineAddForm
      .get('dischargeDate')
      ?.valueChanges.subscribe((value) => {
        if (!value) {
          this.timelineAddForm
            .get('dischargeDate')
            ?.setValue(null, { emitEvent: false });
        }
      });

    forkJoin({
      timelineData: this.timelineService.getTimeline(this.patientId),
      dropdownData: this.timelineService.getDropdown(),
    }).subscribe({
      next: ({ timelineData, dropdownData }) => {
        this.data = timelineData.data;
        this.actionsList = dropdownData.data;

        this.isLoading = false;
      },
      error: (err) => {
        this.swalToastService.toastError(
          'Unable to retrieve Admission Timeline data'
        );

        console.error('Error fetching data:', err);
      },
    });
  }
}
