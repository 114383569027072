import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  permissions: string;
  [key: string]: any;
}

@Injectable()
export class AuthService {
  private permissionsToken: string | null = null;
  private permissions: Set<string> = new Set();

  constructor(private _http: HttpClient) {}

  setPermissionsToken(token: string) {
    this.permissionsToken = token;
    try {
      const decoded: DecodedToken = jwtDecode(token);
      this.permissions = new Set(decoded.permissions?.split(',') || []);
    } catch (err) {
      console.error('Failed to decode JWT:', err);
    }
  }

  hasPermission(permission: string): boolean {
    return this.permissions.has(permission);
  }

  getPermissions(): string[] {
    return Array.from(this.permissions);
  }

  getPermissionsToken(): string | null {
    return this.permissionsToken;
  }

  auth(): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post<string>(
      `${environment.BACKEND_URL}Auth`,
      {},
      {
        headers: headers,
        responseType: 'text' as 'json',
      }
    );
  }
}
