/* src/app/components/ehr/patients/components/hysteroscopy-section/hysteroscopy-file/hysteroscopy-file.component.css */
::ng-deep nz-switch {
  margin-right: 0.5em;
}
.draft-entry td:first-child {
  border-left: 2px solid var(--color-1);
}
.edit-btn {
  margin-left: 1.5em;
  cursor: pointer;
}
.expandable-row {
  cursor: pointer;
}
tr:hover {
  cursor: pointer;
}
.document-view {
  border: 1px solid lightgray;
  min-width: 100% !important;
}
.row {
  height: 100%;
}
.pdf-viewer {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.preview-content {
  height: 60vh;
  width: 100%;
}
.preview-title {
  font-size: 18px;
  font-weight: 500;
  padding: 1em;
  height: 64px;
  border-bottom: 1px solid lightgray;
  background: var(--color-6) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.download-btn:hover {
  cursor: pointer;
}
.anticon {
  display: block;
  margin: 1em;
}
.folder-view-icon.anticon {
  scale: 2.5 !important;
}
.preview {
  margin-bottom: 2em;
}
.image-viewer {
  max-width: 100%;
  max-height: 95%;
  width: auto;
  height: auto;
  display: block;
  cursor: pointer;
}
.video-viewer {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
}
::ng-deep .ant-image-preview-operations {
  background: var(--color-1) !important;
}
::ng-deep .ant-image-preview-mask {
  background-color: rgba(50, 50, 50, 0.9) !important;
}
.selected-preview {
  background-color: var(--color-7) !important;
}
/*# sourceMappingURL=hysteroscopy-file.component-JS22SAVD.css.map */
