<nz-layout>
  <nz-content>
    <ng-container>
      <app-reusable-table
        title="Neck Circumference"
        [columns]="['Date', 'Neck Circumference (cm)', 'Description']"
        [rowFields]="['creationDate', 'neckSize', 'description']"
        [data]="neckData"
        [addComponent]="addComp"
        [deleteFun]="deleteFun"
        [isLoading]="isLoading"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-nes')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
