<nz-layout>
  <nz-content
    ><ng-container>
      <app-reusable-table
        title="Procedures"
        [columns]="['Procedure', 'Body Site', 'Date']"
        [rowFields]="['procedure', 'bodySite', 'date']"
        [data]="procedures?.getAll"
        [dataGetList]="procedures"
        [expandComponent]="expandComp"
        [addComponent]="addComp"
        [isLoading]="isLoading"
        [isNoKnownLoading]="isNoKnownLoading"
        [deleteFun]="deleteFun"
        [handleToggleFun]="handleToggleFun"
        [openModalFun]="openModalFun"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-pro')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
