import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core'; // <-- Import Injectable decorator
import { COLORS } from 'src/styles/colors';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  private translationSubscription: Subscription;

  constructor(private translate: TranslateService) {
    super();

    // Subscribe to translations and update labels
    this.translationSubscription = this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  private translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('itemsPerPage');
    // Add other labels as needed

    // This is important to notify the component to re-check and update the view
    this.changes.next();
  }

  override getRangeLabel = (
    page: number,
    pageSize: number,
    length: number
  ): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.translate.instant('paginator.of')} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return this.translate.instant('paginator.rangeLabel', {
      start: startIndex + 1,
      end: endIndex,
      length: length,
    });
  };

  // Remember to unsubscribe to prevent memory leaks
  ngOnDestroy() {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }
}
