import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { IAddTimeline } from '../interfaces/Timeline/IAddTimeline';
import { ITimelineDetailed } from '../interfaces/Timeline/ITimelineDetailed';
import { ITimelineDropdown } from '../interfaces/Timeline/ITimelineDropdown';
import { IEditTimelineItem } from '../interfaces/Timeline/IEditTimeline';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class TimelineService {
  constructor(private readonly _http: HttpClient) {}

  getTimeline(
    patientId: string
  ): Observable<IGenericServiceResponse<ITimelineDetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<ITimelineDetailed>>(
      `${environment.BACKEND_URL}AccommodationData/Timeline/${patientId}`,
      {
        headers: headers,
      }
    );
  }

  getDropdown(): Observable<IGenericServiceResponse<ITimelineDropdown>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<ITimelineDropdown>>(
      `${environment.BACKEND_URL}AccommodationData/AdmissionStatusDropdownData`,
      {
        headers: headers,
      }
    );
  }

  addTimeline(timelineObj: IAddTimeline, patientId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}AccommodationData/Timeline/${patientId}`,
      timelineObj,
      {
        headers: headers,
      }
    );
  }

  editTimelineItem(timelineObj: IEditTimelineItem, patientId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}AccommodationData/Timeline/${patientId}`,
      timelineObj,
      {
        headers: headers,
      }
    );
  }

  updateCreationDate(patientId: string, date: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Patient/CreationDate/${patientId}/${date}`,
      {
        headers: headers,
      }
    );
  }

  deleteGroup(groupId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete(
      `${environment.BACKEND_URL}AccommodationData/Timeline/${groupId}`,
      {
        headers: headers,
      }
    );
  }
}
