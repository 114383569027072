import { Component, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { TooltipComponent } from '../../../../../../../common/tooltip/tooltip.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { PregnancyStatusService } from 'src/app/services/pregnancyStatus.service';
import { IAddPregnancyStatus } from '../../../../../../../interfaces/PregnancyStatus/IAddPregnancyStatus';
import { ICoding } from 'src/app/interfaces/ICoding';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  standalone: true,
  selector: 'app-pregnancy-status-add-edit',
  templateUrl: './pregnancy-status-add-edit.component.html',
  styleUrls: ['./pregnancy-status-add-edit.component.css'],
  imports: [
    NzModalModule,
    TranslateModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NzSelectModule,
    CommonModule,
    NzButtonModule,
    NzTableModule,
    NzLayoutModule,
    TooltipComponent,
    NzIconModule,
  ],
})
export class PregnancyStatusAddEditComponent {
  private destroy$ = new Subject<void>();

  public statusDropdown?: ICoding[];
  public pregnancyEstimatedDropdown?: ICoding[];

  isVisible = false;
  isLoading = false;
  isDropdownLoading = false;

  isEdit = false;
  editPregnancyStatusId?: number;

  pregnancyStatusSubmitted = false;

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  pregnancyStatusForm: FormGroup = new FormGroup({});

  constructor(
    private readonly pregnancyStatusService: PregnancyStatusService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isDropdownLoading = true;
    this.isEdit = false;

    this.pregnancyStatusForm = this.formBuilder.group({
      dateObservationValue: [null, Validators.required],
      statusValue: [null, Validators.required],
      pregnancyEstimatedValue: [null, Validators.required],
      estimatedDateValue: [null, Validators.required],
    });

    this.pregnancyStatusService.getDropdownData().subscribe({
      next: (res) => {
        this.statusDropdown = res?.data?.status;
        this.pregnancyEstimatedDropdown = res?.data?.pregnancyEstimated;
        this.isDropdownLoading = false;
      },
      error: (err) => {
        console.error('Error fetching dropdown data:', err);
      },
    });

    this.pregnancyStatusService.pregnancyStatusEditSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // GET DATA FIELDS AND ASSIGN DEFAULT VALUES TO FORM
        if (data) {
          this.isEdit = true;
          this.editPregnancyStatusId = data?.id;

          this.pregnancyStatusForm.patchValue({
            dateObservationValue: data?.dateOfObservation,
            statusValue: data?.statusId,
            pregnancyEstimatedValue: data?.pregnancyEstimatedId,
            estimatedDateValue: data?.estimatedDate,
          });
        } else {
          this.isEdit = false;
          this.editPregnancyStatusId = undefined;

          this?.pregnancyStatusForm.reset();
        }
      });

    this.pregnancyStatusService.pregnancyStatusModalSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((showModal) => {
        this.isVisible = showModal;
      });

    this.isLoading = false;
  }

  ngOnDestroy() {
    // Unsubscribe from observables
    this.destroy$.next();
    this.destroy$.complete();

    this?.modalRef.destroy();
  }

  getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  getOneYear() {
    // Get current date
    let currentDate = new Date();

    // Add one year to current date
    currentDate.setFullYear(currentDate.getFullYear() + 1);

    // Return the date in ISO format
    return currentDate.toISOString().split('T')[0];
  }

  handleCancel() {
    this.pregnancyStatusForm.reset();

    this.pregnancyStatusService.pregnancyStatusModalSub.next(false);

    this.pregnancyStatusSubmitted = false;

    this.isLoading = false;

    this?.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.pregnancyStatusSubmitted = true;

    if (this.pregnancyStatusForm.valid) {
      const pregnancyStatusObj: IAddPregnancyStatus = {};

      pregnancyStatusObj.id = this.editPregnancyStatusId;
      pregnancyStatusObj.dateOfObservation =
        this.pregnancyStatusForm.value?.dateObservationValue;
      pregnancyStatusObj.statusId = this.pregnancyStatusForm.value?.statusValue;
      pregnancyStatusObj.pregnancyEstimatedId =
        this.pregnancyStatusForm.value?.pregnancyEstimatedValue;
      pregnancyStatusObj.estimatedDate =
        this.pregnancyStatusForm.value?.estimatedDateValue;

      if (this.isEdit) {
        this.pregnancyStatusService
          .editPregnancyStatus(pregnancyStatusObj)
          .subscribe({
            next: () => {
              this.swalToastService.toastSuccess(
                'Pregnancy Status edited successfully'
              );
              this.pregnancyStatusService.getData();

              this.handleCancel();
            },

            error: (err) => {
              this.swalToastService.toastError(
                '`Unable to edit Pregnancy Status`'
              );
              this.handleCancel();
            },
          });
      } else {
        this.pregnancyStatusService
          .addPregnancyStatus(pregnancyStatusObj)
          .subscribe({
            next: () => {
              this.swalToastService.toastSuccess(
                'Pregnancy Status added successfully'
              );
              this.pregnancyStatusService.getData();

              this.handleCancel();
            },
            error: (err) => {
              this.swalToastService.toastError(
                'Unable to add Pregnancy Status'
              );
              this.handleCancel();
            },
          });
      }
    } else {
      this.isLoading = false;
    }
  }
}
