/* src/app/components/ehr/patients/components/laboratory-section/laboratory-file/laboratory-file-add/laboratory-file-add.component.css */
.laboratory-submitted .laboratory.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
input.upload {
  border: 0px solid red !important;
  margin: 0px 0px 0px 14px !important;
  padding: 0px 0px !important;
  width: 100%;
  padding: 2px !important;
}
/*# sourceMappingURL=laboratory-file-add.component-AWFQ2AKK.css.map */
