/* src/app/components/ehr/patients/components/inital-assessment-amen-section/dynamic-questionnaire/dynamic-questionnaire.component.css */
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.steps-action {
  margin: 24px 5px 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-actions {
  display: flex;
  gap: 8px;
}
.right-actions {
  margin-left: auto;
}
button {
  margin-right: 0;
}
button {
  margin-right: 8px;
}
button:disabled {
  border: none !important;
  background-color: var( --light-gray );
  color: #bfbfbf;
  cursor: not-allowed;
}
button:disabled span {
  color: #bfbfbf !important;
}
::ng-deep .ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
}
::ng-deep .ant-steps-item-finish .ant-steps-item-tail::after {
  background-color: var(--color-1) !important;
}
::ng-deep .ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
}
::ng-deep .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}
::ng-deep .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}
::ng-deep .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-1) !important;
}
::ng-deep .ant-steps-dot .ant-steps-icon-dot {
  background-color: var(--color-1) !important;
}
::ng-deep .ant-steps-dot .ant-steps-icon-dot:hover {
  background-color: #0a6f63 !important;
}
::ng-deep .ant-steps-item-title:hover {
  color: var(--color-1) !important;
}
::ng-deep .ant-steps-item-process .ant-steps-item-title {
  color: var(--color-1) !important;
}
form {
  background-color: white !important;
  padding: 50px;
}
.cancel-btn {
  border-radius: 5px;
  border: 1px solid red !important;
  background: #fcf0f0 !important;
}
.cancel-btn:hover {
  background: #fcd6d6 !important;
}
/*# sourceMappingURL=dynamic-questionnaire.component-EUPOH3MR.css.map */
