/* src/app/components/ehr/patients/components/tracheostomy-section/tube-characteristics/tube-characteristics-add-edit/tube-characteristics-add-edit.component.css */
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
nz-select {
}
.tubeCharacteristics-submitted .tubeCharacteristics.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=tube-characteristics-add-edit.component-BPUT26OV.css.map */
