/* src/app/components/ehr/patients/components/pregnancy-section/pregnancy-status/pregnancy-status-add-edit/pregnancy-status-add-edit.component.css */
.empty-table {
  margin-bottom: 3em;
}
.status-submitted .status.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .status.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=pregnancy-status-add-edit.component-TTOKHZ6Q.css.map */
