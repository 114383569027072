<div
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap="20px"
  [ngClass]="{ preview: isPreview }"
>
  <div
    [fxFlex]="data?.length! > 0 && !isPreview ? '50' : '100'"
    fxFlex.lt-lg="100"
  >
    <nz-layout class="table-margin">
      <nz-header class="table-header">
        <!--  -->
        <div
          *ngIf="isEOC() || isInpatientView() || isPreview || data?.length! > 0"
          class="table-header-row margin-lr"
        >
          <div class="table-header-cell">
            {{ "Imaging Files" | translate }}
          </div>
          <!--  -->
          <div class="table-header-cell margin-lrr">
            <button
              nz-button
              nzShape="round"
              (click)="openModal()"
              *ngIf="
                (isEOC() || isInpatientView()) &&
                !isPreview &&
                hasPermission('a-imgf')
              "
            >
              <div fxLayout="row">
                <div fxLayout="col">
                  <mat-icon class="add-icon">add_circle_outline</mat-icon>
                </div>
                <div fxLayout="col" class="add-title">
                  {{ "Add" | translate }} {{ "Imaging" | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>

        <div
          fxLayout="row"
          class="table-header-row"
          *ngIf="
            !(isEOC() || isInpatientView()) &&
            !isPreview &&
            !(data?.length! > 0)
          "
        >
          <div fxLayout="col" class="title" *ngIf="!isLoading">
            {{ "Imaging" | translate }} - {{ "No data available" | translate }}
          </div>
        </div>
      </nz-header>
      <nz-content *ngIf="data?.length! > 0 || isLoading">
        <nz-table
          #nzTable
          nzTableLayout="fixed"
          nzShowTitle="false"
          nzSize="middle"
          nzNoResult="No Imaging data found"
          [nzLoading]="isLoading"
          [nzData]="data || []"
          [nzShowPagination]="pagination"
          [nzShowSizeChanger]="pagination"
          nzPaginationType="default"
          [nzFrontPagination]="pagination"
          [nzPageIndex]="currentPageIndex"
          (nzPageIndexChange)="handlePageIndexChange($event)"
          (nzPageSizeChange)="handlePageSizeChange($event)"
          [nzPageSizeOptions]="[5, 10, 20]"
          [nzPageSize]="pageSize"
        >
          <thead>
            <tr>
              <ng-container
                *ngFor="
                  let col of [
                    'Category',
                    'Modality',
                    'Date',
                    'Description',
                    'Exam Title'
                  ]
                "
              >
                <th>
                  {{ col | translate }}
                  <app-tooltip [fieldName]="col" area="Imaging" />
                </th>
              </ng-container>

              <th *ngIf="hasUnsubmitted() && !isPreview" nzWidth="70px">
                {{ "Actions" | translate }}
                <app-tooltip fieldName="Actions" area="Imaging" />
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of getData()">
              <tr
                [ngClass]="{'draft-entry': !item?.['isSubmitted'] && (isEOC()||isInpatientView()), 'selected-preview': fileItem?.id === item.id }"
                (click)="this.reportFiles = true; preview(item)"
              >
                <ng-container
                  *ngFor="
                    let field of [
                      'category',
                      'modality',
                      'date',
                      'description',
                      'examTitle'
                    ]
                  "
                >
                  <td>{{ getValue(field, item[field]?.toString()) }}</td>
                </ng-container>

                <td
                  *ngIf="
                    (isEOC() || isInpatientView()) &&
                    !item['isSubmitted'] &&
                    !isPreview
                  "
                >
                  <i
                    nz-icon
                    nzType="icons:delete"
                    nzTheme="outline"
                    class="delete-btn"
                    (click)="deleteFun(item?.id!); $event.stopPropagation()"
                  ></i>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </nz-content>
    </nz-layout>
  </div>
  <div *ngIf="data?.length! > 0 && !isPreview" [fxFlex]="50" fxFlex.lt-lg="100">
    <div class="document-view">
      <div class="preview-title">
        {{ "Preview" | translate }}
        {{ fileItem?.examTitle ? " - " + fileItem?.examTitle : ""
        }}<span
          *ngIf="fileObj && !isPreviewLoading"
          nz-icon
          nzType="icons:download"
          nzTheme="outline"
          class="download-btn"
          (click)="downloadPDF()"
        ></span>
      </div>
      <div
        *ngIf="fileItem"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
        class="preview-buttons"
      >
        <div fxLayout="column">
          <button
            nz-button
            [ngClass]="reportFiles ? 'active-btn' : 'inactive-btn'"
            (click)="reportFiles = true; preview()"
          >
            {{ "Report" | translate }}
          </button>
        </div>
        <div fxLayout="column">
          <button
            nz-button
            [ngClass]="reportFiles ? 'inactive-btn' : 'active-btn'"
            (click)="reportFiles = false; preview()"
          >
            {{ "Files" | translate }}
          </button>
        </div>
      </div>
      <div
        class="preview-content"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div *ngIf="!isLoading && !isPreviewLoading" class="file-name">
          {{ this.fileName }}
        </div>
        <div *ngIf="!fileObj && !isPreviewLoading">
          <i
            nz-icon
            nzType="icons:folder-view"
            nzTheme="outline"
            class="folder-view-icon"
          ></i>
          <div>
            {{ "Select Imaging record to preview the file!" | translate }}
          </div>
        </div>
        <div *ngIf="isPreviewLoading"><nz-spin nzSimple></nz-spin></div>
        <pdf-viewer
          *ngIf="fileObj && !isPreviewLoading && fileType == 'application/pdf'"
          class="pdf-viewer"
          [src]="fileObj"
          [render-text]="true"
          [original-size]="false"
          (click)="isVisible = true"
        ></pdf-viewer>

        <img
          nz-image
          *ngIf="fileObj && !isPreviewLoading && fileType?.startsWith('image')"
          [nzSrc]="fileObj"
          nzWidth="auto"
          nzHeight="auto"
          class="image-viewer"
        />
      </div>

      <div
        *ngIf="paginatorSize > 1 && !reportFiles"
        class="file-paginator"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <nz-pagination
          [nzPageIndex]="1"
          [nzTotal]="paginatorSize"
          (nzPageIndexChange)="pageChange($event)"
          [nzPageSize]="1"
          nzSimple
        ></nz-pagination>
      </div>
    </div>
  </div>
</div>

<nz-modal
  #modalRef
  nzSize
  [(nzVisible)]="isVisible"
  [nzTitle]="
    ('Preview' | translate) +
    (fileItem?.examTitle ? ' - ' + fileItem?.examTitle : '')
  "
  (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel"
  nzOkText="Save"
  nzOkType="primary"
  [nzFooter]="null"
  class="custom-modal-class"
>
  <ng-container *nzModalContent>
    <pdf-viewer
      class="modal-pdf"
      [src]="fileObj"
      [render-text]="true"
      [original-size]="false"
    ></pdf-viewer>
  </ng-container>
</nz-modal>

<div *ngComponentOutlet="addComp"></div>
