<nz-layout>
    <nz-header class="section-header">
        {{'Somatometrics'|translate}}
    </nz-header>
    <nz-content>
        <ng-container *ngFor="let cm of submenu">
            <app-weight *ngIf="cm.path==componentPaths.weight" [id]="cm.path" />
            <app-neck *ngIf="cm.path==componentPaths.neck" [id]="cm.path" />
        </ng-container>
    </nz-content>
</nz-layout>