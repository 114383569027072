/* src/app/components/ehr/patients/components/imaging-section/imaging-files/imaging-files-add/imaging-files-add.component.css */
.imaging-submitted .imaging.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
input.upload {
  border: 0px solid red !important;
  margin: 0px 0px 0px 14px !important;
  padding: 0px 0px !important;
  width: 100%;
  padding: 2px !important;
}
/*# sourceMappingURL=imaging-files-add.component-PMZRB7XC.css.map */
