import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from 'src/app/services/swal.service';
import { AccountService } from 'src/app/services/account.service';
import { COLORS } from 'src/styles/colors';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrl: './forgot-username.component.css',
})
export class ForgotUsernameComponent {
  emailControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async ngOnInit() {}

  back() {
    this.router.navigate(['/login']);
  }

  forgotUsername() {
    if (this.emailControl.value)
      [
        this.accountService.forgotUsername(this.emailControl.value).subscribe({
          next: (res) => {
            this.swalToastService.toastSuccess(
              'User will be notified via email'
            );
          },

          error: (err) => {
            this.swalToastService.toastError(err.error.message);
          },
        }),
      ];
  }
}
