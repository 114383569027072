import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { COLORS } from 'src/styles/colors';

@Injectable({
  providedIn: 'root',
})
export class InPatientExternalRefresh {
  private refreshInpatientsListSource = new Subject<void>();

  refreshInpatientsList$ = this.refreshInpatientsListSource.asObservable();

  triggerRefresh() {
    this.refreshInpatientsListSource.next();
  }
}
