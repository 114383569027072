/* src/app/components/ehr/patients/components/precious-ai/assessment-section/assessment-section.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-layout.parent {
  background: #f4f4f6;
  height: 100%;
}
nz-content.parent {
}
nz-layout.child {
  background: #f4f4f6;
}
nz-content.child {
  border: 1px solid #d8d8d8 !important;
  padding: 1em;
}
.etiology {
}
.content {
  width: 100%;
}
/*# sourceMappingURL=assessment-section.component-ZPUFF2VP.css.map */
