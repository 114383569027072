/* src/app/components/ehr/in-patients/move-bed/move-bed.component.css */
.patients-search {
  margin-top: 2em;
  margin-bottom: 2em;
  height: 10%;
}
.patients-table {
  height: 90%;
  min-width: 100%;
}
.mat-mdc-form-field-focus-overlay {
  background-color: var(--red) !important;
}
.patients-search-input {
  height: 35px !important;
  margin-right: 3em;
}
@media (max-width: 1140px) {
  .patients-search-input {
    margin-right: 1em !important;
  }
}
.patients-search-buttons {
  height: 35px !important;
  margin-right: 1em;
}
.mat-mdc-input-element {
  height: 35px !important;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
::placeholder {
  color: #c2c2c2;
}
input[type=date] {
  font-family: "Inter", sans-serif !important;
}
.search-button {
  height: 100%;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: var(--color-0);
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-button:hover {
  cursor: pointer;
}
.clear-button {
  height: 100%;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: white;
  color: var(--color-0);
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-button {
  height: 100%;
  border: 1px solid var(--color-1);
  border-radius: 4px;
  background-color: var(--color-1);
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clear-button:hover {
  cursor: pointer;
}
.register-button:hover {
  cursor: pointer;
}
.add-button {
  border: 1px solid var(--color-0);
  border-radius: 15px;
  background-color: white;
  color: var(--color-0);
  padding: 5px;
  font-size: 12px;
  min-width: calc(90% + 5px);
}
.add-button:hover {
  color: white;
  background-color: var(--color-0);
  cursor: pointer;
}
.discharge-button {
  border: 1px solid var(--red);
  border-radius: 15px;
  background-color: var(--red);
  color: white;
  padding: 5px;
  font-size: 12px;
  min-width: 40%;
}
.discharge-button:hover {
  background-color: var(--red-hover);
  cursor: pointer;
}
.view-button {
  background-color: var(--color-1);
  color: white;
  border: 1px solid var(--color-0);
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
  min-width: 40%;
  margin-left: 5px;
}
.view-button:hover {
  background-color: var(--color-0);
  color: white;
  cursor: pointer;
}
.move-button {
  background-color: transparent;
  color: white;
  border: 1px solid var(--color-0);
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
  min-width: 10%;
  margin-right: 5px;
}
.move-button:hover {
  background-color: transparent;
  color: white;
  cursor: pointer;
}
.full-width {
  margin-left: 0px;
  min-width: calc(90% + 5px) !important;
}
.patients-content-paginator-hide {
  display: none !important;
}
.parent-body {
  height: 100%;
}
.patients-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
}
::ng-deep .mat-mdc-paginator-outer-container {
  margin-top: 0em;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
input {
  color: black !important;
}
textarea {
  border: 1px solid #d9d9d9;
  padding-left: 11px;
}
::ng-deep .mat-column-inpatient-actions {
  max-width: 250px !important;
  width: 250px !important;
  min-width: 250px !important;
  padding-right: 0px !important;
}
::ng-deep .mat-column-location {
  max-width: 300px !important;
  width: 300px !important;
  min-width: 100px !important;
}
::ng-deep .mat-column-patientFullName {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 100px !important;
}
::ng-deep .mat-column-sepsis {
  max-width: 150px !important;
  width: 150px !important;
  min-width: 150px !important;
}
nz-progress {
  width: 100% !important;
}
.bed-submitted .ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.discharge-submitted .ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.move-submitted .ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
::ng-deep div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--color-1);
}
.patient-dropdown {
  width: 100%;
}
:is() .ant-progress-inner {
  background-color: rgba(49, 125, 136, 0.2) !important;
}
:is() .ant-progress-inner {
  background-color: rgba(255, 184, 67, 0.29) !important;
}
:is() .ant-progress-inner {
  background-color: rgba(229, 86, 89, 0.25) !important;
}
.low-risk {
  background-color: #d0f0cf;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.mild-risk {
  background-color: #fff9d4;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.moderate-risk {
  background-color: #ffedd4;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.high-risk {
  background-color: #ffdfdf;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.moderate-rv {
  color: var(--orange);
}
.severe-rv {
  color: var(--red);
}
::ng-deep .mat-mdc-table {
  overflow-x: auto;
}
.no-patients {
  margin-top: 15em;
  font-size: large;
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.inPatient-spinner {
  margin-top: 3em;
}
/*# sourceMappingURL=move-bed.component-SPPCQH2A.css.map */
