import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IVitalSignDetailed } from 'src/app/interfaces/VitalSign/IVitalSignDetailed';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import Swal from 'sweetalert2';
import { VitalSignsAddEditComponent } from './vital-signs-add-edit/vital-signs-add-edit.component';
import { vitalSignsRV } from 'src/app/interfaces/IReferenceValue';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-vital-signs',
  templateUrl: './vital-signs.component.html',
  styleUrls: ['./vital-signs.component.css'],
})
export class VitalSignsComponent {
  public addComp = VitalSignsAddEditComponent;

  private destroy$ = new Subject<void>();

  vitalSigns?: IVitalSignDetailed[];
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  referenceValues = vitalSignsRV;

  constructor(
    private readonly vitalSignService: VitalSignService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.vitalSignService.vitalSignLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.vitalSignService.vitalSignDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IVitalSignDetailed[]) => {
        this.vitalSigns = data;
      });

    this.vitalSignService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  openModalFun(item?: IVitalSignDetailed) {
    this.vitalSignService.vitalSignModalSub.next(true);
    this.vitalSignService.vitalSignEditSub.next(item);
  }

  deleteFun(vitalSignId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder4'
      )} ${this.translate.instant('Vital Signs?')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.vitalSignService
          .deleteVitalSign(vitalSignId.toString())
          .subscribe({
            next: (d) => {
              this.swalToastService.toastSuccess(
                'Vital Signs deleted successfully'
              );
              this.vitalSignService.getData();
            },

            error: (err) => {
              this.swalToastService.toastError('Unable to delete Vital Signs');
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Vital Sign is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
}
