/* src/app/components/ehr/patients/components/daily-monitoring-section/glasgow/glasgow-add-edit/glasgow-add-edit.component.css */
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
nz-select {
}
.glasgow-submitted .glasgow.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=glasgow-add-edit.component-D4MFBYGL.css.map */
