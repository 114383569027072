<nz-modal #modalRef nzSize [nzVisible]="true" [nzTitle]="'Admission Timeline' | translate" (nzOnSave)="saveChanges()"
    (nzOnCancel)="closeModal()" nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null">
    <ng-container *nzModalContent>
        <form [formGroup]="timelineForm" (ngSubmit)="saveChanges()" [visible]="!isLoading">
            {{"If not relevant, leave it blank." | translate}}
            <span class="cards-container">
                <span class="row">
                    <!-- Patient Creation Date -->
                    <mat-card appearance="outlined" class="patient-creation-date">
                        <mat-card-header>
                            <mat-card-title class="creation-date-mark">
                                {{"Creation Date"|translate}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <input type="datetime-local" formControlName="patientCreationDate"
                                [max]="getCurrentDate()" />
                        </mat-card-content>
                    </mat-card>

                    <!-- Pending State -->
                    <mat-card appearance="outlined" class="patient-pending-date"
                        [visible]="this.timelineForm.value.patientCreationDate">
                        <mat-card-header>
                            <mat-card-title class="pending-date-mark">
                                {{"Pending"|translate}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <input type="datetime-local" formControlName="pendingDate"
                                [min]="formatLimit(this.timelineForm.value.patientCreationDate)"
                                [max]="getCurrentDate()" />
                        </mat-card-content>
                    </mat-card>
                </span>

                <span class="row" [visible]="this.timelineForm.value.pendingDate">
                    <!-- Waiting List -->
                    <mat-card appearance="outlined" class="patient-waiting-list-date"
                        [visible]="this.timelineForm.value.pendingDate">
                        <mat-card-header>
                            <mat-card-title class="waiting-list-date-mark">
                                {{"Waiting List"|translate}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <input type="datetime-local" formControlName="waitingListDate"
                                [min]="formatLimit(this.timelineForm.value.pendingDate)" [max]="getCurrentDate()" />
                        </mat-card-content>
                    </mat-card>

                    <!-- Approved -->
                    <mat-card appearance="outlined" class="patient-approved-date"
                        [visible]="this.timelineForm.value.waitingListDate">
                        <mat-card-header>
                            <mat-card-title class="approved-date-mark">
                                {{"Approve Date"|translate}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <input type="datetime-local" formControlName="approvedDate"
                                [min]="formatLimit(this.timelineForm.value.waitingListDate)" [max]="getCurrentDate()" />
                            <input type="text" class="notes" formControlName="approvedNotes"
                                placeholder="Approval Notes" />
                        </mat-card-content>
                    </mat-card>

                    <!-- Admission Date -->
                    <mat-card appearance="outlined" class="patient-admission-date"
                        [visible]="this.timelineForm.value.approvedDate">
                        <mat-card-header>
                            <mat-card-title class="admission-date-mark">
                                {{"Admission Date"|translate}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <input type="datetime-local" formControlName="admissionDate"
                                [min]="formatLimit(this.timelineForm.value.approvedDate)" [max]="getCurrentDate()" />
                            <select formControlName="admissionReasonId">
                                <option [ngValue]="null">{{"Select Admission Reason" | translate}}</option>
                                <option
                                    *ngFor="let reason of timelineDropdownData?.admissionDropdownData?.admissionReasons"
                                    [value]="reason.id">{{ reason.name }}</option>
                            </select>
                            <input type="text" class="notes" formControlName="admissionNotes"
                                placeholder="Admission Notes" />
                            <select formControlName="bedId">
                                <option [ngValue]="null">{{"Select Bed" | translate}}</option>
                                <option *ngFor="let bed of beds" [value]="bed.id">{{ bed.name }}</option>
                            </select>
                            <div *ngIf="bedMessageRef.value!=''" class="bed-unavailable">
                                {{bedMessageRef.value}}
                            </div>
                        </mat-card-content>
                    </mat-card>


                    <!-- Rejected -->
                    <div class="patient-rejected-date-container"
                        [visible]="this.timelineForm.value.pendingDate && !this.timelineForm.value.approvedDate">
                        <mat-card appearance="outlined" class="patient-rejected-date">
                            <mat-card-header>
                                <mat-card-title class="rejected-date-mark">
                                    {{"Reject Date"|translate}}
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <input type="datetime-local" formControlName="rejectedDate"
                                    [min]="formatLimit(this.timelineForm.value.pendingDate)"
                                    [max]="formatLimit(this.timelineForm.value.approvedDate) || getCurrentDate()" />
                                <select formControlName="rejectionReasonId">
                                    <option [ngValue]="null">{{"Select Rejection Reason" | translate}}</option>
                                    <option
                                        *ngFor="let reason of timelineDropdownData?.admissionDropdownData?.rejectedReasons"
                                        [value]="reason.id">{{ reason.name }}</option>
                                </select>
                                <input type="text" class="notes" formControlName="rejectedNotes"
                                    placeholder="Rejection Notes" />
                            </mat-card-content>
                        </mat-card>
                    </div>

                </span>


                <span class="row" [visible]="this.timelineForm.value.admissionDate">
                    <!-- Discharge -->
                    <mat-card appearance="outlined" class="patient-discharged-date"
                        [visible]="this.timelineForm.value.admissionDate">
                        <mat-card-header>
                            <mat-card-title class="discharged-date-mark">
                                {{"Discharge Date" | translate}}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <input type="datetime-local" formControlName="dischargeDate"
                                [min]="formatLimit(this.timelineForm.value.admissionDate)" [max]=" getCurrentDate()" />
                            <select formControlName="dischargeTypeId">
                                <option [ngValue]="null">{{"Select Discharge Type" | translate}}</option>
                                <option
                                    *ngFor="let type of timelineDropdownData?.dischargeDropdownData?.typeOfDischarge"
                                    [value]="type.id">
                                    {{ type.name }}</option>
                            </select>
                            <select formControlName="dischargeSituationId">
                                <option [ngValue]="null">{{"Select Discharge Situation" | translate}}</option>
                                <option *ngFor="let situation of timelineDropdownData?.dischargeDropdownData?.situation"
                                    [value]="situation.id">{{ situation.name }}</option>
                            </select>
                            <input type="text" class="notes" formControlName="dischargeTypeNotes"
                                placeholder="Discharge Type Notes" />
                            <input type="text" class="notes" formControlName="dischargeSituationNotes"
                                placeholder="Discharge Situation Notes" />
                        </mat-card-content>
                    </mat-card>
                </span>
            </span>

            <div fxLayout="row" fxLayoutAlign="end" class="footer">
                <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading || isSaving"
                    [disabled]="isLoading || isSaving">{{"Save" | translate}}</button>
                <button nz-button nzDanger class="cancel-btn" type="button" (click)="closeModal()"
                    [disabled]="isLoading || isSaving">{{"Cancel" | translate}}</button>
            </div>
        </form>
        <div [visible]="isLoading" class="spinner-container"><mat-spinner></mat-spinner></div>
    </ng-container>
</nz-modal>