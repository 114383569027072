<div [visible]="isUpdating && showPatientInformation" fxLayout="column" class="spinner">
  <h2>Processing...</h2>
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<div [visible]="isLoading != 4 && showPatientInformation" fxLayout="column" class="spinner">
  <h2>Fetching Data...</h2>
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>

<nz-layout [visible]="!isUpdating && isLoading == 4 && showPatientInformation" class="full-height">
  <nz-header>
    <div class="header-content">

      <div class="header-title">
        <div class="registration-status" [nz-tooltip]="getRegistrationStatus()"
          [ngStyle]="{'background-color': getBackgroundColor()}">
        </div>

        <!-- <span
          *ngIf="canNavigateBackInput"
          class="custom-button"
          (click)="previousScreen()"
        >
          <mat-icon class="information-icon">keyboard_arrow_left</mat-icon>
        </span>-->
        {{
        canNavigateBackInput
        ? "Patient's Information"
        : ("Patient's Admission Profile" | translate)
        }}

        <span *ngIf="
            admissionPersonalInfoData?.firstName &&
            admissionPersonalInfoData?.lastName
          ">
          {{ canNavigateBackInput ? "" : " : " }}
          {{ canNavigateBackInput ? "" : admissionPersonalInfoData?.firstName }}
          {{
          canNavigateBackInput ? "" : admissionPersonalInfoData?.lastName
          }}</span>
        <!-- Timeline -->
        <button nz-button class="admission-timeline-button" (click)="openTimeline()">
          <i nz-icon nzType="icons:timeline" class="custom-icon"></i>
        </button>
        <!-- Edit -->
        <button *ngIf="!isEditMode" nz-button class="admission-edit-button" (click)="enterEditMode()">
          <i nz-icon nzType="icons:userEdit" class="custom-icon"></i>
          <span class="btn-text">
            {{ "Edit" | translate }}
          </span>
        </button>
        <button *ngIf="isEditMode && canNavigateBackInput" nz-button class="admission-cancel-button"
          (click)="requestCancel()">
          <i nz-icon nzType="stop"></i>
          <span class="btn-text">
            {{ "Cancel" | translate }}
          </span>
        </button>
        <button *ngIf="isEditMode && canNavigateBackInput" nz-button class="admission-edit-button"
          (click)="updatePatientInfo()">
          <i nz-icon nzType="check"></i>
          <span class="btn-text">
            {{ "Update" | translate }}
          </span>
        </button>
      </div>

      <div class="header-buttons">
        <button *ngIf="isEditMode && !canNavigateBackInput" nz-button class="admission-edit-button"
          (click)="updatePatientInfo()">
          <i nz-icon nzType="check"></i>
          <span class="btn-text">
            {{ "Update" | translate }}
          </span>
        </button>
        <button *ngIf="isEditMode && !canNavigateBackInput" nz-button class="admission-cancel-button"
          (click)="requestCancel()">
          <i nz-icon nzType="stop"></i>
          <span class="btn-text">
            {{ "Cancel" | translate }}
          </span>
        </button>
        <!-- 
        Pending = 4
        Waiting List = 3
        Approve = 2
        Admitted = 6
        Discharged = 7
        Rejected = 5 -->

        <!-- WAITING LIST -->
        <!-- Show if current is Pending -->
        <button *ngIf="
            patientInfo?.registrationStatusId == 4 &&
            !isEditMode &&
            isLoading == 4  &&
            !canNavigateBackInput
          " (click)="waitlistPatient()" nz-button class="admission-waitinglist-button">
          <i nz-icon nzType="icons:admissionWaitingList"></i>
          <span class="btn-text">
            {{ "Waiting List" | translate }}
          </span>
        </button>

        <!-- APPROVE -->
        <button *ngIf="
                patientInfo?.registrationStatusId == 3 &&
                !isEditMode &&
                isLoading == 4  &&
                !canNavigateBackInput
              " (click)="approvePatient()" nz-button class="admission-approve-button">
          <i nz-icon nzType="icons:checkWhite"></i>
          <span class="btn-text">
            {{ "Approve" | translate }}
          </span>
        </button>

        <!-- ADMIT -->
        <!-- Show if current is Approved -->
        <button *ngIf="
            patientInfo?.registrationStatusId == 2 &&
            !isEditMode &&
            isLoading == 4  &&
            !canNavigateBackInput
          " (click)="openAdmitModal()" nz-button class="admission-admit-button">
          <i nz-icon nzType="icons:admissionBed"></i>
          <span class="btn-text">
            {{ "Admit" | translate }}
          </span>
        </button>

        <!-- DISCHARGE -->
        <!-- Show if current is Pending or Waiting List -->
        <button nz-button *ngIf="
            (patientInfo?.registrationStatusId == 3||
            patientInfo?.registrationStatusId == 4) &&
            !isEditMode &&
            isLoading == 4  &&
            !canNavigateBackInput
          " (click)="openRejectModal()" class="admission-reject-button">
          <i nz-icon nzType="icons:admissionReject"></i>
          <span class="btn-text">
            {{ "Reject" | translate }}
          </span>
        </button>


        <!-- REAPPLY / PENDING -->
        <!-- Show if current is Discharged or Rejected -->
        <button nz-button *ngIf="
            (patientInfo?.registrationStatusId == 5||
            patientInfo?.registrationStatusId ==7) &&
            !isEditMode &&
            isLoading == 4 &&
            !canNavigateBackInput
          " (click)="reapplyPatient()" class="admission-pending-button">
          <i nz-icon nzType="icons:reapply"></i>
          <span class="btn-text">
            {{ "Reapply" | translate }}
          </span>
        </button>
      </div>
    </div>
  </nz-header>
  <nz-content>
    <nz-tabset nzType="card">

      <nz-tab [nzTitle]="titleTemplate1" [nzForceRender]="true">
        <ng-template #titleTemplate1>
          <i nz-icon nzType="icons:personalInfo"></i>
          {{ "Personal Information" | translate }}
        </ng-template>
        <div>
          <app-admission-personal-info [admissionPersonalInfoData]="admissionPersonalInfoData"
            [gendersList]="gendersList" [bloodTypesList]="bloodTypesList" [doctors]="doctors"
            [placeOfBirthList]="placeOfBirthList" [closestRelativesList]="closestRelativesList"
            [educationLevelList]="educationLevelList" [familyStatusList]="familyStatusList"
            [sourceOfIncomeList]="sourceOfIncomeList" [religionList]="religionList" [formSubmitted]="formSubmitted"
            [registrationStatusDropdown]="
              admissionDropdownData.registrationStatus!
            " [documentTypeList]="documentTypeList" [countriesList]="countriesList" [avatarImageUrl]="avatarImageUrl"
            [isEditMode]="isEditMode"></app-admission-personal-info>
        </div>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate3" [nzForceRender]="true">
        <ng-template #titleTemplate3>
          <i nz-icon nzType="icons:addressInfo"></i>
          {{ "Address Info" | translate }}
        </ng-template>
        <app-admission-address-info [admissionAddressInfoData]="admissionAddressInfoData" [gendersList]="gendersList"
          [bloodTypesList]="bloodTypesList" [doctors]="doctors" [placeOfBirthList]="placeOfBirthList"
          [closestRelativesList]="closestRelativesList" [educationLevelList]="educationLevelList"
          [familyStatusList]="familyStatusList" [sourceOfIncomeList]="sourceOfIncomeList" [religionList]="religionList"
          [countriesList]="countriesList" [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"></app-admission-address-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate2" [nzForceRender]="true">
        <ng-template #titleTemplate2>
          <i nz-icon nzType="icons:medicalInfo"></i>
          {{ "Medical Info" | translate }}
        </ng-template>
        <app-admission-medical-info [admissionMedicalInfoData]="admissionMedicalInfoData"
          [bloodTypesList]="bloodTypesList" [doctors]="doctors" [placeOfBirthList]="placeOfBirthList"
          [closestRelativesList]="closestRelativesList" [educationLevelList]="educationLevelList"
          [familyStatusList]="familyStatusList" [sourceOfIncomeList]="sourceOfIncomeList" [religionList]="religionList"
          [immobilityList]="immobilityList" [formSubmitted]="formSubmitted" [isEditMode]="isEditMode"
          [externalDoctorsDropdownVersion]="externalDoctors" [externalDoctorsApi]="externalDoctorsList"
          [externalDoctorsCymaDropdownVersion]="externalDoctorCyma" [externalDoctorsCymaApi]="externalDoctorCymaList"
          [admissionExternalDoctors]="admissionMedicalInfoExternalDoctorsData" [admissionExternalDoctorsCyma]="
            admissionMedicalInfoExternalDoctorsCymaData
          " [countriesList]="countriesList" [doctorSpecialties]="doctorSpecialties"></app-admission-medical-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate4" [nzForceRender]="true">
        <ng-template #titleTemplate4>
          <i nz-icon nzType="icons:emergencyContacts"></i>
          {{ "Emergency Contacts" | translate }}
        </ng-template>
        <app-admission-emergency-contacts-info [countriesList]="countriesList" [documentTypeList]="documentTypeList"
          [admissionEmergencyContactsInfoData]="
            admissionEmergencyContactsInfoData
          " [closestRelativesList]="closestRelativesList" [formSubmitted]="formSubmitted"
          [isEditMode]="isEditMode"></app-admission-emergency-contacts-info>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate5" [nzForceRender]="true">
        <ng-template #titleTemplate5>
          <i nz-icon nzType="icons:insurance"></i>
          {{ "Insurance" | translate }}
        </ng-template>
        <app-admission-insurance [admissionInsuranceInfoData]="admissionInsuranceInfoData" [insurances]="insuranceList"
          [formSubmitted]="formSubmitted" [isEditMode]="isEditMode">
        </app-admission-insurance>
      </nz-tab>

      <nz-tab [nzTitle]="titleTemplate6" *ngIf="isPatientInfoLoaded && hasDocuments()" [nzForceRender]="true">
        <ng-template #titleTemplate6>
          <i nz-icon nzType="icons:documents"></i>
          {{ "Documents" | translate }}
        </ng-template>
        <app-admission-documents [patiendIdInput]="patientIdInput ?? null" [fileList]="fileList"
          [isEditMode]="isEditMode" />

        <!-- <app-admission-documents /> -->
      </nz-tab>


    </nz-tabset>
  </nz-content>
</nz-layout>

<!-- <app-admission-timeline *ngIf="isTimeline" [patientId]="patientID" (isVisibleChange)="isVisibleChange($event)" /> -->

<app-admission-timeline-history *ngIf="isTimeline" [patientId]="patientID"
  (isVisibleChange)="isVisibleChange($event)" />