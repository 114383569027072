/* src/app/components/ehr/patients/components/medical-history-section/vaccination/vaccination-add-edit/vaccination-add-edit.component.css */
.vaccination-submitted .vaccination.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=vaccination-add-edit.component-JYCNN6F7.css.map */
