import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComplicationResponse } from '../interfaces/Complication/IComplicationDropdownData';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { IComplication } from '../interfaces/Complication/IComplication';
import { IAddComplication } from '../interfaces/Complication/IAddComplication';
import { GetComplicationResponse } from '../interfaces/Complication/IGetComplication';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class ComplicationService {
  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}
  getComplicationDataDropDown(): Observable<
    IGenericServiceResponse<ComplicationResponse>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<ComplicationResponse>>(
      `${environment.BACKEND_URL}Complication/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getComplication(): Observable<
    IGenericServiceResponse<GetComplicationResponse>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<GetComplicationResponse>>(
      `${
        environment.BACKEND_URL
      }Complication/${this.patientService.patientIDsignal()}/${this.eocService.currentEocId()}/${this.eocService.currentVisitId()}`,
      {
        headers: headers,
      }
    );
  }

  addComplication(paramsObj: IAddComplication) {
    paramsObj.patientId = +this.patientService.patientIDsignal();
    paramsObj.visitId = +this.eocService.currentVisitId();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}Complication`,
      paramsObj,
      {
        headers: headers,
      }
    );
  }

  deleteComplication(complicationId: number): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${
        environment.BACKEND_URL
      }Complication/${complicationId}/${this.eocService.currentVisitId()}`,
      {
        headers: headers,
      }
    );
  }
}
