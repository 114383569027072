/* src/app/components/ehr/patients/components/medication-summary-section/medication-expand/medication-expand.component.css */
nz-header {
  background-color: white !important;
}
.ant-table-pagination {
  background-color: white !important;
}
.ant-table-wrapper {
  background-color: white !important;
}
::ng-deep .ant-table-small {
  border: 1px solid #f0f0f0 !important;
}
.medication-expanded {
}
.medication-details {
  background-color: white !important;
  height: auto;
  border: 1px solid #f4f4f6;
  border-radius: 5px;
  padding: 20px;
}
.medication-reactions {
  background-color: white !important;
  height: auto;
  border: 1px solid #f4f4f6;
  border-radius: 5px;
  padding: 20px;
}
.ant-layout-content {
  background-color: white;
  border-radius: 5px;
}
/*# sourceMappingURL=medication-expand.component-Q5ZIPGJA.css.map */
