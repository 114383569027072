import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { IMedicationDetailed } from 'src/app/interfaces/Medication/IMedicationDetailed';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import Swal from 'sweetalert2';
import { MedicationExpandComponent } from '../medication-expand/medication-expand.component';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-current-medication',
  templateUrl: './current-medication.component.html',
  styleUrls: ['./current-medication.component.css'],
})
export class CurrentMedicationComponent {
  // public expandComp = CurrentMedicationExpandComponent;

  private destroy$ = new Subject<void>();

  expandComp = MedicationExpandComponent;

  currentMedication?: IGetList<IMedicationDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.currentMedicationService.currentMedicationLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.currentMedicationService.noKnownLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.currentMedicationService.currentMedicationDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IMedicationDetailed>) => {
        this.currentMedication = data;
      });

    this.currentMedicationService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  openModalFun(item?: IMedicationDetailed) {
    this.currentMedicationService.medicationEditSub.next(item);

    this.currentMedicationService.medicationOpenSub.next(true);
  }

  deleteFun(medicationId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Current Medication')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentMedicationService
          .deleteCurrentMedication(medicationId.toString())
          .subscribe({
            next: (d) => {
              this.swalToastService.toastSuccess(
                'Current Medication deleted successfully'
              );
              this.currentMedicationService.getData();
            },
            error: (err) => {
              this.swalToastService.toastError(
                'Unable to delete Current Medication'
              );
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Current Medication is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.currentMedicationService.noKnownLoadingSub.next(true);
        this.currentMedicationService.addNoKnownCurrentMedication().subscribe({
          next: () => {
            this.swalToastService.toastSuccess(
              'No known Medication set successfully'
            );
            this.currentMedicationService.getData();
          },
          error: (err) => {
            this.swalToastService.toastError(
              'Unable to set No Known Medication'
            );
            console.error('Error setting No Known Medication:', err);
          },
        });
      } else {
        if (unknownid) {
          this.currentMedicationService.noKnownLoadingSub.next(true);
          this.currentMedicationService
            .deleteNoKnownCurrentMedication(unknownid)
            .subscribe({
              next: () => {
                this.swalToastService.toastSuccess(
                  'No known Medication unset successfully'
                );
                this.currentMedicationService.getData();
              },
              error: (err) => {
                this.swalToastService.toastError(
                  'Unable to unset No Known Medication'
                );
                console.error('Error unsetting No Known Medication:', err);
              },
            });
        }
      }
    }
  }
}
