import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import { Subject, takeUntil } from 'rxjs';
import { IProcedureDetailed } from '../../../../../../interfaces/Procedure/IProcedureDetailed';
import { ProcedureService } from '../../../../../../services/procedure.service';
import { ProcedureAddEditComponent } from './procedure-add-edit/procedure-add-edit.component';
import { ProcedureExpandComponent } from './procedure-expand/procedure-expand.component';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css'],
})
export class ProcedureComponent {
  public addComp = ProcedureAddEditComponent;
  public expandComp = ProcedureExpandComponent;

  private destroy$ = new Subject<void>();

  procedures?: IGetList<IProcedureDetailed>;
  isLoading: boolean = false;
  isNoKnownLoading: boolean = false;

  constructor(
    private readonly procedureService: ProcedureService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.procedureService.procedureLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.procedureService.procedureLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isNoKnownLoading = data;
      });

    this.procedureService.procedureDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetList<IProcedureDetailed>) => {
        this.procedures = data;
      });

    this.procedureService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  openModalFun(item?: IProcedureDetailed) {
    this.procedureService.procedureModalSub.next(true);
    this.procedureService.procedureEditSub.next(item);
  }

  deleteFun(procedureId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Procedure')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.procedureService
          .deleteProcedure(procedureId.toString())
          .subscribe({
            next: (d) => {
              this.swalToastService.toastSuccess(
                'Procedure deleted successfully'
              );
              this.procedureService.getData();
            },
            error: (err) => {
              this.swalToastService.toastError('Unable to delete Procedure');
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Procedure is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }

  handleToggleFun(preview: boolean, switchValue: boolean, unknownid?: string) {
    if (!preview) {
      if (switchValue) {
        this.procedureService.procedureNoKnownLoadingSub.next(true);
        this.procedureService.addNoKnownProcedure().subscribe({
          next: () => {
            this.swalToastService.toastSuccess(
              'No known Procedures set successfully'
            );
            this.procedureService.getData();
          },
          error: (err) => {
            this.swalToastService.toastError(
              'Unable to set No Known Procedures'
            );
            console.error('Error setting No Known Procedures:', err);
          },
        });
      } else {
        if (unknownid) {
          this.procedureService.procedureNoKnownLoadingSub.next(true);
          this.procedureService.deleteNoKnownProcedure(unknownid).subscribe({
            next: () => {
              this.swalToastService.toastSuccess(
                'No known Procedures unset successfully'
              );
              this.procedureService.getData();
            },
            error: (err) => {
              this.swalToastService.toastError(
                'Unable to unset No Known Procedure'
              );
              console.error('Error unsetting No Known Procedure:', err);
            },
          });
        }
      }
    }
  }

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
}
