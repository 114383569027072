<nz-modal #modalRef nzSize [nzVisible]="this.openModalID == this.patient?.id" [nzTitle]="'Details' | translate"
  (nzOnCancel)="handleCancel()" nzCancelText="Cancel" nzOkText="Ok" nzOkType="primary" [nzFooter]="null"
  class="custom-modal-class">
  <ng-container *nzModalContent>
    <div fxLayout="row" fxLayoutAlign="center start " [ngClass]="{ 'bottom-divider': patient?.reasonNotes }"
      *ngIf="patient?.reason">
      <div fxLayout="column" fxFlex="10" fxLayoutAlign="center start ">
        <span class="titles">{{ "Reason" | translate }}</span>
      </div>
      <div fxLayout="column" fxFlex="auto" fxLayoutAlign="center start ">
        {{ patient?.reason }}
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center start ">
      <div fxLayout="column" fxFlex="10" fxLayoutAlign="center start ">
        <span class="titles">{{ "Notes" | translate }}</span>
      </div>
      <div fxLayout="column" fxFlex="auto" fxLayoutAlign="center start ">
        {{ patient?.reasonNotes || "-" }}
      </div>
    </div>
  </ng-container>
</nz-modal>