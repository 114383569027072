import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EtiologyResponse } from '../interfaces/Etiology/IEtiologyDropdownData';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { IEtiology } from '../interfaces/Etiology/IEtiology';
import { IAddEtiology } from '../interfaces/Etiology/IAddEtiology';
import { GetEtiologyResponse } from '../interfaces/Etiology/IGetEtiology';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class EtiologyService {
  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  getEtiologyDataDropDown(): Observable<
    IGenericServiceResponse<EtiologyResponse>
  > {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<EtiologyResponse>>(
      `${environment.BACKEND_URL}Etiology/DropdownData`,
      {
        headers: headers,
      }
    );
  }

  getEtiology(): Observable<IGenericServiceResponse<GetEtiologyResponse>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<GetEtiologyResponse>>(
      `${
        environment.BACKEND_URL
      }Etiology/${this.patientService.patientIDsignal()}/${this.eocService.currentEocId()}/${this.eocService.currentVisitId()}`,
      {
        headers: headers,
      }
    );
  }

  addEtiology(paramsObj: IAddEtiology) {
    paramsObj.patientId = +this.patientService.patientIDsignal();
    paramsObj.visitId = +this.eocService.currentVisitId();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}Etiology`, paramsObj, {
      headers: headers,
    });
  }

  deleteEtiology(etiologyId: number): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${
        environment.BACKEND_URL
      }Etiology/${etiologyId}/${this.eocService.currentVisitId()}`,
      {
        headers: headers,
      }
    );
  }
}
