/* src/app/common/reusable-table-expand/reusable-table-expand.component.css */
nz-header {
  background-color: white !important;
}
.ant-table-pagination {
  background-color: white !important;
}
.ant-table-wrapper {
  background-color: white !important;
}
::ng-deep .ant-table-small {
  border: 1px solid #f0f0f0 !important;
}
/*# sourceMappingURL=reusable-table-expand.component-6B6QLZOU.css.map */
