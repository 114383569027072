import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IGenericServiceResponse } from '../interfaces/IServiceResponse';
import { ISHLPasscodeResponse } from '../interfaces/SHL/ISHLPasscodeResponse';
import { ICDADetailed } from '../interfaces/SHL/ICDADetailed';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class SHLService {
  constructor(private _http: HttpClient) {}

  passcode(
    shl: string,
    passcode: string
  ): Observable<IGenericServiceResponse<ISHLPasscodeResponse>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.post<IGenericServiceResponse<ISHLPasscodeResponse>>(
      `${environment.BACKEND_URL}SmartHealthLink/${shl}`,
      { passcode: passcode },
      {
        headers: headers,
      }
    );
  }

  getSHL(location: string): Observable<IGenericServiceResponse<ICDADetailed>> {
    return this._http.get<IGenericServiceResponse<ICDADetailed>>(location);
  }
}
