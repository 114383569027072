import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { combineLatest, debounceTime, Subject } from 'rxjs';
import { IModule } from 'src/app/interfaces/IModule';
import { NeckService } from 'src/app/services/neck.service';
import { WeightService } from 'src/app/services/weight.service';
import { paths } from 'src/component-paths/component-paths';

@Component({
  selector: 'app-somatometrics-section',
  templateUrl: './somatometrics-section.component.html',
  styleUrl: './somatometrics-section.component.css',
})
export class SomatometricsSectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: string;
  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit() {}

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.weightService.weightLoadingSub$,
        this.neckService.neckLoadingSub$,
      ]).subscribe(([value1, value2]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.path == this.selectedOption
        )?.path;
        if (!value1 && !value2 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const weight = document.getElementById(paths.weight);
                const neck = document.getElementById(paths.neck);

                if (neck) {
                  const rect = neck.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.neck
                    )?.path;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }

                if (weight) {
                  const rect = weight.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.weight
                    )?.path;

                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly weightService: WeightService,
    private readonly neckService: NeckService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
