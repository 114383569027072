<nz-layout>
  <nz-content
    ><ng-container>
      <app-reusable-table
        title="Past Medication"
        [columns]="[
          'Onset Date & Time',
          'End Date & Time',
          'Product',
          'Package',
          'Unit',
          'Frequency',
          'Duration'
        ]"
        [rowFields]="[
          'onSetDateTime',
          'endDateTime',
          'productCombination',
          'packageCombination',
          'unitCombination',
          'frequencyCombination',
          'durationTreatmentCombination'
        ]"
        [data]="pastMedication"
        [isLoading]="isLoading"
        [deleteFun]="deleteFun"
        [openModalFun]="openModalFun"
        [expandComponent]="expandComp"
        [isPreview]="false"
        [showAddButton]="hasPermission('a-pmed')"
      />
    </ng-container>
  </nz-content>
</nz-layout>
