import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { INeckDetailed } from '../interfaces/Neck/INeckDetailed';
import { IAddNeck } from '../interfaces/Neck/IAddNeck';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class NeckService {
  public neckModalSub = new BehaviorSubject<boolean>(false);

  public neckEditSub = new BehaviorSubject<INeckDetailed | undefined>(
    undefined
  );

  public neckDataSub = new BehaviorSubject<INeckDetailed[]>([]);

  public neckLoadingSub = new BehaviorSubject<boolean>(false);
  public neckLoadingSub$ = this.neckLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.neckLoadingSub.next(true);
    this.getNeck(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.neckDataSub.next(response?.data!);
        this.neckLoadingSub.next(false);
      },

      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve Neck record');
        console.error('Error fetching Neck:', err);
      },
    });
  }

  getNeck(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<INeckDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<INeckDetailed[]>>(
        `${environment.BACKEND_URL}NeckSize/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<INeckDetailed[]>>(
        `${environment.BACKEND_URL}NeckSize/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  addNeck(neckObj: IAddNeck) {
    neckObj.visitId = this.eocService.currentVisitId();
    neckObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}NeckSize`, neckObj, {
      headers: headers,
    });
  }

  editNeck(neckObj: IAddNeck) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}NeckSize/${neckObj.id}`,
      neckObj,
      {
        headers: headers,
      }
    );
  }

  deleteNeck(neckId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}NeckSize/${neckId}`,
      {
        headers: headers,
      }
    );
  }

  deleteNeckFromTable(neckId: string) {
    // REMOVE THE Neck
    this.neckDataSub.next(
      this.neckDataSub.value?.filter((neck) => neck.id?.toString() != neckId)
    );
  }
}
