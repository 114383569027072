import { Component } from '@angular/core';
import { IGetEpisodesOfCare } from 'src/app/interfaces/IGetEpisodesOfCare';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PatientService } from 'src/app/services/patient.service';
import { VisitExpandComponent } from './visit-expand/visit-expand.component';
import { Subject, takeUntil } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-eoc-section',
  templateUrl: './eoc-section.component.html',
  styleUrls: ['./eoc-section.component.css'],
})
export class EocSectionComponent {
  public expandComp = VisitExpandComponent;

  private destroy$ = new Subject<void>();

  isLoading: boolean = false;

  episodesOfCare: IGetEpisodesOfCare[] = [];

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.eocService.isLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.eocService.eocDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGetEpisodesOfCare[]) => {
        this.episodesOfCare = data;
      });

    this.eocService.getData();
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }
}
