/* src/app/components/ehr/patients/components/medical-history-section/medical-history/medical-history-add-edit/medical-history-add-edit.component.css */
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 150px;
  margin-left: 1em;
}
.history-submitted .history.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=medical-history-add-edit.component-WDD3LPJK.css.map */
