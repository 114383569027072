import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { IWeightDetailed } from '../interfaces/Weight/IWeightDetailed';
import { IAddWeight } from '../interfaces/Weight/IAddWeight';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class WeightService {
  public weightModalSub = new BehaviorSubject<boolean>(false);

  public weightEditSub = new BehaviorSubject<IWeightDetailed | undefined>(
    undefined
  );

  public weightDataSub = new BehaviorSubject<IWeightDetailed[]>([]);

  public weightLoadingSub = new BehaviorSubject<boolean>(false);
  public weightLoadingSub$ = this.weightLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.weightLoadingSub.next(true);
    this.getWeight(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.weightDataSub.next(response?.data!);
        this.weightLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve Weight record');
        console.error('Error fetching Weight:', err);
      },
    });
  }

  getWeight(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IWeightDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IWeightDetailed[]>>(
        `${environment.BACKEND_URL}Weight/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IWeightDetailed[]>>(
        `${environment.BACKEND_URL}Weight/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  addWeight(weightObj: IAddWeight) {
    weightObj.visitId = this.eocService.currentVisitId();
    weightObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(`${environment.BACKEND_URL}Weight`, weightObj, {
      headers: headers,
    });
  }

  editWeight(weightObj: IAddWeight) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Weight/${weightObj.id}`,
      weightObj,
      {
        headers: headers,
      }
    );
  }

  deleteWeight(weightId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Weight/${weightId}`,
      {
        headers: headers,
      }
    );
  }

  deleteWeightFromTable(weightId: string) {
    // REMOVE THE Weight
    this.weightDataSub.next(
      this.weightDataSub.value?.filter(
        (weight) => weight.id?.toString() != weightId
      )
    );
  }
}
