<nz-layout>
  <nz-header> </nz-header>
  <nz-content>
    <app-reusable-table
      title="Episodes of Care"
      [isLoading]="isLoading"
      [columns]="[
        'Potential Diagnosis',
        'Activation Date',
        'End Date',
        'Status',
        ''
      ]"
      [rowFields]="[
        'potentialDiagnosis',
        'activationDate',
        'endDate',
        'status',
        'isHospitalized'
      ]"
      [data]="episodesOfCare"
      [expandComponent]="expandComp"
      [showAddButton]="hasPermission('a-eoc')"
    />
  </nz-content>
</nz-layout>
