import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Observable, Subject, catchError, takeUntil, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { COLORS } from 'src/styles/colors';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private destroy$ = new Subject<void>();

  private currentLocale: string | null = null;

  constructor(private userService: UserService, private http: HttpClient) {
    this.userService.localeSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((locale) => {
        this.currentLocale = locale;
      });
  }

  ngOnInit() {}

  ngOnDestoy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCurrentLocale(): string | null {
    return this.currentLocale;
  }

  updateLocale(locale: string): Observable<any> {
    const requestBody = {
      attributes: {
        locale: locale,
      },
    };

    return this.http
      .put(
        `${environment.BACKEND_URL}UserManagement/ChangeLanguage/${locale}`,
        {}
      )
      .pipe(
        catchError((err) => {
          console.error(err.error);
          return throwError(() => err.error);
        })
      );
  }
}
