import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IQuestionnaireQuestion } from 'src/app/interfaces/Questionnaire/IQuestionnaire';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-dynamic-questions',
  templateUrl: './dynamic-questions.component.html',
  styleUrls: ['./dynamic-questions.component.css'],
})
export class DynamicQuestionsComponent implements OnInit {
  @Input() elements: IQuestionnaireQuestion[] = [];
  @Input() formGroup!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly permissionService: PermissionService
  ) {}

  isInpatientView(): boolean {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  ngOnInit() {}

  setNotMentioned(fieldName: string): void {
    this.formGroup
      .get(fieldName)
      ?.setValue(this.translate.instant('Not mentioned'));
    this.cdr.detectChanges(); // Ensure UI updates immediately
  }
}
