<div
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap="20px"
  [ngClass]="{ preview: isPreview }"
  class="table-margin"
>
  <div
    [fxFlex]="data?.length! > 0 && !isPreview ? '50' : '100'"
    fxFlex.lt-lg="100"
  >
    <nz-layout>
      <nz-header class="table-header">
        <!--  -->
        <div
          *ngIf="isEOC() || isInpatientView() || isPreview || data?.length! > 0"
          class="table-header-row margin-lr"
        >
          <div class="table-header-cell">
            {{ "Laboratory Files" | translate }}
          </div>
          <!--  -->
          <div class="table-header-cell margin-lrr">
            <button
              nz-button
              nzShape="round"
              (click)="openModal()"
              *ngIf="
                (isEOC() || isInpatientView()) &&
                !isPreview &&
                hasPermission('a-labf')
              "
            >
              <div fxLayout="row">
                <div fxLayout="col">
                  <mat-icon class="add-icon">add_circle_outline</mat-icon>
                </div>
                <div fxLayout="col" class="add-title">
                  {{ "Add" | translate }} {{ "Laboratory File" | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>

        <div
          fxLayout="row"
          class="table-header-row"
          *ngIf="
            !(isEOC() || isInpatientView()) &&
            !isPreview &&
            !(data?.length! > 0)
          "
        >
          <div fxLayout="col" class="title" *ngIf="!isLoading">
            {{ "Laboratory Files" | translate }} -
            {{ "No data available" | translate }}
          </div>
        </div>
      </nz-header>
      <nz-content *ngIf="data?.length! > 0 || isLoading">
        <nz-table
          #nzTable
          nzTableLayout="fixed"
          nzShowTitle="false"
          nzSize="middle"
          nzNoResult="No Laboratory Files found"
          [nzLoading]="isLoading"
          [nzData]="data || []"
          [nzShowPagination]="pagination"
          [nzShowSizeChanger]="pagination"
          nzPaginationType="default"
          [nzFrontPagination]="pagination"
          [nzPageIndex]="currentPageIndex"
          (nzPageIndexChange)="handlePageIndexChange($event)"
          (nzPageSizeChange)="handlePageSizeChange($event)"
          [nzPageSizeOptions]="[5, 10, 20]"
          [nzPageSize]="pageSize"
        >
          <thead>
            <tr>
              <ng-container
                *ngFor="
                  let col of ['Category', 'Date', 'Description', 'Exam Title']
                "
              >
                <th>
                  {{ col | translate }}
                  <app-tooltip [fieldName]="col" area="Laboratory" />
                </th>
              </ng-container>

              <th *ngIf="hasUnsubmitted() && !isPreview" nzWidth="70px">
                {{ "Actions" | translate }}
                <app-tooltip fieldName="Actions" area="Laboratory" />
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of getData()">
              <tr
                [ngClass]="{'draft-entry': !item?.['isSubmitted'] && (isEOC()||isInpatientView()), 'selected-preview': pdfItem?.id === item.id}"
                (click)="requestPDF(item)"
              >
                <ng-container
                  *ngFor="
                    let field of [
                      'category',
                      'date',
                      'description',
                      'examTitle'
                    ]
                  "
                >
                  <td>{{ getValue(field, item[field]?.toString()) }}</td>
                </ng-container>

                <td
                  *ngIf="
                    (isEOC() || isInpatientView()) &&
                    !item['isSubmitted'] &&
                    !isPreview
                  "
                >
                  <i
                    nz-icon
                    nzType="icons:delete"
                    nzTheme="outline"
                    class="delete-btn"
                    (click)="deleteFun(item?.id!); $event.stopPropagation()"
                  ></i>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </nz-content>
    </nz-layout>
  </div>
  <div *ngIf="data?.length! > 0 && !isPreview" [fxFlex]="50" fxFlex.lt-lg="100">
    <div class="document-view">
      <div class="preview-title">
        {{ "Preview" | translate }}
        {{ pdfItem?.examTitle ? " - " + pdfItem?.examTitle : ""
        }}<span
          *ngIf="pdfObj && !isPreviewLoading"
          nz-icon
          nzType="icons:download"
          nzTheme="outline"
          class="download-btn"
          (click)="downloadPDF(pdfItem!)"
        ></span>
      </div>
      <div class="preview-content" fxLayoutAlign="center center">
        <div *ngIf="!pdfObj && !isPreviewLoading">
          <i
            nz-icon
            nzType="icons:folder-view"
            nzTheme="outline"
            class="folder-view-icon"
          ></i>
          <div>{{ "Select a Laboratory File to preview!" | translate }}</div>
        </div>
        <div *ngIf="isPreviewLoading"><nz-spin nzSimple></nz-spin></div>
        <pdf-viewer
          *ngIf="pdfObj && pdfItem && !isPreviewLoading"
          class="pdf-viewer"
          [src]="pdfObj"
          [render-text]="true"
          [original-size]="false"
          [fit-to-page]="true"
          (click)="isVisible = true"
        ></pdf-viewer>
      </div>
    </div>
  </div>
</div>

<nz-modal
  #modalRef
  nzSize
  [(nzVisible)]="isVisible"
  [nzTitle]="
    ('Preview' | translate) +
    (pdfItem?.examTitle ? ' - ' + pdfItem?.examTitle : '')
  "
  (nzOnCancel)="handleCancel()"
  nzCancelText="Cancel"
  nzOkText="Save"
  nzOkType="primary"
  [nzFooter]="null"
  class="custom-modal-class"
>
  <ng-container *nzModalContent>
    <pdf-viewer
      class="modal-pdf"
      [src]="pdfObj"
      [render-text]="true"
      [original-size]="false"
    ></pdf-viewer>
  </ng-container>
</nz-modal>

<div *ngComponentOutlet="addComp"></div>
