import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { SwalToastService } from 'src/app/services/swal.service';
import { IAddAccommodation } from 'src/app/interfaces/Accommodation/IAddAccommodation';
import { AccommodationService } from 'src/app/services/accomodation.service';
import { AdmissionService } from 'src/app/services/admission.service';

@Component({
  selector: 'app-move-bed',
  templateUrl: './move-bed.component.html',
  styleUrl: './move-bed.component.css',
})
export class MoveBedComponent {
  @ViewChild('moveModalRef', { static: true }) moveModalRef!: NzModalRef;

  @Input() parent: any;

  @Input() isMoveVisible: boolean = false;
  @Input() selectedPatientFullName = '';
  @Input() selectedPatientID?: number;

  moveForm: FormGroup = new FormGroup({});

  moveBeds: { id: number; name: string }[] = [];

  isMoveLoading = false;
  moveSubmitted = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly admissionService: AdmissionService,
    private readonly accommodationService: AccommodationService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {
    this.moveForm = this.fb.group({
      accommodationValue: ['', Validators.required],
      newBedValue: [undefined, Validators.required],
      noteValue: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isMoveVisible']) {
      if (changes['isMoveVisible'].currentValue) {
        this.moveBeds = [];

        this.moveForm.reset();

        if (this.selectedPatientID) {
          this.moveForm
            .get('accommodationValue')
            ?.setValue(this.selectedPatientID);
          this.isMoveLoading = true;

          this.admissionService.getDropdownData().subscribe({
            next: (d) => {
              const data = d.data.bedAvailableId || [];

              data.forEach((building) => {
                building.floors.forEach((floor) => {
                  floor.wards.forEach((ward) => {
                    ward.rooms.forEach((room) => {
                      room.beds.forEach((bed) => {
                        this.moveBeds.push({
                          id: bed.id,
                          name: `${building.name} - ${floor.name} - ${ward.name} - ${room.name} - ${bed.name}`,
                        });
                      });
                    });
                  });
                });
              });
              this.isMoveLoading = false;
            },
            error: (err) => {},
          });
        }
      }
    }
  }

  handleMoveCancel() {
    this.parent.isMoveVisible = false;

    this.moveForm.reset();

    this.moveSubmitted = false;

    this.isMoveLoading = false;

    this.moveModalRef.destroy();
  }

  moveBedForm() {
    this.moveSubmitted = true;
    this.isMoveLoading = true;

    const moveObj: IAddAccommodation = {
      accommodationDataId: this.moveForm.get('accommodationValue')?.value,
      newBedId: this.moveForm.get('newBedValue')?.value,
      bedNotes: this.moveForm.get('noteValue')?.value,
    };

    this.accommodationService.moveBed(moveObj).subscribe({
      next: (res) => {
        this.swalToastService.toastSuccess('Patient moved successfully');
        this.isMoveLoading = false;
        this.parent.isMoveVisible = false;

        this.moveForm.reset();

        this.parent.refreshTable();
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to move the Patient');
        this.isMoveLoading = false;
      },
    });
  }
}
