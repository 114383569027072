import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IMedicalInfoDetailed } from '../interfaces/MedicalInfo/IMedicalInfoDetailed';
import { IAddMedicalInfo } from '../interfaces/MedicalInfo/IAddMedicalInfo';
import { TranslateService } from '@ngx-translate/core';
import { SwalToastService } from './swal.service';

@Injectable()
export class MedicalHistoryService {
  public medicalHistoryModalSub = new BehaviorSubject<boolean>(false);

  public medicalHistoryEditSub = new BehaviorSubject<
    IMedicalInfoDetailed | undefined
  >(undefined);

  public medicalHistoryDataSub = new BehaviorSubject<IMedicalInfoDetailed[]>(
    []
  );

  public medicalHistoryLoadingSub = new BehaviorSubject<boolean>(false);
  public medicalHistoryLoadingSub$ =
    this.medicalHistoryLoadingSub.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  async getData() {
    this.medicalHistoryLoadingSub.next(true);
    this.getMedicalHistory(
      this.patientService.patientIDsignal(),
      this.eocService.currentVisitId()
    ).subscribe({
      next: (response) => {
        this.medicalHistoryDataSub.next(response?.data!);
        this.medicalHistoryLoadingSub.next(false);
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve medical alerts');
        console.error('Error fetching medical alerts:', err);
      },
    });
  }

  getMedicalHistory(
    patientId: string,
    visitId?: string
  ): Observable<IGenericServiceResponse<IMedicalInfoDetailed[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<IGenericServiceResponse<IMedicalInfoDetailed[]>>(
        `${environment.BACKEND_URL}MedicalHistory/${patientId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<IGenericServiceResponse<IMedicalInfoDetailed[]>>(
        `${environment.BACKEND_URL}MedicalHistory/${patientId}`,
        {
          headers: headers,
        }
      );
    }
  }

  addMedicalHistory(medicalAlertObj: IAddMedicalInfo) {
    medicalAlertObj.visitId = this.eocService.currentVisitId();
    medicalAlertObj.patientId = this.patientService.patientIDsignal();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}MedicalHistory`,
      medicalAlertObj,
      {
        headers: headers,
      }
    );
  }

  editMedicalHistory(medicalAlertObj: IAddMedicalInfo) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}MedicalHistory/${medicalAlertObj.id}`,
      medicalAlertObj,
      {
        headers: headers,
      }
    );
  }

  deleteMedicalHistory(medicalAlertId: string): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}MedicalHistory/${medicalAlertId}`,
      {
        headers: headers,
      }
    );
  }

  deleteMedicalHistoryFromTable(medicalHistoryId: string) {
    // REMOVE THE Medical History
    this.medicalHistoryDataSub.next(
      this.medicalHistoryDataSub.value?.filter(
        (mh) => mh.id?.toString() != medicalHistoryId
      )
    );
  }
}
