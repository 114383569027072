/* src/app/components/ehr/patients/components/etiology-section/etiology/etiology.component.css */
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.table-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
nz-table {
  width: 100%;
}
.ant-table-tbody > tr.submitted-item > td {
  border-left: 2px solid var(--color-1);
}
::ng-deep .ant-checkbox-checked ::ng-deep .ant-checkbox-inner {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
}
/*# sourceMappingURL=etiology.component-QAHQ7JXC.css.map */
