/* src/app/components/ehr/patients/components/pregnancy-section/pregnancy-outcome/pregnancy-outcome-add-edit/pregnancy-outcome-add-edit.component.css */
.empty-table {
  margin-bottom: 3em;
}
.outcome-submitted .outcome.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=pregnancy-outcome-add-edit.component-5ETKEYWF.css.map */
