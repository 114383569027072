import {
  Component,
  Input,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { AllergiesExpandComponent } from 'src/app/components/ehr/patients/components/alerts-section/allergies/allergies-expand/allergies-expand.component';
import { VisitExpandComponent } from 'src/app/components/ehr/patients/components/eoc-section/visit-expand/visit-expand.component';
import { FlexModule } from '@angular/flex-layout';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { MatIconModule } from '@angular/material/icon';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { Router } from '@angular/router';
import { NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import { NgModule } from '@angular/core';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { IGetList } from 'src/app/interfaces/Get/IGetList';
import moment from 'moment';
import { IReferenceValue } from 'src/app/interfaces/IReferenceValue';
import { DynamicService } from '../../services/dynamic.service';
import { VitalSignService } from 'src/app/services/vitalSign.service';
import { CapnographyService } from 'src/app/services/capnography.service';
import { ComorbidityService } from 'src/app/services/comorbidity.service';
import { TooltipComponent } from '../tooltip/tooltip.component';
import {
  IDynamicItem,
  IDynamicTable,
} from 'src/app/interfaces/DynamicTable/IDynamicDetailed';
import { IDynamicDropdownItem } from 'src/app/interfaces/DynamicTable/IDynamicDropdown';
import { IAddDynamic } from 'src/app/interfaces/DynamicTable/IAddDynamic';
import { PatientService } from 'src/app/services/patient.service';
import Swal from 'sweetalert2';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

interface formInterface {
  [key: string]: [any, any];
}

@Component({
  standalone: true,
  selector: 'app-reusable-dynamic-table',
  templateUrl: './reusable-dynamic-table.component.html',
  styleUrls: ['./reusable-dynamic-table.component.css'],
  imports: [
    NzLayoutModule,
    NzTableModule,
    TranslateModule,
    CommonModule,
    AllergiesExpandComponent,
    VisitExpandComponent,
    FlexModule,
    NzToolTipModule,
    NzIconModule,
    MatIconModule,
    TooltipComponent,
    NzSwitchModule,
    FormsModule,
    NzButtonModule,
    NzModalModule,
    NzPaginationModule,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [NgModule],
})
export class ReusableDynamicTableComponent {
  @Input() endpoint: string = 'Laboratory';

  @ViewChild('modalRef', { static: true }) modalRef!: NzModalRef; // Reference to the modal

  tableForm: FormGroup = new FormGroup({});

  itemSubmitted: boolean = false;

  currentPageIndex: number = 1;
  pageSize: number = 10;

  @Input() noKnownComponent: Type<any> | null = null;

  @Input() isLoading?: boolean = false;
  @Input() isNoKnownLoading?: boolean;
  @Input() pagination?: boolean = true;

  @Input() title: string = '';
  @Input() colIdentifier: string = '';

  rowNames: string[] = [];
  columnIDs: number[] = [];
  @Input() dropdownData: IDynamicDropdownItem[] = [];
  @Input() groupId: number = 0;

  @Input() data: IDynamicTable<IDynamicItem>[] = [];

  editObj?: IDynamicTable<IDynamicItem> = undefined;

  @Input() dataGetList?: IGetList<IDynamicItem>;

  @Input() isPreview?: boolean;

  @Input() isEdit?: boolean;

  @Input() showAddButton?: boolean = true;

  isVisible: boolean = false;

  filteredData: IDynamicTable<IDynamicItem>[] = [];

  @ViewChild('expandContainer', { read: ViewContainerRef, static: false })
  expandCont!: ViewContainerRef;

  // DO NOT REMOVE LAB RESULTS,... SERVICES!!!
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly eocService: EpisodeOfCareService,
    private readonly router: Router,
    private readonly dynamicService: DynamicService,
    private readonly patientService: PatientService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly capnographyService: CapnographyService,
    private readonly vitalSignService: VitalSignService,
    private readonly comorbidityService: ComorbidityService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.fetchData();

    this.columnIDs = this.dropdownData.map((x) => x.id);
    this.rowNames = this.dropdownData.map((x) => x.name);

    const formControlsConfig: formInterface = {};

    for (const i of this.dropdownData) {
      if (i.isRequired) {
        formControlsConfig[i.name.replace(' ', '')] = [
          null,
          Validators.required,
        ];
      } else {
        formControlsConfig[i.name.replace(' ', '')] = [null, null];
      }
    }

    // Initialize the form with dynamically created controls
    this.tableForm = this.formBuilder.group(formControlsConfig);
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(this.endpoint);
  }

  async fetchData() {
    if (!this.isPreview) {
      this.isLoading = true;
      this.dynamicService
        .getDynamic(
          this.endpoint,
          this.patientService.patientIDsignal(),
          this.groupId,
          this.eocService.currentVisitId()
        )
        .subscribe({
          next: (response) => {
            this.data =
              response.data.filter((item) => item.groupId == this.groupId) ||
              [];

            this.getData();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to retrieve', this.title);
            console.error(`Error fetching ${this.title}:`, err);
          },
        });
    }
  }

  findField(id: number): string {
    return this.dropdownData?.find((x) => x.id === id)?.name || '';
  }

  findValue(id: number, labs: IDynamicItem[]): string {
    return labs?.find((x) => x['id'] === id)?.['value'].toString() || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.getData();
    }
  }

  getData() {
    if (this.data?.length! > 0) {
      if (this.pagination) {
        const startIndex = (this.currentPageIndex - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.filteredData = this?.data?.slice(startIndex, endIndex) || [];
      } else {
        this.filteredData = this?.data || [];
      }
    } else {
      this.filteredData = [];
    }

    this.isVisible = false;
    this.isLoading = false;
  }

  handlePageIndexChange(pageIndex: number): void {
    this.currentPageIndex = pageIndex;

    this.getData();
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPageIndex = 1; // Reset to the first page when changing page size

    this.getData();
  }

  expandSet: Set<number> = new Set<number>();

  getUnknownText(): string {
    if (this.dataGetList?.noInfo || this.dataGetList?.noKnown) {
      for (const key in this.dataGetList?.getUnknown) {
        if (key !== 'id') {
          const getUnknown = this.dataGetList?.getUnknown as unknown as {
            [key: string]: string;
          };
          return getUnknown[key];
        }
      }
    }

    return 'No data available';
  }

  openModal(item?: IDynamicTable<IDynamicItem>) {
    item ? (this.isEdit = true) : (this.isEdit = false);

    item ? (this.editObj = item) : (this.editObj = undefined);

    if (item) {
      item.items?.map((x) => {
        const formField =
          this.dropdownData.find((i) => i.id == x.id)?.name.replace(' ', '') ||
          '';

        const patchObject: { [key: string]: any } = {};
        patchObject[formField] = x.value;

        this.tableForm.patchValue(patchObject);
      });
    } else {
      this.tableForm.reset();
    }

    this.isVisible = true;
  }

  onExpandChange(id: number): void {
    if (this.expandSet) {
      if (!this.expandSet.has(id)) {
        this.expandSet.add(id);
      } else {
        this.expandSet.delete(id);
      }
    }
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  getValue(field: string, value?: string) {
    if (!value) {
      return '-';
    }

    if (field?.toLowerCase()?.includes('datetime')) {
      return moment(value).format('YYYY-MM-DD h:mm a');
    } else if (
      field?.toLowerCase()?.includes('date') ||
      field?.toLowerCase()?.includes('onset')
    ) {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return value;
    }
  }

  checkReference(value: any, refValue?: IReferenceValue): boolean {
    if (typeof value == 'number' && refValue) {
      if (value < refValue?.min! || value > refValue?.max!) {
        return true;
      }
    }
    return false;
  }

  checkRV(value: any, refValue?: IReferenceValue): string {
    if (typeof value == 'number' && refValue) {
      if (!refValue?.mid) {
        if (value < refValue?.min! || value > refValue?.max!) {
          return 'red';
        }
      } else {
        if (value > refValue?.max!) {
          return 'red';
        } else if (value > refValue?.mid!) {
          return 'orange';
        }
      }
    }
    return '';
  }

  handleCancel() {
    this.tableForm.reset();

    this.itemSubmitted = false;

    this.isLoading = false;

    this.isVisible = false;

    this.modalRef.destroy();
  }

  submitForm() {
    this.isLoading = true;
    this.itemSubmitted = true;

    if (this.tableForm.valid) {
      const labObj: IAddDynamic = {};
      labObj.groupId = this.groupId;
      labObj.items = [];

      for (const i in this.columnIDs) {
        const lid = parseInt(this.columnIDs[i].toString());
        const val =
          this.tableForm.value[
            this.dropdownData
              .find((j) => j.id! == this.columnIDs[i])
              ?.name.replace(' ', '')!
          ];

        labObj.items?.push({
          id: lid,
          value: val,
        });
      }

      if (this.isEdit) {
        labObj.id = this.editObj?.id?.toString() || '';
        labObj.date = this.editObj?.date;

        this.dynamicService.editDynamic(this.endpoint, labObj).subscribe({
          next: (d) => {
            this.swalToastService.toastSuccess(
              this.title,
              'added successfully'
            );

            this.handleCancel();
            this.fetchData();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to add');

            this.isVisible = false;
            this.isLoading = false;
          },
        });
      } else {
        labObj.date = new Date().toISOString().split('T')[0];

        this.dynamicService.addDynamic(this.endpoint, labObj).subscribe({
          next: (d) => {
            this.swalToastService.toastSuccess(
              this.title,
              'added successfully'
            );

            this.handleCancel();
            this.fetchData();
          },
          error: (err) => {
            this.swalToastService.toastError('Unable to add');

            this.isVisible = false;
            this.isLoading = false;
          },
        });
      }
    } else {
      this.isLoading = false;
    }
  }

  deleteFun(id: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'Are you sure that you want to delete this'
      )} ${this.translate.instant(this.title)}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.dynamicService
          .deleteDynamic(this.endpoint, id.toString())
          .subscribe({
            next: (d) => {
              this.swalToastService.toastSuccess(
                this.title,
                'deleted successfully'
              );

              this.fetchData();
            },
            error: (err) => {
              this.swalToastService.toastError('Unable to delete');
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant(this.title)} ${this.translate.instant(
            'is not deleted'
          )}`,
          '',
          'info'
        );
      }
    });
  }
}
