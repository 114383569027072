/* src/app/components/ehr/admission/admission-profile/admission-timeline-history/admission-timeline-history.component.css */
::ng-deep .ant-timeline-item-head {
  border-width: 4px !important;
}
::ng-deep .ant-timeline-item {
  padding-bottom: 40px;
}
.edit-btn {
  margin-right: 10px;
  margin-left: 5px;
  cursor: pointer;
}
.break {
  font-weight: 600;
}
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
.timeline-submitted .timeline.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.timeline-history-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.timeline-container {
  margin-top: 1em;
  width: 100%;
}
.form-container {
  margin-top: 1em;
  margin-bottom: 1em;
  width: max(35%, 500px);
}
.add-form-container {
  margin-top: 1em;
  margin-bottom: 1em;
  width: max(35%, 500px);
}
::ng-deep nz-select {
  width: 100% !important;
}
::ng-deep nz-select.ng-invalid.ng-touched {
  border: red 1px solid !important;
  border-radius: 2px;
}
.timeline-submitted .timeline.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.not-visible {
  display: none !important;
}
.error-msg {
  color: red;
}
.header-note {
  width: 100%;
  padding-bottom: 3em;
}
.f-500 {
  font-weight: 500;
}
/*# sourceMappingURL=admission-timeline-history.component-CQCMYBGR.css.map */
