import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CanLeaveGuard } from 'src/app/guards/can-leave.guard';
import { EpisodeOfCareService } from 'src/app/services/episodeOfCare.service';
import { HysteroscopyFileService } from 'src/app/services/hyseroscopyFile.service';
import { PatientService } from 'src/app/services/patient.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-hysteroscopy-start-examination',
  templateUrl: './hysteroscopy-start-examination.component.html',
  styleUrls: ['./hysteroscopy-start-examination.component.css'],
})
export class HysteroscopyStartExaminationComponent {
  @Input() isPreview: boolean = true;

  constructor(
    private readonly eocService: EpisodeOfCareService,
    private router: Router,
    private canLeaveGuard: CanLeaveGuard,
    private patientService: PatientService,
    private hysteroscopyFileService: HysteroscopyFileService,
    private readonly permissionService: PermissionService
  ) {}

  startVideoStream() {
    this.hysteroscopyFileService.setExaminationStatus(true);
    // this.router.navigate(['/ehr/hysteroscopy-examination']);
  }

  isEOC() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('episodeOfCare')
    );
  }

  isInpatientView() {
    return (
      !(
        this.eocService.currentEocId() === null ||
        this.eocService.currentEocId() === ''
      ) && this.router.url.includes('inPatientView')
    );
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }
}
