import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { IPatient } from '../interfaces/IPatient';
import { ISearchPatient } from '../interfaces/ISearchPatient';
import { IPatientDetailed } from '../interfaces/Detailed/IPatientDetailed';
import { IDefinition, IDefinitions } from '../interfaces/IDefinitions';
import { saveAs } from 'file-saver-es';
import { IAdmissionFile } from '../interfaces/AdmissionPatient/AdmissionProfile/IAdmissionFile';
import { IAddDocument } from '../interfaces/AdmissionPatient/AdmissionProfile/IAddDocument';
import {
  IExistingPatient,
  IExistingPatientResponse,
} from '../interfaces/IExistingPatient';
import { TranslateService } from '@ngx-translate/core';
import { IExternalCymaDoctorDropdown } from '../interfaces/ExternalDoctors/IExternalCymaDoctorDropdown';
import { SwalToastService } from './swal.service';

@Injectable()
export class PatientService {
  public patientInfoSub = new BehaviorSubject<IPatientDetailed>({});

  public patientLoadingSub = new BehaviorSubject<boolean>(false);

  public patientIDsignal = signal('');

  public definitionsSignal = signal([] as IDefinition[]);

  public documentsSub = new BehaviorSubject<IAdmissionFile[]>([]);

  constructor(
    private _http: HttpClient,
    private translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  getPatients(
    paramsObj: ISearchPatient,
    pageSize: number = 10,
    pageNumber: number = 1
  ): Observable<IGenericServiceResponse<IPatient[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IPatient[]>>(
      `${environment.BACKEND_URL}Patient/Search/${pageNumber}/${pageSize}`,
      {
        headers: headers,
        params: new HttpParams({ fromObject: paramsObj as any }),
      }
    );
  }
  getPatientsGOC(
    paramsObj: ISearchPatient,
    pageSize: number = 10,
    pageNumber: number = 1
  ): Observable<IGenericServiceResponse<IPatient[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IPatient[]>>(
      `${environment.BACKEND_URL}Patient/SearchPilotStudy/${pageNumber}/${pageSize}`,
      {
        headers: headers,
        params: new HttpParams({ fromObject: paramsObj as any }),
      }
    );
  }

  getPatientByID(
    id: string
  ): Observable<IGenericServiceResponse<IPatientDetailed>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IPatientDetailed>>(
      `${environment.BACKEND_URL}Patient/${id}`,
      {
        headers: headers,
      }
    );
  }

  getDefinitions(): Observable<IGenericServiceResponse<IDefinitions>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IDefinitions>>(
      `${environment.BACKEND_URL}Definitions`,
      {
        headers: headers,
      }
    );
  }

  async getFiles(patientId: string) {
    this.getPatientFiles(patientId).subscribe({
      next: (response) => {
        this.documentsSub.next(response.data);
      },
      error: (err) => {
        this.swalToastService.toastError('Unable to retrieve documents');
        console.error('Error fetching Documents:', err);
      },
    });
  }

  getPatientFiles(
    patientId: string
  ): Observable<IGenericServiceResponse<IAdmissionFile[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.get<IGenericServiceResponse<IAdmissionFile[]>>(
      `${environment.BACKEND_URL}Patient/Files/${patientId}`,
      {
        headers: headers,
      }
    );
  }

  addDocument(documentObj: IAddDocument): Observable<any> {
    const formData = new FormData();

    // TO BE CHANGED
    if (documentObj?.files) {
      documentObj.files.forEach((file, index) => {
        formData.append(`Files`, file);
      });
    }

    formData.append('FileTypeId', documentObj?.fileTypeId!);
    formData.append('PatientId', documentObj?.patientId!);

    return this._http.post(
      `${environment.BACKEND_URL}Patient/UploadPatientFile`,
      formData
    );
  }

  getProfilePicture(id: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http
      .get(`${environment.BACKEND_URL}Patient/DownloadProfilePicture/${id}`, {
        headers: headers,
        responseType: 'blob',
      })
      .pipe(
        catchError((error) => {
          console.error('Error downloading profile picture:', error);
          throw error; // rethrow error to propagate it to the subscriber
        })
      );
  }

  downloadDocument(fileName: string, fileId: string, patientId: string): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .get(
        `${environment.BACKEND_URL}Patient/DownloadPatientFile/${fileId}/${patientId}`,
        {
          headers: headers,
          responseType: 'blob',
        }
      )
      .subscribe(
        (blob) => {
          const file = new Blob([blob], { type: 'application/pdf' });
          saveAs(file, fileName);

          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        },
        (error) => {
          this.swalToastService.toastError('Unable to download the document');
          console.error('Error downloading PDF:', error);
        }
      );
  }

  deleteDocument(
    fileId: string,
    patientId: string
  ): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}Patient/DeletePatientFile/${fileId}/${patientId}`,
      {
        headers: headers,
      }
    );
  }

  exportAdmissionData(paramsObj: ISearchPatient): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this._http
      .get(
        `${environment.BACKEND_URL}PatientRegistration/ExportAdmissionData`,
        {
          headers: headers,
          responseType: 'blob',
          params: new HttpParams({ fromObject: paramsObj as any }),
        }
      )
      .subscribe(
        (blob) => {
          const file = new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(file, 'Patients_' + this.formatDateToCustom() + '.xlsx');
        },
        (error) => {
          this.swalToastService.toastError('Unable to download the document');
          console.error('Error downloading PDF:', error);
        }
      );
  }

  formatDateToCustom(): string {
    const date = new Date();
    return `${date.getFullYear()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}${date
      .getHours()
      .toString()
      .padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
  }

  updateAdmissionPatient(patientId: number, patientObj: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}Patient/${patientId}`,
      patientObj,
      {
        headers: headers,
      }
    );
  }

  patientExists(
    existingPatient: IExistingPatient
  ): Observable<IGenericServiceResponse<IExistingPatientResponse>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post<IGenericServiceResponse<IExistingPatientResponse>>(
      `${environment.BACKEND_URL}Patient/Exists`,
      existingPatient,
      {
        headers: headers,
      }
    );
  }

  getExternalCymaDoctors(): Observable<IExternalCymaDoctorDropdown[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http
      .get<{ data: { externalCymaDoctors: IExternalCymaDoctorDropdown[] } }>(
        `${environment.BACKEND_URL}Patient/ExternalDoctors`,
        { headers }
      )
      .pipe(
        map((response) => response?.data?.externalCymaDoctors || []) // Safely extract the array
      );
  }
}
