/* src/app/components/ehr/workspace/my-patients/my-patients.component.css */
.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}
nz-content {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 1em;
}
.patient-list {
  max-height: 85vh;
  min-height: 100%;
  min-width: 350px;
  flex: 0 1 100% !important;
}
.patient-details {
  min-height: 100%;
  max-height: 85vh;
}
.details-container {
  overflow-y: auto;
  max-height: 80vh;
}
.my-patients-content {
  min-height: 85vh;
}
.content-layout {
  border-radius: 10px;
}
.content-header {
  border-radius: 10px;
}
.content-patients {
  border-radius: 10px;
  background-color: white;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  flex-direction: column;
}
.content-body {
  border-radius: 10px;
  background-color: white;
  max-width: 100% !important;
  padding: 1em;
}
.mat-mdc-input-element {
  height: 35px !important;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}
mat-row:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}
::ng-deep .mat-mdc-paginator-outer-container {
  margin-top: 0em;
  box-shadow: none !important;
}
::ng-deep .mat-elevation-z8 {
  box-shadow: none;
}
mat-row {
  min-height: 75px;
}
.patient-name {
  font-weight: 500;
  font-size: 15px;
}
::ng-deep .mat-column-age {
  max-width: 150px;
  min-width: 150px;
}
::ng-deep .mat-mdc-paginator-page-size {
  display: none !important;
}
::ng-deep .mat-mdc-paginator-range-actions {
  width: 100%;
  justify-content: center;
}
::ng-deep .no-patients {
  margin-top: 2em;
  margin-bottom: 1em;
}
::ng-deep .no-paginator .mat-mdc-paginator-range-actions {
  display: none;
}
.search-bar {
  width: 100%;
  padding-bottom: 4px;
  background-color: #f4f4f6;
}
.search-input {
  border: 1px solid #d9d9d9 !important;
  line-height: 1.5715;
  height: 30px;
  border-radius: 4px;
}
.search-btn {
  line-height: 1.5715;
  height: 30px;
  border-radius: 4px;
  color: #313131;
  border: 1px solid #b1b1b1 !important;
}
.search-btn:hover {
  background-color: rgb(234, 234, 234);
  color: black;
  cursor: pointer;
}
mat-paginator {
  margin-top: auto !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.search-input:focus {
  outline: 1px solid #b1b1b1;
  outline-offset: -1px;
}
.entry-info {
  width: 100%;
  padding: 1em;
  border-bottom: 1px solid #9c9c9c;
  background-color: transparent;
}
.medication-info {
  width: 100%;
  padding: 1em;
  background-color: transparent;
  font-weight: 400;
}
.allergy-info {
  width: 100%;
  padding: 1em;
  background-color: transparent;
  font-weight: 400;
}
.icon {
  margin-right: 5px;
}
.clock-icon,
.calendar-icon {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.5);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 100, 100, 0.5);
}
.package-details {
  margin-bottom: 20px;
}
.allergy-description,
.reaction-description {
  margin-top: 20px;
}
.diagnosis-assertion {
  margin-top: 20px;
}
.package {
  font-weight: 500;
}
.mat-expansion-panel-header {
  padding: 0px 20px 0px 0px;
  background-color: #f8f8f8;
  height: auto !important;
  border-bottom: 1px solid #9c9c9c;
}
::ng-deep .mat-expansion-panel-body {
  background-color: white;
  border-bottom: 1px solid #9c9c9c;
  padding: 10px !important;
}
.mat-expanded {
  background-color: white !important;
  border-bottom: 0px solid red;
}
.mat-expansion-panel {
  box-shadow: unset !important;
}
.f-500 {
  font-weight: 500;
}
/*# sourceMappingURL=my-patients.component-R4FTFIOV.css.map */
