<nz-modal #modalRef nzSize [nzVisible]="true" [nzTitle]="'Admission Timeline' | translate" (nzOnCancel)="closeModal()"
    nzOkType="primary" nzCancelText="Cancel" nzOkText="Save" nzOkType="primary" [nzFooter]="null">
    <ng-container *nzModalContent>
        <span class="timeline-history-container">
            <div [visible]="!editTimeline && !addTimeline && !isLoading" class="timeline-container">
                <nz-timeline nzMode="left">
                    <nz-timeline-item *ngIf="data?.patientCreationDate" [nzDot]="breakTemplate" nzPosition="right">
                        <span class="break">{{"Admission Creation" | translate}}</span>
                    </nz-timeline-item>
                    <nz-timeline-item [nzColor]="getColor(1)" [nzLabel]="formatDate(data?.patientCreationDate)">
                        <i nz-icon nzType="icons:form" nzTheme="outline" class="edit-btn"
                            (click)="openCreateForm(data?.patientCreationDate!)"></i>
                        {{"Creation Date" | translate}}
                    </nz-timeline-item>

                    <nz-timeline-item [nzDot]="breakTemplate" nzPosition="left" [nzLabel]="historyTemplate">
                        <button nz-button (click)="openAddForm()">{{"Add Status History" | translate}}</button>
                    </nz-timeline-item>
                    <ng-container *ngFor="let group of data?.historicalAccommodationDataItems; let i = index">
                        <nz-timeline-item nzColor="gray" class="f-500"
                            [nzLabel]="this.translate.instant('Group') + ' ' + (i+1)">
                            <i nz-icon nzType="icons:delete" nzTheme="outline" class="edit-btn"
                                (click)="deleteGroup(group.groupId)"></i>
                        </nz-timeline-item>
                        <ng-container *ngFor="let historyItem of group.accommodationDataItems">
                            <nz-timeline-item [nzColor]="getColor(historyItem.statusId)"
                                [nzLabel]="formatDate(historyItem.statusDate)">
                                <i nz-icon nzType="icons:form" nzTheme="outline" class="edit-btn"
                                    (click)="openEditForm(historyItem.statusDate, historyItem.statusId, historyItem.id)"></i>
                                {{getStatusName(historyItem.statusId)}}
                            </nz-timeline-item>
                        </ng-container>
                    </ng-container>

                    <nz-timeline-item *ngIf="data?.systemAccommodationDataItems" [nzDot]="breakTemplate"
                        nzPosition="right">
                        <span class="break"
                            [nz-tooltip]="'Actions recorded through the platform.' | translate">{{"Current" |
                            translate}}</span>
                    </nz-timeline-item>
                    <ng-container *ngFor="let group of data?.systemAccommodationDataItems; let i = index">
                        <nz-timeline-item nzColor="gray" class="f-500"
                            [nzLabel]="this.translate.instant('Group') + ' ' + (i+1)"></nz-timeline-item>
                        <ng-container *ngFor="let item of group.accommodationDataItems">
                            <nz-timeline-item [nzColor]="getColor(item.statusId)"
                                [nzLabel]="formatDate(item.statusDate)">
                                {{getStatusName(item.statusId)}}
                            </nz-timeline-item>
                        </ng-container>
                    </ng-container>
                </nz-timeline>

                <ng-template #breakTemplate>⏱️</ng-template>
                <ng-template #historyTemplate>
                    <span class="break">{{"Status History" | translate}}</span>
                </ng-template>
            </div>

            <div [visible]="editTimeline" class="form-container">
                <form [formGroup]="timelineEditForm" (ngSubmit)="submitEditForm()"
                    [ngClass]="{'timeline-submitted': timelineSubmitted, 'timeline-not-submitted': !timelineSubmitted}">


                    <div class="form-row" fxLayout="row" fxLayoutAlign="start center">
                        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="50">
                            <label>{{"Action Date" | translate}}</label>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="start center" fxFlex="50" fxLayoutAlign="start center">
                            <input class="inputs timeline" type="datetime-local" formControlName="actionDate"
                                [min]="this.timelineEditForm.get('minDate')?.value"
                                [max]="this.timelineEditForm.get('maxDate')?.value" />
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end" class="footer">
                        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                            [disabled]="isLoading">{{"Save" | translate}}</button>
                        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleEditCancel()"
                            [disabled]="isLoading">{{"Cancel" | translate}}</button>
                    </div>
                </form>
            </div>

            <div [visible]="addTimeline" class="add-form-container">
                <form [formGroup]="timelineAddForm" (ngSubmit)="submitAddForm()"
                    [ngClass]="{'timeline-submitted': timelineSubmitted, 'timeline-not-submitted': !timelineSubmitted}">

                    <div class="header-note">
                        {{"Every historical timeline should finish with a Discharge or a Reject action." | translate}}
                    </div>
                    <nz-timeline nzMode="left">
                        <nz-timeline-item [nzColor]="getColor(4)" [nzLabel]="'Pending' | translate">
                            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <div fxLayout="column" fxFlex="100">
                                    <input class="inputs timeline" type="datetime-local" formControlName="pendingDate"
                                        [min]="timelineAddForm.get('minDate')?.value"
                                        [max]=" getAdjustedDate(timelineAddForm.get('waitingListDate')?.value ||
                                        timelineAddForm.get('approveDate')?.value ||
                                        timelineAddForm.get('admissionDate')?.value ||
                                        timelineAddForm.get('dischargeDate')?.value, -1) || timelineAddForm.get('maxDate')?.value" />
                                </div>
                            </div>
                        </nz-timeline-item>

                        <nz-timeline-item *ngIf="timelineAddForm.get('pendingDate')?.value" [nzColor]="getColor(3)"
                            [nzLabel]="'Waiting List' | translate">
                            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <div fxLayout="column" fxFlex="100">
                                    <input class="inputs timeline" type="datetime-local"
                                        formControlName="waitingListDate"
                                        [min]="getAdjustedDate(timelineAddForm.get('pendingDate')?.value, 1)"
                                        [max]="getAdjustedDate(timelineAddForm.get('approveDate')?.value ||
                                        timelineAddForm.get('admissionDate')?.value ||
                                        timelineAddForm.get('dischargeDate')?.value, -1) || timelineAddForm.get('maxDate')?.value" />
                                </div>
                            </div>
                        </nz-timeline-item>

                        <nz-timeline-item
                            *ngIf="timelineAddForm.get('waitingListDate')?.value && !timelineAddForm.get('rejectDate')?.value"
                            [nzColor]="getColor(2)" [nzLabel]="'Approve' | translate">
                            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <div fxLayout="column" fxFlex="100">
                                    <input class="inputs timeline" type="datetime-local" formControlName="approveDate"
                                        [min]="getAdjustedDate(timelineAddForm.get('waitingListDate')?.value, 1)"
                                        [max]="getAdjustedDate(timelineAddForm.get('admissionDate')?.value ||
                                        timelineAddForm.get('dischargeDate')?.value, -1) || timelineAddForm.get('maxDate')?.value" />
                                </div>
                            </div>
                        </nz-timeline-item>

                        <nz-timeline-item
                            *ngIf="timelineAddForm.get('approveDate')?.value && !timelineAddForm.get('rejectDate')?.value"
                            [nzColor]="getColor(6)" [nzLabel]="'Admission' | translate">
                            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <div fxLayout="column" fxFlex="100">
                                    <input class="inputs timeline" type="datetime-local" formControlName="admissionDate"
                                        [min]="getAdjustedDate(timelineAddForm.get('approveDate')?.value, 1)"
                                        [max]="getAdjustedDate(timelineAddForm.get('dischargeDate')?.value, -1) || timelineAddForm.get('maxDate')?.value" />
                                </div>
                            </div>
                        </nz-timeline-item>

                        <nz-timeline-item
                            *ngIf="timelineAddForm.get('admissionDate')?.value && !timelineAddForm.get('rejectDate')?.value"
                            [nzColor]="getColor(7)" [nzLabel]="'Discharge' | translate">
                            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <div fxLayout="column" fxFlex="100">
                                    <input class="inputs timeline" type="datetime-local" formControlName="dischargeDate"
                                        [min]="getAdjustedDate(timelineAddForm.get('admissionDate')?.value, 1)"
                                        [max]="timelineAddForm.get('maxDate')?.value" />
                                </div>
                            </div>
                        </nz-timeline-item>

                        <nz-timeline-item
                            *ngIf="timelineAddForm.get('pendingDate')?.value && !timelineAddForm.get('approveDate')?.value"
                            [nzColor]="getColor(5)" [nzLabel]="'Reject' | translate">
                            <div class="form-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <div fxLayout="column" fxFlex="100">
                                    <input class="inputs timeline" type="datetime-local" formControlName="rejectDate"
                                        [min]="getAdjustedDate(timelineAddForm.get('waitingListDate')?.value || timelineAddForm.get('pendingDate')?.value, 1)"
                                        [max]="timelineAddForm.get('maxDate')?.value" />
                                </div>
                            </div>
                        </nz-timeline-item>
                    </nz-timeline>

                    <div *ngIf="addErrorMessage" class="error-msg">{{addErrorMessage}}</div>

                    <div fxLayout="row" fxLayoutAlign="center" class="footer">
                        <button nz-button nzPrimary class="save-btn" type="submit" [nzLoading]="isLoading"
                            [disabled]="isLoading">{{"Save" | translate}}</button>
                        <button nz-button nzDanger class="cancel-btn" type="button" (click)="handleAddCancel()"
                            [disabled]="isLoading">{{"Cancel" | translate}}</button>
                    </div>
                </form>
            </div>
            <div [visible]="isLoading" class="spinner-container"><mat-spinner></mat-spinner></div>
        </span>
    </ng-container>
</nz-modal>