<form [formGroup]="personalInfoForm" [ngClass]="{ submitted: formSubmitted }">
  <div class="layout">
    <!-- <div class="title">{{'Personal Details' | translate}}</div> -->
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <div fxLayout="column" fxFlex="15" fxFlex.lt-lg="100" fxLayoutAlign="center center"
        fxLayoutAlign.lt-lg="center start">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column" fxLayout.lt-lg="row" fxLayoutAlign="center center" style="margin-right: 20px">
            <nz-avatar [nzShape]="'circle'" [nzSize]="85" [nzIcon]="'user'" [nzSrc]="avatarImageUrl"
              style="margin-bottom: 10px"></nz-avatar>
            <button nz-button nzType="primary" [ngClass]="
                !isEditMode
                  ? 'upload-patient-picture-btn-disabled'
                  : 'upload-patient-picture-btn'
              " [disabled]="!isEditMode" (click)="uploadAndOpenImageCropper()">
              <i nz-icon nzType="download"></i>
              {{ "Upload Profile Picture" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="85" fxFlex.lt-lg="100">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="start start" class="label-row">
              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                {{ "First name" | translate }}
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                {{ "Last name" | translate }}
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                {{ "Email address" | translate }}
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                <div fxLayout="row">
                  {{ "Phone number" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" class="add-patient-row">
              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <input class="add-patient-input" formControlName="firstName" [maxLength]="50" />
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <input class="add-patient-input" formControlName="lastName" [maxLength]="50" />
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <input class="add-patient-input" formControlName="email" />
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="[CountryISO.Cyprus, CountryISO.Greece]"
                  [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                  [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]" [selectFirstCountry]="false" [maxLength]="50" [phoneValidation]="true" [separateDialCode]="false"
                  name="phone" formControlName="phone"></ngx-intl-tel-input>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20">
          <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="start start" class="label-row" fxFlex="100">
              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                {{ "Gender" | translate }}
              </div>

              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                {{ "Date of birth" | translate }}
              </div>

              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                <div fxLayout="row">
                  {{ "Place of Birth" | translate
                  }}<span class="optional">{{ "(optional)" | translate }}</span>
                </div>
              </div>

              <div fxLayout="column" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
                <div fxLayout="row">
                  {{ "District of Birth" | translate }}
                  <span class="optional">{{ "(optional)" | translate }}</span>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" class="add-patient-row" fxFlex="100">
              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="genderId">
                  <ng-container *ngFor="let gender of gendersList">
                    <nz-option [nzLabel]="gender.name" [nzValue]="gender.id"></nz-option>
                  </ng-container>
                </nz-select>
              </div>

              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <input formControlName="dateOfBirth" class="add-patient-input" type="date" />
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="placeOfBirthId"
                  (ngModelChange)="onPlaceOfBirthChange($event)">
                  <ng-container *ngFor="let place of placeOfBirthList">
                    <nz-option [nzLabel]="place.name" [nzValue]="place.id"></nz-option>
                  </ng-container>
                </nz-select>
              </div>
              <div fxLayout="column" fxFlex="25" class="add-patient-col">
                <ng-container *ngIf="!isPlaceOfBirthCyprus; else cyprusDropdown">
                  <input class="add-patient-input" [placeholder]="'District of Birth' | translate"
                    formControlName="districtOfBirth" [maxLength]="50" />
                </ng-container>
                <ng-template #cyprusDropdown>
                  <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear
                    [nzPlaceHolder]="'Select a District' | translate" formControlName="districtOfBirth">
                    <ng-container *ngFor="let city of cyprusCities">
                      <nz-option [nzLabel]="city | translate" [nzValue]="city"></nz-option>
                    </ng-container>
                  </nz-select>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- First Name and Last Name -->

      <!-- Date of Birth and Place of Birth -->

      <!-- Phone Number and Email Address -->

      <!-- Document Info -->
      <div class="title">
        {{
        (documentDto.controls.length > 1 ? "Identifiers" : "Identifier")
        | translate
        }}
      </div>
      <div formArrayName="documentDto" *ngFor="let doc of documentDto.controls; let i = index">
        <div [formGroupName]="i">
          <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
            <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
              {{ "Document Type" | translate }}
            </div>
            <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
              {{ "Document Number" | translate }}
            </div>
            <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
              {{
              doc.get("documentTypeId")?.value == 3
              ? ""
              : ("Issuing Country" | translate)
              }}
            </div>
            <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start"></div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
            <div fxLayout="col" fxFlex="25" class="add-patient-col">
              <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="documentTypeId">
                <ng-container *ngFor="let doc of documentTypeList">
                  <nz-option [nzLabel]="doc.name" [nzValue]="doc.id"></nz-option>
                </ng-container>
              </nz-select>
            </div>
            <div fxLayout="col" fxFlex="25" class="add-patient-col">
              <input class="add-patient-input not-allowed" formControlName="documentNumber" [maxLength]="50" />
            </div>
            <div fxLayout="col" fxFlex="25" class="add-patient-col">
              <nz-select *ngIf="doc.get('documentTypeId')?.value !== 3" class="add-patient-inputZorro" nzShowSearch
                nzAllowClear formControlName="documentCountryIssuedId">
                <ng-container *ngFor="let country of countriesList">
                  <nz-option [nzLabel]="country.name" [nzValue]="country.id"></nz-option>
                </ng-container>
              </nz-select>
            </div>

            <div fxLayout="col" fxFlex="25" class="add-patient-col"></div>
          </div>
        </div>
      </div>

      <div class="title">{{ "Additional Information" | translate }}</div>
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Registration Agent" | translate }}
        </div>

        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Date of Admission" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>

        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start"></div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start"></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <input type="text" class="add-patient-input not-allowed" formControlName="registrationAgentFullName"
            [maxLength]="50" />
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <input class="add-patient-input" [placeholder]="'Date of Admission' | translate"
            formControlName="dateOfAdmission" type="date" />
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col"></div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col"></div>
      </div>

      <!-- Family Status and Closest Relatives -->
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Family Status" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Closest Relatives" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>

        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Education Level" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Occupation" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="familyStatusId">
            <ng-container *ngFor="let status of familyStatusList">
              <nz-option [nzLabel]="status.name" [nzValue]="status.id"></nz-option>
            </ng-container>
          </nz-select>
          <!-- 
            Conditionally show the free text input. 
            The value 6 is the  id of option "Other" in family status
            -->
          <input *ngIf="personalInfoForm.get('familyStatusId')?.value == 6" class="add-patient-input"
            formControlName="familyStatusOther" placeholder="{{ 'Please specify' | translate }}" [maxLength]="50" />
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="closestRelativesId">
            <ng-container *ngFor="let relative of closestRelativesList">
              <nz-option [nzLabel]="relative.name" [nzValue]="relative.id"></nz-option>
            </ng-container>
          </nz-select>
          <!-- 
            Conditionally show the free text input. 
            The value 7 is the  id of option "Other" in closest relatives
            -->
          <input *ngIf="personalInfoForm.get('closestRelativesId')?.value == 7" class="add-patient-input"
            formControlName="closestRelativesOther" placeholder="{{ 'Please specify' | translate }}" [maxLength]="50" />
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="educationLevelId">
            <ng-container *ngFor="let education of educationLevelList">
              <nz-option [nzLabel]="education.name" [nzValue]="education.id"></nz-option>
            </ng-container>
          </nz-select>
          <!-- 
            Conditionally show the free text input. 
            The value 5 is the  id of option "Other" in education level
            -->
          <input *ngIf="personalInfoForm.get('educationLevelId')?.value == 5" class="add-patient-input"
            formControlName="educationLevelOther" placeholder="{{ 'Please specify' | translate }}" [maxLength]="50" />
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <input class="add-patient-input" formControlName="occupation" [maxLength]="100" />
        </div>
      </div>

      <!-- Amount of Income and Source of Income -->
      <div fxLayout="row" fxLayoutAlign="center center" class="label-row">
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Amount of Income" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Source of Income" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          {{ "Religion" | translate
          }}<span class="optional">{{ "(optional)" | translate }}</span>
        </div>

        <div fxLayout="col" fxFlex="25" class="add-patient-col" fxLayoutAlign="start start">
          <!-- <span [visible]="isPreRegistration">
            {{ "Admission Status" | translate }}</span> -->
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="add-patient-row">
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <input class="add-patient-input" formControlName="amountOfIncome" />
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="sourceOfIncomeId">
            <ng-container *ngFor="let source of sourceOfIncomeList">
              <nz-option [nzLabel]="source.name" [nzValue]="source.id"></nz-option>
            </ng-container>
          </nz-select>
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="religionId">
            <ng-container *ngFor="let religion of religionList">
              <nz-option [nzLabel]="religion.name" [nzValue]="religion.id"></nz-option>
            </ng-container>
          </nz-select>
          <!-- 
            Conditionally show the free text input. 
            The value 26 is the  id of option "Other" in religion
            -->
          <input *ngIf="personalInfoForm.get('religionId')?.value == 26" class="add-patient-input"
            formControlName="religionOther" placeholder="{{ 'Please specify' | translate }}" [maxLength]="50" />
        </div>
        <div fxLayout="col" fxFlex="25" class="add-patient-col">
          <!-- <nz-select class="add-patient-inputZorro" nzShowSearch nzAllowClear formControlName="registrationStatusId"
            [visible]="isPreRegistration">
            <ng-container *ngFor="let registrationStatus of registrationStatusDropdown">
              <nz-option [nzLabel]="registrationStatus.name" [nzValue]="registrationStatus.id"></nz-option>
            </ng-container>
          </nz-select> -->
        </div>
      </div>
    </div>
  </div>
</form>