import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IBed } from 'src/app/interfaces/Dropdown/IAccommodationDropdown';
import { IQuickAdmissionDetailed } from 'src/app/interfaces/QuickAdmission/IQuickAdmissionDetailed';

export function bedAvailabilityValidator(
  bedMessageRef: { value: string },
  beds: IBed[],
  timelineData: IQuickAdmissionDetailed
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    bedMessageRef.value = '';

    const admissionDate = control?.parent?.get('admissionDate')?.value;
    const bedId = control?.parent?.get('bedId')?.value;

    if (bedId) {
      if (bedId != timelineData?.bedId) {
        if (
          beds.find((bed) => bed.id == bedId)?.bedAvailable == false &&
          !control?.parent?.get('dischargeDate')?.value
        ) {
          bedMessageRef.value =
            'This bed is currently occupied by another patient. Choose another bed or insert the discharge details.';
          return { bedId: true };
        }
      }
    }

    if (!bedId) {
      if (admissionDate) {
        return { bedId: true };
      }
    }

    if (!admissionDate && !bedId) {
      return null;
    }

    return null;
  };
}

export function admissionReasonValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const admissionDate = control.parent?.get('admissionDate')?.value;
    const admissionReasonId = control.parent?.get('admissionReasonId')?.value;

    if (admissionDate && !admissionReasonId) {
      return { admissionReason: true };
    }

    return null;
  };
}

export function bedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const admissionDate = control.parent?.get('admissionDate')?.value;
    const bedId = control.parent?.get('bedId')?.value;

    if (admissionDate && !bedId) {
      return { bedId: true };
    }

    return null;
  };
}

export function dischargeTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dischargeDate = control.parent?.get('dischargeDate')?.value;
    const dischargeTypeId = control.parent?.get('dischargeTypeId')?.value;

    if (dischargeDate && !dischargeTypeId) {
      return { dischargeTypeId: true };
    }

    return null;
  };
}

export function dischargeSituationValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dischargeDate = control.parent?.get('dischargeDate')?.value;
    const dischargeSituationId = control.parent?.get(
      'dischargeSituationId'
    )?.value;

    if (dischargeDate && !dischargeSituationId) {
      return { dischargeSituationId: true };
    }

    return null;
  };
}

export function rejectionReasonValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const rejectedDate = control.parent?.get('rejectedDate')?.value;
    const rejectionReasonId = control.parent?.get('rejectionReasonId')?.value;

    if (rejectedDate && !rejectionReasonId) {
      return { rejectionReasonId: true };
    }

    return null;
  };
}
