<div
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap="20px"
  [ngClass]="{ preview: isPreview }"
>
  <div [fxFlex]="!isPreview ? '50' : '100'" fxFlex.lt-lg="100">
    <nz-layout class="table-margin">
      <nz-header class="table-header">
        <!--  -->
        <div
          *ngIf="isEOC() || isInpatientView() || isPreview"
          class="table-header-row margin-lr"
        >
          <div class="table-header-cell">
            {{ "Hysteroscopy Examination" | translate }}
          </div>
          <!--  -->
          <div class="table-header-cell margin-lrr">
            <button
              nz-button
              nzShape="round"
              (click)="startVideoStream()"
              *ngIf="(isEOC() || isInpatientView()) && hasPermission('a-hy')"
            >
              <div fxLayout="row">
                <div fxLayout="col">
                  <mat-icon class="add-icon">add_circle_outline</mat-icon>
                </div>
                <div fxLayout="col" class="add-title">
                  {{ "Start" | translate }} {{ "Examination" | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </nz-header>
    </nz-layout>
  </div>
</div>
