/* src/app/components/ehr/patients/components/medical-problems-section/procedure/procedure-add-edit/procedure-add-edit.component.css */
.empty-table {
  margin-bottom: 3em;
}
.allergies-submitted .allergy.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
.reactions-submitted .reaction.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=procedure-add-edit.component-3QSO3OX7.css.map */
