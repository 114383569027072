/* src/app/components/ehr/patients/components/medication-summary-section/medication-add/medication-add.component.css */
nz-content {
  background: white;
}
.table-content {
  border: 1px solid #d8d8d8 !important;
}
.table-margin {
  margin-top: 1em;
  margin-bottom: 1em;
}
.margin-t {
  margin-top: 1em;
}
::ng-deep .ant-radio-checked,
::ng-deep .ant-radio-inner,
::ng-deep .ant-radio-checked::after {
  border-color: var(--color-1) !important;
}
::ng-deep .ant-radio-inner::after {
  background-color: var(--color-1) !important;
}
.width-100 {
  width: 100% !important;
}
.margin-1 {
  margin: 1em;
}
.padding-1 {
  padding: 1em;
}
.margin-15 {
  margin: 1.5em;
}
textarea.disabled-txtarea {
  resize: none;
  background: #f4f4f6;
  border: 1px solid #d9d9d9;
}
textarea {
  color: black;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 0px solid #d9d9d9;
}
.label {
  color: black;
  font-weight: 500;
  font-size: 14px;
}
.radio-label {
  color: black;
  font-weight: 500;
  font-size: 16px;
}
input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
}
.search-group {
  border: 1px solid #d9d9d9;
}
::ng-deep .ant-layout-header {
  height: 50px;
}
.medication-submitted .medication.ng-invalid {
  border: red 1px solid !important;
  border-radius: 2px;
}
/*# sourceMappingURL=medication-add.component-XSRNZI4T.css.map */
