import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import Swal from 'sweetalert2';

export interface CanComponentLeave {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  hasUnsavedQuestionnaireChanges?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanLeaveGuard implements CanDeactivate<CanComponentLeave> {
  constructor(private readonly translate: TranslateService) {}

  canDeactivate(
    component: CanComponentLeave
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.hasUnsavedQuestionnaireChanges) {
      Swal.fire({
        text: `${this.translate.instant(
          'You have unsaved changes in the questionnaire. Please submit to continue.'
        )}`,
        icon: 'warning',
        showConfirmButton: true,
      });
      return false;
    }

    if (component.canDeactivate) {
      return component.canDeactivate();
    }

    return new Promise<boolean>((resolve) => {
      Swal.fire({
        text: `${this.translate.instant(
          'Are you sure you want to leave this visit'
        )}?`,
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: `${this.translate.instant('Discard Visit')}`,
        confirmButtonText: `${this.translate.instant('Save as Draft')}`,
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true); // Allow navigation
        } else if (result.isDenied) {
          resolve(true); // Also allow navigation, but you can handle this differently if needed
        } else {
          resolve(false); // Cancel navigation
        }
      });
    });
  }
}
