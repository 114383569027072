import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBed } from 'src/app/interfaces/Dropdown/IAccommodationDropdown';
import { IAddQuickAdmission } from 'src/app/interfaces/QuickAdmission/IAddQuickAdmission';
import { IQuickAdmissionDetailed } from 'src/app/interfaces/QuickAdmission/IQuickAdmissionDetailed';
import { IQuickAdmissionDropdown } from 'src/app/interfaces/QuickAdmission/IQuickAdmissionDropdown';
import {
  admissionReasonValidator,
  bedAvailabilityValidator,
  bedValidator,
  dischargeSituationValidator,
  dischargeTypeValidator,
  rejectionReasonValidator,
} from './admission-timeline.validators';
import { QuickAdmissionService } from 'src/app/services/quickAdmission.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SwalToastService } from 'src/app/services/swal.service';

@Component({
  selector: 'app-admission-timeline',
  templateUrl: './admission-timeline.component.html',
  styleUrl: './admission-timeline.component.css',
})
export class AdmissionTimelineComponent {
  @Input() admissionTimelineData!: IQuickAdmissionDetailed;
  @Input() timelineDropdownData: IQuickAdmissionDropdown = {};
  @Input() patientId: string = '';

  @Output() isVisibleChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  isLoading: boolean = false;
  isSaving: boolean = false;

  timelineForm: FormGroup = this.formBuilder.group({});

  public beds: IBed[] = [];
  bedMessageRef = { value: '' };

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly quickAdmissionService: QuickAdmissionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService
  ) {}

  ngOnInit() {
    this.timelineForm = this.formBuilder.group({
      patientCreationDate: [null],
      pendingDate: [null],
      waitingListDate: [null],
      approvedDate: [null],
      approvedNotes: [''],
      admissionDate: [null, []],
      admissionReasonId: [null, [admissionReasonValidator()]],
      admissionNotes: [''],
      rejectedDate: [null, []],
      rejectionReasonId: [null, [rejectionReasonValidator()]],
      rejectedNotes: [''],
      dischargeDate: [null, []],
      dischargeTypeId: [null, [dischargeTypeValidator()]],
      dischargeSituationId: [null, [dischargeSituationValidator()]],
      dischargeTypeNotes: [''],
      dischargeSituationNotes: [''],
      bedId: [null, [bedValidator()]],
    });

    this.fetchAllData();

    this.timelineForm
      .get('patientCreationDate')
      ?.valueChanges.subscribe((value) => {
        if (!value) {
          this.timelineForm
            .get('patientCreationDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('pendingDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('waitingListDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('approvedDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('admissionDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('rejectedDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('dischargeDate')
            ?.setValue(null, { emitEvent: false });
        }
      });

    this.timelineForm.get('pendingDate')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.timelineForm
          .get('pendingDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('waitingListDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('approvedDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('admissionDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('rejectedDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('dischargeDate')
          ?.setValue(null, { emitEvent: false });
      }
    });

    this.timelineForm
      .get('waitingListDate')
      ?.valueChanges.subscribe((value) => {
        if (!value) {
          this.timelineForm
            .get('waitingListDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('approvedDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('admissionDate')
            ?.setValue(null, { emitEvent: false });

          this.timelineForm
            .get('dischargeDate')
            ?.setValue(null, { emitEvent: false });
        }
      });

    this.timelineForm.get('approvedDate')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.timelineForm
          .get('approvedDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('admissionDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('dischargeDate')
          ?.setValue(null, { emitEvent: false });
      } else {
        this.timelineForm
          .get('rejectedDate')
          ?.setValue(null, { emitEvent: false });
      }
    });

    this.timelineForm.get('admissionDate')?.valueChanges.subscribe((value) => {
      this.timelineForm.get('admissionReasonId')?.updateValueAndValidity();
      this.timelineForm.get('bedId')?.updateValueAndValidity();

      if (!value) {
        this.timelineForm
          .get('admissionDate')
          ?.setValue(null, { emitEvent: false });

        this.timelineForm
          .get('dischargeDate')
          ?.setValue(null, { emitEvent: false });
      }
    });

    this.timelineForm.get('rejectedDate')?.valueChanges.subscribe((value) => {
      this.timelineForm.get('rejectionReasonId')?.updateValueAndValidity();

      if (!value) {
        this.timelineForm
          .get('rejectedDate')
          ?.setValue(null, { emitEvent: false });
      }
    });

    this.timelineForm.get('dischargeDate')?.valueChanges.subscribe((value) => {
      this.timelineForm.get('dischargeTypeId')?.updateValueAndValidity();
      this.timelineForm.get('dischargeSituationId')?.updateValueAndValidity();

      if (!value) {
        this.timelineForm
          .get('dischargeDate')
          ?.setValue(null, { emitEvent: false });
      }
    });
  }

  fetchAllData() {
    this.isLoading = true;

    forkJoin({
      timelineData: this.quickAdmissionService.getTimeline(this.patientId),
      dropdownData: this.quickAdmissionService.getDropdownData(),
    }).subscribe({
      next: ({ timelineData, dropdownData }) => {
        this.admissionTimelineData = timelineData.data;
        this.timelineDropdownData = dropdownData;

        this.beds =
          this.timelineDropdownData?.admissionDropdownData?.bedAvailableId?.flatMap(
            (building) =>
              building.floors.flatMap((floor) =>
                floor.wards.flatMap((ward) =>
                  ward.rooms.flatMap((room) =>
                    room.beds.map((bed) => ({
                      id: bed.id,
                      name: `${building.name} - ${floor.name} - ${ward.name} - ${room.name} - ${bed.name}`,
                      bedAvailable: bed.bedAvailable,
                    }))
                  )
                )
              )
          ) || [];

        // first patch then disable!
        this.patchFormValues(this.admissionTimelineData);
        this.disabledFilledInformation();

        this.isLoading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.swalToastService.toastError(
          'Unable to retrive Admission Timeline data'
        );

        console.error('Error fetching data:', err);
      },
    });
  }

  patchFormValues(admissionTimelineData: IQuickAdmissionDetailed) {
    if (admissionTimelineData) {
      this.timelineForm.patchValue({
        patientCreationDate: this.convertToLocale(
          admissionTimelineData.patientCreationDate
        ),
        pendingDate: this.convertToLocale(admissionTimelineData.pendingDate),
        waitingListDate: this.convertToLocale(
          admissionTimelineData.waitingListDate
        ),
        approvedDate: this.convertToLocale(admissionTimelineData.approvedDate),
        approvedNotes: admissionTimelineData.approvedNotes || '',
        admissionReasonId: admissionTimelineData.admissionReasonId,
        admissionNotes: admissionTimelineData.admissionNotes,
        admissionDate: this.convertToLocale(
          admissionTimelineData.admissionDate
        ),
        rejectedDate: this.convertToLocale(admissionTimelineData.rejectedDate),
        rejectionReasonId: admissionTimelineData.rejectionReasonId || null,
        rejectedNotes: admissionTimelineData.rejectedNotes || '',
        dischargeDate: this.convertToLocale(
          admissionTimelineData.dischargeDate
        ),
        dischargeTypeId: admissionTimelineData.dischargeTypeId || null,
        dischargeSituationId:
          admissionTimelineData.dischargeSituationId || null,
        dischargeTypeNotes: admissionTimelineData.dischargeTypeNotes || '',
        dischargeSituationNotes:
          admissionTimelineData.dischargeSituationNotes || '',
        bedId: admissionTimelineData.bedId,
      });
    }
    this.cdr.detectChanges();
  }

  saveChanges() {
    if (!this.timelineForm.invalid) {
      const updatedData: IAddQuickAdmission = {
        patientCreationDate: this.timelineForm.value.patientCreationDate
          ? moment(this.timelineForm.value.patientCreationDate).utc().format()
          : undefined,
        pendingDate: this.timelineForm.value.pendingDate
          ? moment(this.timelineForm.value.pendingDate).utc().format()
          : undefined,
        waitingListDate: this.timelineForm.value.waitingListDate
          ? moment(this.timelineForm.value.waitingListDate).utc().format()
          : undefined,
        approvedDate: this.timelineForm.value.approvedDate
          ? moment(this.timelineForm.value.approvedDate).utc().format()
          : undefined,
        approvedNotes:
          this.admissionTimelineData.approvedNotes ||
          this.timelineForm.value.approvedNotes,
        admissionReasonId:
          this.admissionTimelineData.admissionReasonId ||
          this.timelineForm.value.admissionReasonId,
        admissionNotes:
          this.admissionTimelineData.admissionNotes ||
          this.timelineForm.value.admissionNotes,
        admissionDate: this.timelineForm.value.admissionDate
          ? moment(this.timelineForm.value.admissionDate).utc().format()
          : undefined,
        rejectedDate:
          this.timelineForm.value.rejectedDate &&
          !this.timelineForm.value.approvedDate
            ? moment(this.timelineForm.value.rejectedDate).utc().format()
            : undefined,
        rejectionReasonId: !this.timelineForm.value.approvedDate
          ? this.admissionTimelineData.approvedDate ||
            this.timelineForm.value.rejectionReasonId
          : undefined,
        rejectedNotes: !this.timelineForm.value.approvedDate
          ? this.admissionTimelineData.rejectedNotes ||
            this.timelineForm.value.rejectedNotes
          : undefined,
        dischargeDate: this.timelineForm.value.dischargeDate
          ? moment(this.timelineForm.value.dischargeDate).utc().format()
          : undefined,
        dischargeTypeId:
          this.admissionTimelineData.dischargeTypeId ||
          this.timelineForm.value.dischargeTypeId,
        dischargeSituationId:
          this.admissionTimelineData.dischargeSituationId ||
          this.timelineForm.value.dischargeSituationId,
        dischargeTypeNotes:
          this.admissionTimelineData.dischargeTypeNotes ||
          this.timelineForm.value.dischargeTypeNotes,
        dischargeSituationNotes:
          this.admissionTimelineData.dischargeSituationNotes ||
          this.timelineForm.value.dischargeSituationNotes,
        bedId:
          this.admissionTimelineData.bedId || this.timelineForm.value.bedId,
      };

      this.isSaving = true;
      this.quickAdmissionService
        .updateQuickAdmission(this.patientId, updatedData)
        .subscribe({
          next: (response) => {
            this.swalToastService.toastSuccess(
              'Admission Timeline edited successfully'
            );
            this.closeModal();
            this.isSaving = false;
            window.location.reload();
          },
          error: (error) => {
            this.swalToastService.toastError(
              'Unable to edit Admission Timeline'
            );

            console.log(error);
          },
        });
    }
  }

  closeModal() {
    this.isVisibleChange.emit(false);
  }

  getCurrentDate() {
    return moment().format().slice(0, 16);
  }

  formatLimit(date?: string) {
    return date ? moment(date).format().slice(0, 16) : undefined;
  }

  convertToLocale(date?: string) {
    if (date) {
      const currentDateTime = date ? moment(date).format() : moment().format();

      const isoDate = currentDateTime.slice(0, 16);

      return isoDate;
    }

    return null;
  }

  disabledFilledInformation() {
    this.admissionTimelineData.approvedDate &&
      this.timelineForm.get('approvedNotes')?.disable();

    this.admissionTimelineData.admissionDate &&
      this.timelineForm.get('admissionReasonId')?.disable();

    this.admissionTimelineData.admissionDate &&
      this.timelineForm.get('admissionNotes')?.disable();

    this.admissionTimelineData.admissionDate &&
      this.timelineForm.get('bedId')?.disable();

    this.admissionTimelineData.rejectedDate &&
      this.timelineForm.get('rejectReasonId')?.disable();

    this.admissionTimelineData.rejectedDate &&
      this.timelineForm.get('rejectNotes')?.disable();

    this.admissionTimelineData.dischargeDate &&
      this.timelineForm.get('dischargeTypeId')?.disable();

    this.admissionTimelineData.dischargeDate &&
      this.timelineForm.get('dischargeSituationId')?.disable();

    this.admissionTimelineData.dischargeDate &&
      this.timelineForm.get('dischargeTypeNotes')?.disable();

    this.admissionTimelineData.dischargeDate &&
      this.timelineForm.get('dischargeSituationNotes')?.disable();

    this.admissionTimelineData.patientCreationDate &&
      this.timelineForm
        .get('patientCreationDate')
        ?.setValidators([Validators.required]);

    this.admissionTimelineData.pendingDate &&
      this.timelineForm
        .get('pendingDate')
        ?.setValidators([Validators.required]);

    this.admissionTimelineData.waitingListDate &&
      this.timelineForm
        .get('waitingListDate')
        ?.setValidators([Validators.required]);

    this.admissionTimelineData.approvedDate &&
      this.timelineForm
        .get('approvedDate')
        ?.setValidators([Validators.required]);

    this.admissionTimelineData.admissionDate &&
      this.timelineForm
        .get('admissionDate')
        ?.setValidators([Validators.required]);

    this.admissionTimelineData.rejectedDate &&
      this.timelineForm
        .get('rejectedDate')
        ?.setValidators([Validators.required]);

    this.admissionTimelineData.dischargeDate &&
      this.timelineForm
        .get('dischargeDate')
        ?.setValidators([Validators.required]);

    this.timelineForm
      .get('bedId')
      ?.setValidators([
        bedAvailabilityValidator(
          this.bedMessageRef,
          this.beds,
          this.admissionTimelineData
        ),
        bedValidator(),
      ]);
  }
}
