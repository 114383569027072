import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IGenericServiceResponse,
  IServiceResponse,
} from '../interfaces/IServiceResponse';
import { environment } from 'src/environments/environment';
import { EpisodeOfCareService } from './episodeOfCare.service';
import { PatientService } from './patient.service';
import { IDynamicDropdown } from '../interfaces/DynamicTable/IDynamicDropdown';
import {
  IDynamicTable,
  IDynamicItem,
} from 'src/app/interfaces/DynamicTable/IDynamicDetailed';
import { IAddDynamic } from 'src/app/interfaces/DynamicTable/IAddDynamic';
import { COLORS } from 'src/styles/colors';

@Injectable()
export class DynamicService {
  constructor(
    private readonly _http: HttpClient,
    private readonly eocService: EpisodeOfCareService,
    private readonly patientService: PatientService
  ) {}

  getDynamic(
    endpoint: string,
    patientId: string,
    groupId: number,
    visitId?: string
  ): Observable<IGenericServiceResponse<IDynamicTable<IDynamicItem>[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (visitId) {
      return this._http.get<
        IGenericServiceResponse<IDynamicTable<IDynamicItem>[]>
      >(
        `${environment.BACKEND_URL}${endpoint}/${patientId}/${groupId}?visitId=${visitId}`,
        {
          headers: headers,
        }
      );
    } else {
      return this._http.get<
        IGenericServiceResponse<IDynamicTable<IDynamicItem>[]>
      >(`${environment.BACKEND_URL}${endpoint}/${patientId}/${groupId}`, {
        headers: headers,
      });
    }
  }

  addDynamic(endpoint: string, dynamicObj: IAddDynamic) {
    dynamicObj.patientId = this.patientService.patientIDsignal();
    dynamicObj.visitId = this.eocService.currentVisitId();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post(
      `${environment.BACKEND_URL}${endpoint}`,
      dynamicObj,
      {
        headers: headers,
      }
    );
  }

  editDynamic(endpoint: string, dynamicObj: IAddDynamic) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.put(
      `${environment.BACKEND_URL}${endpoint}/${dynamicObj.id}`,
      dynamicObj,
      {
        headers: headers,
      }
    );
  }

  deleteDynamic(
    endpoint: string,
    dynamicId: string
  ): Observable<IServiceResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.delete<IServiceResponse>(
      `${environment.BACKEND_URL}${endpoint}/${dynamicId}`,
      {
        headers: headers,
      }
    );
  }

  getDynamicDropdown(
    endpoint: string
  ): Observable<IGenericServiceResponse<IDynamicDropdown[]>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this._http.get<IGenericServiceResponse<IDynamicDropdown[]>>(
      `${environment.BACKEND_URL}${endpoint}/DropdownData`,
      {
        headers: headers,
      }
    );
  }
}
