<nz-layout>
    <nz-header>
        <span class="section-header">{{'Tracheostomy'|translate}}</span>
    </nz-header>
    <nz-content>
        <nz-tabset nzType="card">

            <ng-container *ngFor="let tab of submenu; let i = index; ">
                <nz-tab [nzTitle]="titleTemplate1" *ngIf="isInpatientView() && tab.path == 'assessment'">
                    <ng-template #titleTemplate1>
                        <i nz-icon nzType="icons:assessment"></i>{{"Assessment" | translate}}
                    </ng-template>

                    <app-assessment-section [submenu]="tab" />
                </nz-tab>

                <nz-tab [nzTitle]="titleTemplate" *ngIf="isInpatientView() && tab.path == 'patientCharacteristics'">
                    <ng-template #titleTemplate>
                        <i nz-icon nzType="icons:patientCharacteristics"></i>{{"Patient Characteristics" | translate}}
                    </ng-template>

                    <ng-container *ngFor="let tabcont of tab?.childModules; let j = index; ">
                        <app-social-history *ngIf="tabcont.path == componentPaths.socialHistory" />
                        <app-comorbidity *ngIf="tabcont.path == componentPaths.comorbidity" />
                    </ng-container>
                </nz-tab>

                <nz-tab [nzTitle]="titleTemplate2" *ngIf="isInpatientView()  && tab.path == 'etiology'">
                    <ng-template #titleTemplate2>
                        <i nz-icon nzType="icons:etiology"></i>{{"Etiology" | translate}}
                    </ng-template>
                    <app-etiology />
                </nz-tab>

                <nz-tab [nzTitle]="titleTemplate3" *ngIf="isInpatientView()  && tab.path == 'laboratory'">
                    <ng-template #titleTemplate3>
                        <i nz-icon nzType="icons:laboratory"></i>
                        {{"Laboratory" | translate}}
                    </ng-template>

                    <ng-container *ngFor="let tabcont of tab?.childModules; let j = index; ">
                        <app-laboratory-file *ngIf="tabcont.path == componentPaths.laboratoryFiles" />
                        <app-laboratory-result-section *ngIf="tabcont.path == componentPaths.laboratoryResults" />
                    </ng-container>
                </nz-tab>

                <nz-tab [nzTitle]="titleTemplate6" *ngIf="isInpatientView()  && tab.path == 'imaging'">
                    <ng-template #titleTemplate6>
                        <i nz-icon nzType="icons:mri"></i>{{"Imaging" | translate}}
                    </ng-template>

                    <ng-container *ngFor="let tabcont of tab?.childModules; let j = index; ">
                        <app-imaging-files *ngIf="tabcont.path == componentPaths.imagingFiles" />
                        <app-ct-parameters *ngIf="tabcont.path == componentPaths.ctParameters" />
                        <app-multiple-sclerosis-segmentation
                            *ngIf="tabcont.path == componentPaths.multipleSclerosisSegmentation" />
                    </ng-container>
                </nz-tab>

                <nz-tab [nzTitle]="titleTemplate5" *ngIf="isInpatientView()  && tab.path == 'tubeCharacteristics'">
                    <ng-template #titleTemplate5>
                        <i nz-icon nzType="icons:tube"></i>{{"Tube Characteristics" | translate}}
                    </ng-template>
                    <app-tube-characteristics />
                </nz-tab>

            </ng-container>
        </nz-tabset>

    </nz-content>
</nz-layout>