import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IMedicationDetailed } from 'src/app/interfaces/Medication/IMedicationDetailed';
import { CurrentMedicationService } from 'src/app/services/currentMedication.service';
import { PastMedicationService } from 'src/app/services/pastMedication.service';
import Swal from 'sweetalert2';
import { MedicationExpandComponent } from '../medication-expand/medication-expand.component';
import { TranslateService } from '@ngx-translate/core';
import { COLORS } from 'src/styles/colors';
import { SwalToastService } from 'src/app/services/swal.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-past-medication',
  templateUrl: './past-medication.component.html',
  styleUrls: ['./past-medication.component.css'],
})
export class PastMedicationComponent {
  // public expandComp = PastMedicationExpandComponent;

  private destroy$ = new Subject<void>();

  expandComp = MedicationExpandComponent;

  pastMedication?: IMedicationDetailed[];
  isLoading: boolean = false;

  constructor(
    private readonly pastMedicationService: PastMedicationService,
    private readonly currentMedicationService: CurrentMedicationService,
    private readonly translate: TranslateService,
    private readonly swalToastService: SwalToastService,
    private readonly permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.pastMedicationService.pastMedicationLoadingSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        this.isLoading = data;
      });

    this.pastMedicationService.pastMedicationDataSub
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IMedicationDetailed[]) => {
        this.pastMedication = data;
      });

    this.pastMedicationService.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  hasPermission(permission: string): boolean {
    return this.permissionService.can(permission);
  }

  openModalFun(item?: IMedicationDetailed) {
    this.currentMedicationService.medicationEditSub.next(item);

    this.currentMedicationService.medicationOpenSub.next(true);
  }

  deleteFun(medicationId: number) {
    Swal.fire({
      text: `${this.translate.instant(
        'deletePlaceholder2'
      )} ${this.translate.instant('Past Medication')}?`,
      showDenyButton: false,
      showCancelButton: true,
      cancelButtonText: `${this.translate.instant('Cancel')}`,
      confirmButtonText: `${this.translate.instant('Ok')}`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.pastMedicationService
          .deletePastMedication(medicationId.toString())
          .subscribe({
            next: (d) => {
              this.swalToastService.toastSuccess(
                'Past Medication deleted successfully'
              );
              this.pastMedicationService.deletePastMedicationFromTable(
                medicationId.toString()
              );
            },
            error: (err) => {
              this.swalToastService.toastError(
                'Unable to delete Past Medication'
              );
            },
          });
      } else if (result.isDenied) {
        Swal.fire(
          `${this.translate.instant('Past Medication is not deleted')}`,
          '',
          'info'
        );
      }
    });
  }
}
